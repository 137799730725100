.chart_row{
  //width:100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background: white;
  border-radius: 8px;

}
.c_c_chart_container{
  background: white;
  border-radius: 8px;
  position: relative;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.chart_table_container {
  box-sizing: border-box;
  padding:20px 20px 40px 0;
  height: 100%;
  align-items: center;
  justify-content:center;

  .td_container{
    font-size: 12px;
  }
  .table_title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 160%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .dashboard_chart_table {
    background-color: #FBFBFB;
    height: 100%;
    overflow: hidden;
    border-radius: 15px;
    //width: max-content;
    font-family: Inter;

    thead{
      tr{
        th{
          white-space: nowrap;
          height: 50px;
          width: fit-content;
          text-overflow: ellipsis;
          //overflow: hidden;
          padding: 0 12px 0 0;
        }
        th:first-child{
        }
      }
    }
    tbody{
      tr{
        td{
          //min-width: min-content;
          max-width: max-content;
          border-bottom: 0;
          .td_container{
            height:100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .svg_container {
              height:100%;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                height: 5px;
                width: 11px;
              }
            }
          }

        }
        td:first-child{
          //min-width: unset;
        }

        .red {
          color: red;
        }
        .green {
          color: green;
          svg{
            transform: scale(1, -1);
          }
        }
      }
    }

  }
}
#contextMenuOptionWithIconAndHelper{
    width:300px;
    display:flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 50px;
}
#TextBetweenIconAndHelper{
    padding-left: 12px;
    width:100%;
}
#contextMenuOptionWithIconAndArrow{
    width:89.5%;
    display:flex;
    flex-direction: row;
    padding-left: 20px;
}
#contextMenuOptionWithHelper{
    width:300px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 50px;
}
#contextMenuOptionWithArrow{
    width:90%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
}
#contextMenuOption{
    padding-left: 20px;
}
#contextMenuOptionWithIcon{
    width:100%;
    display:flex;
    flex-direction: row;
    padding-left: 20px;
}
#divider{
    border: 1px solid #F0F1F7;
    width:200px;
    left:0;
}
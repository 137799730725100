.scdfa_add_dialog_content{
  border-bottom: none;
  font-family: 'Inter'
}

.cd_grey_area{
  background: #F5F5F5;
  font-weight: 400;
  margin-top: 10px;
  padding: 10px;
  border-radius:8px;

  a{
    text-decoration: unset;
  }
}

.scdfa_add_dialog_scrollable{
  position: relative;
  .scdfa_add_dialog_div{
    z-index: 999;
    position: absolute;
    left: calc(50% - 23px);
  }
  .scdfa_add_dialog_top{
    top: -40px;
  }

  .scdfa_add_dialog_bottom{
    top: -15px;
  }

  .MuiButtonBase-root{
      background-color: rgba(#01AFFD, .2) ;
      animation: beat 0.5s infinite alternate;
      transform-origin: center;
      padding: 14px;
      @keyframes beat{
        to { transform: scale(1.1); }
      }
  }

}

.apply_scroll_buttons{
      top: -55px !important;
}
.acc_details {
  font-family: Inter;

  .subscriptions_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  td,
  th {
    font-family: Inter;
    border-color: #a9a9a9;
  }

  .card {
    background: white;
    border: 1px solid #e9ebec;
    border-radius: 5px;
    width: 706px;
    height: 525px;
    padding: 16px;
    box-sizing: border-box;

    .title_cont {
      width: 100%;
      text-align: left;

      .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0;
      }
    }

    .second_title,
    th {
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0;
    }

    .third_title {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;

      font-weight: 700;
    }
  }

  .tab_content {
    display: flex;
    justify-content: center;
    column-gap: 10px;

    .general_info {
      flex-direction: column;
      row-gap: 25px;
      display: flex;
      width: calc(100% - 40px);
      max-width: 1110px;

      &.small {
        width: 35%;
        height: 100%;
      }

      .content {
        display: grid;
        column-gap: 20px;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        grid-template-rows: auto;

        .column {
          flex-direction: column;
          row-gap: 15px;
          display: flex;

          .btn_container {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            &.multi_buttons {
              justify-content: space-between;
            }
          }
        }

        .property_name {
          font-weight: 400;
        }
      }
    }

    .billing_history {
      flex-direction: column;
      row-gap: 10px;
      display: flex;

      .content {
        height: calc(100% - 36px);
      }

      .table_container {
        overflow: auto;
        height: 100%;

        table {
          width: 100%;
        }
      }
    }

    .general_info.manage_subscription {
      box-sizing: border-box;

      .content {
        display: block;

        .appsumo_container {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }

        .appsumo_link {
          text-decoration: none;
          font-size: 12px;
        }
      }

      td:last-child {
        padding-top: 0;
        padding-bottom: 0;
      }

      tr.blue_border {
        td {
          border-color: #01affd;
        }
      }

      tr.bold {
        td {
          font-weight: 600;
        }
      }

      .cell_btn_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.subscriptions_container {
  height: 100%;
}

.add_on_change_dialog {
  font-family: Inter;

  .MuiPaper-root {
    max-width: unset;
    width: 796px;
    border: 1px;
  }

  .MuiDialogActions-root {
    justify-content: center;
    column-gap: 22px;
  }

  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .MuiButtonGroup-root {
    box-shadow: 4px 4px 20px 0 #00000040;
    border-radius: 20px;
    overflow: hidden;
  }

  .value {
    width: 50px;
    margin: auto;
    text-align: center;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;

    &.s_b {
      justify-content: space-between;
    }

    &.grid {
      display: grid;
      grid-template-columns: 359px 30px 359px;

      .svg_cont {
        align-self: center;
        justify-self: center;
      }
    }
  }

  .small_title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  .small_text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  .bottom_text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
  }

  .subtitle {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    text-align: center;
  }

  .MuiDialogTitle-root {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;
    position: relative;

    button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.grey_card {
  background-color: #fbfbfb;
  border: 1px solid #e7e5e5;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  row-gap: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &.icon_container {
    svg {
      align-self: center;
    }

    display: grid;
    grid-template-columns: 94px auto;

    .button_container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      justify-self: center;
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 3;

    }
  }

  &.inner_content {
    padding-left: 20px;
    border: 0;
  }

  .icon_wrapper_container {
    width: 93px;
    height: 93px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #01affd;
  }

  .g_c_content {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
  }

  ul {
    margin: 0;
  }
}

//@media only screen and (max-width: 1410px) {
//  .acc_details {
//    .card {
//      width: 600px;
//    }
//  }
//}
//@media only screen and (max-width: 1240px) {
//  .acc_details {
//    .card {
//      width: 500px;
//    }
//
//    .general_info {
//      .content {
//        height:100%;
//        overflow: auto;
//        display: block !important;
//      }
//    }
//  }
//}

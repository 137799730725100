.s_e_financial_table_container {
  height: 100%;
  width: 100%;
  //overflow: auto;
  .s_e_table {
    background: white;
    height: 100%;
    width: 100%;
    font-family: Inter;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 11pt;
    table-layout: fixed;

    &.year {
      font-size: 14pt;
    }
    thead{
      background: #A9E5FE;//rgba(83,204,254, 0.5);
    }

    th {
      text-align: end;
      border-bottom: 1px solid #F0F1F7; //#585858;
      padding: 3pt 1px 4pt 1px;
    }

    th:nth-child(1) {
      text-align: start !important;
      overflow-wrap: break-spaces;
    }

    tbody {
      tr {
        text-align: end;
        background: white;

        td {
          border-bottom: 1px solid #F0F1F7; //#585858;
          padding-top: 3pt;
          min-width: max-content;
          white-space: nowrap;
        }

        td:nth-child(1) {
          text-align: start;
          z-index: 1;
          overflow-wrap: break-spaces;
          white-space: unset;
        }

        .bold {
          font-weight: 600 !important;
        }

        .bold:nth-child(1) {
          font-weight: 700 !important;
        }
      }
    }
  }


  .s_e_table.small {
    font-size: 8pt;

    &.year{
      font-size: 12pt;
    }
  }

  .s_e_table.medium {
    font-size: 10pt;

    &.year{
      font-size: 14pt;
    }
  }
}

  .main_table_container_mr {
    height: 100%;
    width: 100%;
    font-family: Inter;
    font-style: normal;
    box-sizing: border-box;

    .mtc_header_container {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;

      .mtc_hc_left {
        display: flex;
        column-gap: 10px;
        align-items: center;

        .mtc_hc_left_heading {
          font-weight: 700;
          font-size: 24px;
          color: #252525;
          margin: 0;
        }
      }
    }

    .mtc_table_container {
      width: 100%;
      margin-top: 20px;
      box-sizing: border-box;
      overflow: auto;
      height: calc(100% - 65px);

      .mtc_tc_table {
        width: 100%;
        box-sizing: border-box;
        border-spacing: 0;
        table-layout: fixed;

        .MuiTableCell-root {
          font-family: Inter;
          padding: 10px 5px;
        }

        tbody {
          tr {
            &:nth-child(odd) {
              background: #F4FDFF;
            }

            &:hover {
              background-color: #FAFBFE;
            }
          }
        }

        td {
          border: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          overflow-wrap: break-word;
        }

        th {
          font-weight: 700;
          font-size: 12px;
          color: #979797;
          text-align: left;
          border-bottom: 1px solid #E9EAF0;
          line-height: 160%;
          padding-top: 10px;
          padding-bottom: 10px;

          &:last-child {
            width: 70px;
          }

          &:nth-child(2), &:nth-child(3) {
            width: 200px;
          }
        }

        .mtc_tc_table_name {
          font-weight: 700;
          font-size: 14px;
          color: #252525;
          border-bottom: 1px solid #E9EAF0;
          line-height: 160%;
          padding-top: 10px;
          padding-bottom: 10px;

          cursor: pointer;
        }

        .mtc_tc_table_text {
          font-weight: 400;
          font-size: 14px;
          color: #979797;
          border-bottom: 1px solid #E9EAF0;
          line-height: 160%;
          padding-top: 10px;
          padding-bottom: 10px;

          width: 240px;
        }

        .flex {
          display: flex;
          column-gap: 5px;
          align-items: center;
        }

        .mtc_tc_table_text_actions {
          width: 100px;
          font-weight: 400;
          font-size: 14px;
          color: #979797;
          border-bottom: 1px solid #E9EAF0;
          line-height: 160%;
          padding-top: 10px;
          padding-bottom: 10px;

          .actions_cont {
            display: flex;
            column-gap: 5px;
            align-items: center;
          }
        }
      }
    }
  }
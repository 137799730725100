.error{
  color:red;
  font-family: Inter;
  font-size: 12.5px;
}

.psfrrc_input_types_div{
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  .psfrrc_input_types_content{
    display: flex;
    flex-direction: column;
    margin-bottom: -15px;
  }
  .psfrrc_input_types_pargraph {
    margin-top: -2px;
    align-self: start;
  }
  .psfrrc_input_types_checkboxes_div{
    display: flex;
    justify-content: flex-start;
    column-gap: 65px;
    margin-left: 20px;
    margin-bottom: 25px;

  }
}

.psfrcsc_multiple_customers_at_start_div{
  //margin-bottom: 10px;
  .psfrcsc_multiple_customers_at_start_paragraph{
    display: flex;
    justify-content: flex-start;
  }
  .psfrcsc_multiple_customers_at_start_childrens{
    display: flex;
    align-self: start;
    column-gap: 20px;
    font-family: Inter;
    align-items: end;
    .psfrcsc_multiple_customers_at_start_group{
      display: block;
      .psfrcsc_multiple_customers_at_start_childrens_p{
        display: block;
        width: 140px;
        margin-top: 5px;
        margin-bottom: 5px;
        :first-child{
          justify-content: flex-start;
        }
        .psfrcsc_multiple_customers_at_start_childrens_input{
          width: 140px;

        }

        label {
          margin-left: 4px;
        }
      }
    }

  }
}

.psfrcsc_multiple_customers_inputs_grpoup{
  display: flex;
  column-gap: 20px;
}

.psfrcrcc_breakdown_checkbox{
}

.psrcrsmcs_error_values{
  font-family: Inter;
  color: red;
  font-size: 10px;
  height: 0;
  padding: 0;
}

.psrcrsmcs_space_tables{
  height: 0;
  padding: 0;
}

.psfrcssc_total_percentage{
  margin-bottom: -8px;
  width: 80px;
  white-space: nowrap;
}
body, html, #root {
  width: 100%;
  height: 100%;
}

.MuiTooltip-tooltip {
  background: white !important;
  box-sizing: border-box !important;
  border: 1px solid #F0F1F7;
  border-radius: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-top: 10px !important;
  color: #252525 !important;
  font-family: Inter;
  font-weight: 400;
  font-size: 10px;

  .MuiTooltip-arrow {
    color: white !important;

    &::before {
      box-sizing: border-box !important;
      border: 1px solid #F0F1F7 !important;
    }

  }
}

.transition {
  transition-duration: 150ms;
}

.slide_editor {
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content auto;
  height: 100%;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  .slide_loader {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    opacity: 60%;
  }


  .se_content_editor {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .se_panel_header {
    z-index: 989;
    grid-column-start: 1;
    grid-row-start: 1;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //box-shadow: 2px 10px 5px 0px rgba(0, 0, 0, 0.37);
    //-webkit-box-shadow: 2px 10px 5px 0px rgba(0, 0, 0, 0.37);
    //-moz-box-shadow: 2px 10px 5px 0px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);

    #se_panel_header {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      .undo:hover .undoIcon {
        stroke: #D4D5DE;
      }

      .undo:hover #tooltipTextUndo {
        visibility: visible;
      }

      .undo {
        position: relative;
        display: inline-block;

      }

      #tooltipTextUndo {
        visibility: hidden;
        height: 20px;
        width: 100px;
        color: black;
        font-size: 10px;
        background-color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        left: -10px;
        top: 130%;
        z-index: 1;
        border: 1px solid #F0F1F7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      }

      #tooltipTextUndo:before {
        content: '';
        display: block;
        position: absolute;
        left: 13px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-bottom-color: #F0F1F7;
      }

      #tooltipTextUndo:after {
        content: '';
        display: block;
        position: absolute;
        left: 14px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-bottom-color: white;
      }

      .SubMenuButton:hover .undoIcon {
        stroke: #D4D5DE;

      }

      .SubMenuButton:hover .undoIconFill {
        fill: #D4D5DE;
      }

      .SubMenuButton:hover #tooltipSubMenuButton {
        visibility: visible;
      }

      .SubMenuButton {
        position: relative;
        display: inline-block;

      }

      #tooltipSubMenuButton {
        visibility: hidden;
        height: 20px;
        width: 100px;
        color: black;
        font-size: 10px;
        background-color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        left: -30px;
        top: 130%;
        z-index: 1;
        border: 1px solid #F0F1F7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      }

      #tooltipSubMenuButton:before {
        content: '';
        display: block;
        position: absolute;
        left: 41px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-bottom-color: #F0F1F7;
      }

      #tooltipSubMenuButton:after {
        content: '';
        display: block;
        position: absolute;
        left: 42px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-bottom-color: white;
      }

    }

    .FontSizeButtons {
      display: flex;
      margin-left: 20px;
      margin-right: 10px;
    }

    #minus:hover {
      background: lightgrey;
    }

    #plus:hover {
      background: lightgrey;
    }

    #FontValue:hover {
      background: lightgrey;
    }

    #fontFamily {
      width: 200px;
      height: 28px;
      border-color: lightgrey;
    }

    #fontColor {
      border-color: lightgrey;
      background: white;
      height: 25px;
    }

    #fontColor:hover {
      background: lightgrey;
    }

    #fontFamily:hover {
      background: lightgrey;
    }

    #minus, #plus, #FontValue, #boldButton, #textAlignLeft, #textAlignCenter, #textAlignRight {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      background: white;
      border: solid;
      border-color: lightgrey;
      border-width: 1px;
      width: 25px;
      height: 25px;

    }

    #lineTypeButton {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      background: white;
      border: solid;
      border-color: lightgrey;
      border-width: 1px;
      width: 25px;
      height: 25px;
    }

    #lineTypeButton:hover {
      background: lightgrey;
    }

    .button {
      margin-left: 10px;
      margin-right: 10px;
    }

    #italicButton, #underlineButton {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      border: solid;
      border-color: lightgrey;
      border-width: 1px;
      width: 25px;
      height: 25px;
    }

    .TextAlignIcon {
      width: 100%;
      height: 100%;
    }

    #minus {
      border-bottom-left-radius: 40%;
      border-top-left-radius: 40%;
    }

    #plus {
      border-bottom-right-radius: 40%;
      border-top-right-radius: 40%;
    }

    #boldButton:hover {
      background: lightgrey;
    }

    #italicButton:hover {
      background: lightgrey;
    }

    #underlineButton:hover {
      background: lightgrey;
    }

    #textAlignLeft:hover {
      background: lightgrey;
    }

    #textAlignCenter:hover {
      background: lightgrey;
    }

    #textAlignRight:hover {
      background: lightgrey;
    }

  }

  .se_panel_header.hidden {
    display: none;
  }

  .scale_bp_container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    overflow: auto;
    //overflow-y:auto;
  }

  .se_panel_wrapper {
    grid-column-start: 1;
    grid-row-start: 2;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: min-content auto min-content;
    grid-template-rows: 100%;
    overflow: hidden;

    .se_menu_container {
      //float:left;
      //z-index: 1;

      background: white;
      grid-column-start: 1;
      grid-row-start: 1;
      height: 100%;
      width: 250px;
      display: flex;
      border-right: 1px solid #F0F1F7;
      //box-shadow: 1px 1px 16px 2px rgba(0, 0, 0, 0.75);
      //-webkit-box-shadow: 1px 1px 16px 2px rgba(0, 0, 0, 0.75);
      //-moz-box-shadow: 1px 1px 16px 2px rgba(0, 0, 0, 0.75);

      .icons_list {
        display: flex;
        flex-direction: column;
        width: 50px;
        height: 100%;
        background-color: #e7e7e7;

        .button {
          width: 50px;
          height: 50px;
          display: flex;
          flex-direction: column;
          box-shadow: 1px 1px 8px #ededed;

          .icon {
            display: flex;
            height: 100%;
            flex-grow: 1;
            align-items: flex-end;
            justify-content: center;
          }

          .title {
            display: flex;
            height: 100%;
            flex-grow: 1;
            align-items: flex-start;
            justify-content: center;
          }

        }
      }

      .menu_extended {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

      }
    }

    .se_menu_container.hidden {
      //.left_menu{
      //  visibility: hidden;
      //}

      position: relative;
      //transition-property: width;
      transition-duration: 150ms;
      width: 0;
    }

    .se_menu_container.extended {
      position: relative;
      transition-property: width;
      transition-duration: 150ms;
      width: 250px;
    }

    .se_panel_container {
      grid-column-start: 2;
      grid-row-start: 1;
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto 0 0 67px;
      //align-items: center;
      //justify-content: center;
      background-color: #E0E0E0;
      overflow: hidden;
      overflow-y: auto;
      position: relative;

      .loader_container_on_top_sc {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        //backdrop-filter: blur(3px);
        background-color: #E0E0E0;

        &.bp {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-columns: 100%;
          overflow: hidden;
          overflow-y: auto;
          //backdrop-filter: blur(3px);
          //opacity: 0.3;
        }
      }

      &.naked {
        grid-template-rows: 100% 0 0 0;
      }

      .insert_mask {
        display: none;

        &.visible {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 100%;
          width: 100%;
          height: 100%;
          cursor: crosshair;
          z-index: 999999;
        }
      }

      .se_scale_panel_top {
        //max-height: 90vh;
        height: 93%;
        width: 90%;
        align-self: center;
        justify-self: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .se_scale_panel {
          display: block;
        }


      }

      .business_plan {
        display: block;

      }

      .se_panel {
        width: 1280px;
        height: 720px;
        background-color: #fff;
        transform-origin: top left;
        position: relative;
      }

      .se_panel.bp {
        width: 816px;
        min-height: 1154px;
        height: auto;
        padding: 49px;
        box-sizing: border-box;
      }

      .se_panel.padding-top {
        padding-top: 140px;
      }

      .se_panel.padding-bottom {
        padding-bottom: 80px;
      }


      .se_panel_placeholder {
        border: 1px solid transparent;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;


        .shape {
          position: absolute;
          height: 100%;
          overflow: visible;
          display: block;
          pointer-events: none;
          top: 0;
          left: 0;
          width: 100%;
        }

        &_selected {
          border: 1px solid #00c4cc;
        }
      }

      .se_panel_footer {
        width: 100%;
        height: 66px;
        background: #FFFFFF;
        border-top: 1px solid #F0F1F7;
        display: flex;
        padding: 0 10px 0 10px;
        box-sizing: border-box;
      }

      #chart_editor_portal {
        width: 100%;
        z-index: 1290;
        position: relative;

        .chart_editor_contaier {
          z-index: 1299;
          //overflow-y: scroll;
          width: 100%;
          position: absolute;
          //display:none;
          height: 300px;
          background: white;
          bottom: 0;

        }
      }

    }

    .se_right_menu_container {
      background: white;
      position: relative;
      grid-column-start: 3;
      grid-row-start: 1;
      //height: 100%;
      width: 250px;
      border-left: 1px solid #F0F1F7;
      display: grid;

      .css-1waf648-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        color: black !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      grid-template-columns: 100%;
      grid-template-rows: 100%;

      .right_menu_mask {
        position: absolute;
        z-index: 8;
        width: 100%;
        height: 100%;
        background: white;
      }

      .collapse {
        padding-top: 16px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .MuiListItemButton-root {
        max-height: 62px;
        max-width: 242px;
      }

      .listButtonText {
        font-family: Inter;
      }

      .listRowElement {
        width: 100%;
        text-align: left;
        font-size: 12px;
        font-family: Inter;
      }

      .right_menu_splitter {
        width: 240px;
        border: 1px solid #F0F1F7;
      }

      .listSubHeading {
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        line-height: 19px;
        font-family: Inter;

        &.light {
          padding-top: 0;
          font-weight: 400;
        }

        &:first-child {
          padding-top: 0;
        }
      }

      .listRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: Inter;

        .listSubHeading {
          font-weight: 500;
        }

        &.listColumn {
          flex-direction: column;
          align-items: flex-start;

        }

        input[type=radio] {
          position: relative;
          cursor: pointer;
        }

        input[type=radio]:before {
          content: "";
          display: block;
          position: absolute;
          width: 14px;
          height: 14px;
          top: -0.9px;
          left: -0.9px;
          //background-color:#e9e9e9;
          border-radius: 50%;
        }

        input[type=radio]:checked:before {
          content: "";
          display: block;
          position: absolute;
          width: 14px;
          height: 14px;
          background: #B560CA;
          top: 0;
          left: 0;
          border-radius: 50%;
        }
      }

      .se_right_menu {
        overflow: auto;
        height: 100%;

        .top_btn_cont {
          width: 242px;
          display: flex;
          column-gap: 20px;
          justify-content: center;
          padding-top: 10px;
        }
      }
    }

    .se_right_menu_container.hidden {
      transition-duration: 150ms;
      width: 0;

      .MuiCollapse-wrapperInner {
        display: none;
      }
    }

    .se_right_menu_container.extended {
      transition-duration: 150ms;
      width: 250px;
    }


  }

  nav {
    //position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0px;
    width: 50px;
    height: 350px;
  }


  .css-18lolh3-MuiDataGrid-root {
    position: relative;
  }
}

//a:hover {
//  background: lightgrey;
//
//}
//
//a {
//  text-decoration: none;
//  size: 9px;
//}

.AddNewText:hover {
  background: lightgrey;
}

.AddNewText {
  background: white;
  border-radius: 16px;
  box-shadow: 5px 5px 8px #ededed;
}

.sp_cp_textobject {
  white-space: pre-wrap;
}

#se_scale_panel {
  &.insertMode {
    cursor: crosshair !important;
  }
}


.sp_cp_mainpanel{
  border: 1px solid transparent !important;
  position: absolute;
  margin:0;
  padding: 0;

  &.async{
    background: rgba(1,175,253, 0.3);
  }
  &.async-auto{
    width: auto !important;
  }

  &.async-auto.auto_height{
    height: auto !important;
  }

  &.hidden{
    display: none;
  }

  .sp_cp_childpanel{
    display: block;
    height: 100% !important;
    width: 100% !important;
    margin: 0;
    padding: 0;

    .pfr_main_revenue_content {
      width: 100%;
      height: 100%;
    }

    .dashboard_container {
      width: 100%;
      height: 100%;
      grid-template-rows: unset;
      overflow: hidden;

      &.apply_responsive {
        grid-template-rows: auto calc(100% - 30px);
      }


      .finance_table_main_wrapper_div {
        margin: 0;
      }

      .main_table_container {
        padding: 0;

        .finance_table_main_wrapper_div_kpi {
          max-height: calc(100% - 55px) !important;
        }

        &.table_container {
          padding: 0 20px;
        }
      }
    }

    .cropper-modal {
      background-color: #FFF;
      opacity: 0.5;

    }

    .crop_button {
      background: white;
      outline: none;
      font-family: Inter;
        font-size: 20px;
        height:50px;
        border-radius: 15px;
        border:none;
        color:#01AFFD;

        &:hover{
          background: rgba(128,215,254, 0.2);

        }
      }

    .c_c_chart_container{
      height:100%;
    }
  }

  .resizable-handler, .square, .rotate{
    display: none !important;
  }

  .square{
    border: none !important;
    background-color: #999999 !important;
  }

  &_autoheight{
    height: auto !important;
    .sp_cp_childpanel{
      height: auto;
    }
  }

  &_selected{
    border: 1px dashed #999999 !important;

    .resizable-handler, .square, .rotate{
      display: block !important;
    }

    .sp_cp_mainpanel_not_selected{
      .resizable-handler, .square, .rotate{
        display: none !important;
      }
    }

    &_grouped{
      border: 1px dashed #01AFFD !important;

      .resizable-handler, .square, .rotate{
        display: block !important;
      }

      .sp_cp_mainpanel_not_selected{
        .resizable-handler, .square, .rotate{
          display: none !important;
        }
      }
    }
  }

  &.hover:hover{
    border: 1px solid #999999 !important;
  }

  &.hover_grouped:hover{
    border: 1px solid #01AFFD !important;
  }

  *:focus{
    outline: none;
  }
}

.sp_cp_active_line_horizontal{
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  border-top:1px dashed #999999;
}

.sp_cp_active_line_vertical{
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
  border-left:1px dashed #999999;
}

.sp_cp_active_box{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;

  .sp_cp_active_box_inner{
    border:1px dashed #999999;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }
}
#contextMenuContentPanel{
  display:flex;
  flex-direction: column;
  gap:10px;
}

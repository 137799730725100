.MuiSlider-thumb{
    color:#80D7FE;
    border: 1px solid white;
}
.MuiSlider-rail{
    color: #E5E5E5;
}

.loader_container {
    font-family: Inter;
    font-size: 12px;
    visibility: visible;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    width:100px;
    height:30px;
    box-sizing: border-box;
.loader_small {
    transform: scale(0.15);
    width: 45px;
svg {
    margin: 0;
}
}
}

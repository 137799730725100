.psdf_menu {
  width: 200px;
  z-index: 501;
  .szh-menu__item{
    white-space: nowrap;
    font-size: 14px;
  }

  .szh-menu{
    padding: 0
  }
}

.psdf_menu_button{
  font-size: 12px !important;
  border-radius:8px !important;
  border-color: #F0F1F7 !important;
  font-weight: 600 !important;
  background: white !important;
  color: #252525 !important;
  height: 34px !important;
  width: 200px !important;
  align-self: flex-end !important;
  text-transform: none !important;

}

.psdf_menu_button_overview{
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 160% !important;
  /* or 19px */
  /* Dark_Grey/500 */

  color: #252525 !important;

}

.psdf_menu_navigation{
  display: inline-flex;
  align-content: center;
  column-gap: 25px;
  margin-left: 15px;
  align-items: center;
  .psdf_switch{
    align-self: center;
  }
  @media (min-width: 1362px) and (max-width: 1450px) {
    column-gap: 15px;
  }
}

.psdf_menu_navigation_show_hide_div{
  //align-self: normal;
}

.psdf_menu_navigation_show_hide{
  display: inline-flex;
  flex-direction: column;
  align-content: center;
  column-gap: 20px;
  margin-left: 15px;
  width: 200px;
  padding-left: 10px;
  padding-right: 20px;
  .MuiFormControlLabel-root{
    justify-content: space-between;
  }
  .psdf_menu_children_div{
    margin-left: 10px;
    display: inline-grid;
  }

}


.psdf_menu_section_button{
  align-self: center !important;
  width: 190px !important;
overflow: hidden;
  white-space: nowrap;
  text-transform: none !important;
  background: white !important;
  color: #252525 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 160% !important;
  justify-content: space-between !important;
  &.custom_dash{
    margin-top: 13px;
  }

  span{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .spsvs_show_hide{
    width: 300px !important;
  }
  .psdf_menu{
    width: 190px
  }
}

.psdf_menu_section_button.psdf_menu_section_not_full_width{
  width: auto !important;
}


.psdf_navigation_menu_buttons{
  max-height: 31px !important;
  height: 31px !important;
  min-height: 31px !important;
  font-size: 16px !important;
  border-color: #F0F1F7 !important;
  border-radius:8px !important;
  .MuiTabs-flexContainer{
    background: #FAFBFE !important;
  }
  .MuiButtonBase-root{
    letter-spacing: unset;
    line-height: unset;
    cursor: pointer;
  }
}

.psdf_navigation_menu_buttons.apply_color{
  .Mui-selected{
    background: #01AFFD !important;
    color: #FFFFFF !important;
  }
}

.psdf_navigation_menu_tab{
  min-height: 0 !important;
  text-transform: none !important;
  border-radius:5px !important;
  color: #252525 !important;
  font-size: 12px !important;
  background-color: #FAFBFE !important;
  font-weight: 600 !important;
  padding: 9px 12px !important ;
}
.psdf_navigation_menu_buttons.apply_hidden{
  @media (max-width: 1362px) {
    display: none !important;
  }
}
.psdf_show_hide{
  text-transform: none !important;
  color: red;
  background: red !important;
}

.psdf_menu_navigation_show_hide.spsvs_show_hide{
  width: 300px !important;
}
.auth_page {
  .auth_page_container {
    width:100%;
    height:489px;
  }

  .auth_page_container_scroll {
    max-height: 430px;
    overflow: scroll;
    .auth_page_accounts_container{
      display: flex;
      justify-content: space-between !important;
    }
    .account_active{
      color: #01AFFD;
    }
  }

  .auth_page_container.loader_container{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .auth_page_container.choose_account{
    box-sizing: border-box;
    padding:17px 9px 18px 9px;



    .account_card{
      width:100%;
      display: flex;
      margin-bottom:6px;
      justify-content: flex-start;
      border-radius: 8px;
      text-transform: none;

      .icon_container_auth{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
      }

      .mail_container{
        display: flex;
        align-items: flex-end;
        padding:0 0 0 15px;

        .account_active{
          color: #01AFFD;
        }
      }
    }

    .new_acc_btn{
      width:70%;
      display: flex;
      justify-content: space-between;
      text-transform: none;
      border-radius: 8px;
      color: #01AFFD;
      font-weight: 600;
      size: 16px;
      padding: 6px 17px 6px 17px;
      align-items: center;
    }
  }

  .Paragraph{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    color: #252525;
    line-height: 160%;
  }
  .P1_Strong{
    font-size: 20px;
  }

  .P3_Strong{
    font-size: 14px;
  }

}

.choose_account_title{
  padding: 10px 0 0 10px;
}

.appsumo_logo_container_main {
  display: flex;
  align-items: center;

  .plus_container{
    height:min-content;
    font-size: 50px;
    color: black;
    padding: 0 20px 8px 4px;
    display: flex;
    align-items: center;
  }
  
  .appsumo_logo_container {
    width: 180px;
    height: 48px;
    display: flex;
    align-items: center;

    svg {
      width: 180px;
      height: 180px;
    }

  }
}
.spsi_company_name{
  width: 200px;
  overflow: visible;

  .cmpny_name{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  @media (min-width: 1150px) and (max-width: 1200px) {
    width: 200px;
    font-size: 12px;
    overflow-x: hidden;

    .MuiButton-root.company_btn{
      max-width: 100%;
    }
  }

  @media (min-width: 0px) and (max-width: 1150px) {
    width: 150px;
    font-size: 12px;
    overflow-x: hidden;

    .MuiButton-root.company_btn{
      max-width: 100%;
    }
  }

}

.spsi_nav_bar{
  .MuiTabs-flexContainer{
    margin-right: 200px
  }

  @media (min-width: 1150px) and (max-width: 1400px) {
    .MuiTabs-flexContainer{
      margin-right: 100px
    }

  }

  @media (min-width: 0px) and (max-width: 1150px) {
    .MuiTabs-flexContainer{
      margin-right: 0
    }

  }


}

.upgrade_plan_div{

  .upgrade_plan_dialog_text{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    font-size: 18px;
    font-weight: 600;
    font-family: Inter;
    .font_big_size{
      color: #01AFFD;
      font-size: 20px;
    }
  }
  .upgrade_plan_actions{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    column-gap: 10px;
  }
}

.update_plan_icon_text_div{
  @media (min-width: 0px) and (max-width: 1360px) {
    display: none;
  }
  display: flex;
  column-gap: 5px;
  align-items: center;
  .upgrade_plan_trial_text{
    font-size: 13px;
    font-family: Inter;
    color: #01AFFD;
    white-space: no-wrap;
    width: 135px;
  }
}

.mslt_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  width: 100%;

  p{
    margin: 0 auto;
  }

  .p1Strong{
    font-weight: bold;
    font-size: 20px;
  }
}
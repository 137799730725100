.pscfc_single_finance_tab_subtitle_buttons_content{
  margin-top: -2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: pre-line;

}

.MuiTooltip-tooltip{
    font-size: 16px !important;
    max-width: 600px !important;
}
//.MuiTooltip-popper{
//  font-size: 16px !important;;
//  color: red !important;;
//}
//
//.MuiTooltip-popperInteractive	{
//  font-size: 16px !important;
//  color: red !important;;
//}
//

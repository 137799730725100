.error_boundary{
  font-family: Inter;

  width:100%;
  padding:100px;
  box-sizing: border-box;

  .logo_container{
    display: flex;
    align-items: flex-end;
    column-gap: 5px;
    font-size: 24px;
    font-weight: 700;
  }

  .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    font-weight: 300;
    margin-top: 50px;

    .r_1{
      margin-top: 44px;
      font-weight: 500;
      font-size: 40px;
    }
    .r_2{
      font-size: 32px;

      &.m_t{
        margin-top: 26px;
      }
    }
    .r_3{
      margin-top: 36px;
      font-size: 24px;
    }

    .r_4{
      margin-top: 20px;
    }
  }
}
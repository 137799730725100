.payment_failed_dialog {

  .pfd_label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }

  .pfd_label_2{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }


  .pfd_button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiPaper-root {
    height: 160px;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    padding: 10px;
  }
}
.upgrade_lifetime_dialog {
  .MuiPaper-root {
    width: 460px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    font-family: Inter;
    color: #252525;
    box-sizing: border-box;
    overflow: hidden;

    .p1_strong_blue {
      font-weight: 700;
      color: #01AFFD;
      font-size: 20px;
    }

    .p1_strong{
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px;
    }



    .cld_typography2_buttons {
      margin-top: 20px;
      display: flex;
      padding-top: 10px;
      justify-content: flex-start;
    }
  }
}
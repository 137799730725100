.psffcc_growth_container{
  display: block;
  padding: 7px 0 5px 0;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  .psffcc_multi_div{
    display: flex;
    column-gap: 25px;
  }
  .psffcc_multi_single_div{
    display: flex;
    column-gap: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
  }


}

.psffcc_single_div{
  display: flex;
  column-gap: 20px;
}

.psffcc_input_width{
  text-align: start !important;

}
.psffcc_input_date{
  display: block;
}

.psffcc_growth_title{
  align-self: end;
  margin-bottom: 10px;
}

.psffcc_title_input{
  margin-left: 0 !important;
}


.psffcc_growth_child_container {
  display: block;
  width: 250px;
  padding-top: 2px;
  padding-bottom: 5px;
}

.psffcc_growth_label{
  display:block;
  :first-child{
    margin-left: 0;
  }
  :last-child{
    margin-top: 5px
  }
}

.psffcc_growth_inputs_container{
  display: flex;
  column-gap: 10px;
  margin-top: 2px;
  .psffcc_input_date{
    margin-left: 0;
    max-width: 120px !important;
    min-width: 120px !important;
  }
  .mx_numinput.mct_ft_dialog_input_small{
    max-width: 120px !important;
    min-width: 120px !important;
  }

}

.psffcc_input_label_divider{
  margin-bottom: 5px;
}

.psfcfc_growth_div{
  margin-bottom: 10px;
}
.left_menu{
  display:flex;
  flex-direction: column;
  width:100%;
  position: relative;

  .closing_btn{
    //visibility: visible !important;
    width:28px;
    height:28px;
    background: #FFF;
    border-radius: 50%;
    border: 1px solid #F0F1F7;
    position: absolute;
    right:-14px;
    z-index: 1;
    top:15px;
  }

  .tab_btn{
    padding-right:5px;
  }

  .MuiTabs-indicator{
    background-color: #1976d2 !important;
  }

  .section_name{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 60px;
    color:#000000;
    height:60px;
    text-align: left;
    padding-left: 16px;
  }
  .section_name.button{
    text-transform: capitalize;
    display: flex;
    justify-content: flex-start;

    span{
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

  }

  .categories_menu{
    .szh-menu{


      .szh-menu__item{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #979797;
      }
      .szh-menu__item.selected{
        color:#000000;
      }
    }
  }


  .MuiBox-root{
    width:100%;

    .tab_btn{
      width:100%;
      display: flex;
      align-items: flex-start;
      min-height:0;
      height:36px !important;
      box-sizing: border-box;
      padding-top:0;
      padding-bottom:0;

      text-align: left;
      text-transform: capitalize;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      .tab_items_container{
        width:100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right:0;


        .tab_name.hidden{
          display: block !important;
          color: #D4D5DE;
        }
        .btns_container{
          display: none;
        }
      }



      &:hover{

      }
    }

    .tab_btn.Mui-selected{
      transition-delay: 200ms;
      transition-property: border;

      border-top: 1px solid #01AFFD;
      border-bottom: 1px solid #01AFFD;
      font-weight: 700;
      .tab_items_container {
        .tab_name {
          white-space: nowrap;
          line-height: 22.4px;
          width: 50%;
          text-overflow: ellipsis;
          overflow: hidden;
        }


        .btns_container {
          display: flex;
          width: min-content;
          column-gap: 6px;
          justify-content: space-between;
          align-items: center;

          .svg_btn {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .left_side_button_container{
      width:100%;
      display: flex;
      justify-content: center;
      padding-top:20px;
    }
  }
}
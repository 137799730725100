@media (min-width: 1896px) {
  .more_options_menu {
    .m_o_xxl {
      display: none !important;
    }
    .m_o_xl {
      display: none !important;
    }
    .m_o_l {
      display: none !important;
    }
    .m_o_m{
      display: none !important;
    }
    .m_o_s{
      display: none !important;
    }
    .m_o_xs{
      display: none !important;
    }
    .m_o_xxs{
      display: none !important;
    }
    .m_o_xxxs{
      display: none !important;
    }
  }
}
@media (max-width: 1895px) and (min-width: 1854px){
  .more_options_menu {
    .m_o_xl {
      display: none !important;
    }
    .m_o_l {
      display: none !important;
    }
    .m_o_m{
      display: none !important;
    }
    .m_o_s{
      display: none !important;
    }
    .m_o_xs{
      display: none !important;
    }
    .m_o_xxs{
      display: none !important;
    }
    .m_o_xxxs{
      display: none !important;
    }
  }
}
@media (max-width: 1853px) and (min-width: 1806px){
  .more_options_menu {
    .m_o_l {
      display: none !important;
    }
    .m_o_m{
      display: none !important;
    }
    .m_o_s{
      display: none !important;
    }
    .m_o_xs{
      display: none !important;
    }
    .m_o_xxs{
      display: none !important;
    }
    .m_o_xxxs{
      display: none !important;
    }
  }
}
@media (max-width: 1805px) and (min-width: 1716px){
  .m_o_m{
    display: none !important;
  }
  .m_o_s{
    display: none !important;
  }
  .m_o_xs{
    display: none !important;
  }
  .m_o_xxs{
    display: none !important;
  }
  .m_o_xxxs{
      display: none !important;
    }
}

@media (max-width: 1715px) and (min-width: 1591px){
  .more_options_menu {
    .m_o_s{
      display: none !important;
    }
    .m_o_xs{
      display: none !important;
    }
    .m_o_xxs{
      display: none !important;
    }
    .m_o_xxxs{
      display: none !important;
    }
  }
}
@media (max-width: 1590px) and (min-width: 1466px){
  .more_options_menu {
    .m_o_xs{
      display: none !important;
    }
    .m_o_xxs{
      display: none !important;
    }
    .m_o_xxxs{
      display: none !important;
    }
  }
}
@media (max-width: 1465px) and (min-width: 1343px){
  .more_options_menu {
    .m_o_xxs{
      display: none !important;
    }
    .m_o_xxxs{
      display: none !important;
    }
  }
}
@media (max-width: 1342px) and (min-width: 1203px){
  .more_options_menu {
    .m_o_xxxs{
      display: none !important;
    }
  }
}
.more_options_menu {
  .szh-menu {
    //width:500px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    font-family: Inter;
    font-size: 15px;
    //padding:0;

    .szh-menu__item--hover{
      background: white !important;

      color: #01AFFD;
      .undoIconFill{
        fill:#01AFFD;
      }
      .undoIcon{
        stroke:#01AFFD
      }

      button{
        .undoIconFill{
          fill:#01AFFD !important;
        }
        .undoIcon{
          stroke:#01AFFD !important;
        }
      }
    }

    button{
      margin:0 !important;
      padding: 0 !important;
      display: flex;
      align-items: center;

      &:hover{
        .undoIconFill{
          fill:#01AFFD;
        }
        .undoIcon{
          stroke:#01AFFD
        }
      }
    }

    .szh-menu__item{
      width:max-content;
      padding:0 8px 0 8px !important;
    }
    .szh-menu__submenu{

      .szh-menu__item{
        width:max-content;
        padding:0 8px 0 8px;
      }
      .szh-menu__item::after{
        content: none;
      }
    }
  }
}

.align{
  .szh-menu{
    display: flex;
    flex-direction: row;
    width:158px !important;
    min-width:0;
    flex-wrap: wrap;
    .szh-menu__item{
      width:max-content;
      padding:7px !important;
    }
  }

}
.bulleting{
  .szh-menu{
    display: flex;
    flex-direction: row;
    min-width:0;
    width: 202px !important;
    height: 164px;
    flex-wrap: wrap;
    .szh-menu__item{
      width:max-content;
      padding:0 8px 0 8px;
    }
  }
}
.spacing{
  font-size: 15px;
  font-family: Inter;
  .szh-menu{
    display: flex;
    width:206px !important;
    min-width:0;
    flex-direction: column;

    .szh-menu__item{
      display:flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width:100%;
      padding: 7px !important;
    }


    .szh-menu__submenu.custom{
      display: flex;
      align-items: center;
      justify-content: center;
      .szh-menu__item{
        display: flex;
        justify-content: center;
        align-items: center;
        width:max-content;
        padding:0 8px 0 8px;
      }
      .szh-menu__item::after{
        content: none;
      }
      .szh-menu{
        width:152px !important;
        padding:5px 5px 5px 5px;
        .row_txt{
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .row{
          align-items: center;
          display:flex;
          justify-content: center;
          height:max-content;
          padding:0 !important;
        }
        input{
          height:30px;
          width:45px;
          outline: none;
          border: 1px solid #F0F1F7;
          border-radius: 4px;
        }
      }
    }
  }
}
.slide.more_options_menu{
  .szh-menu{
    width: 200px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    align-items: center;
  }

  &.vertical{
    .szh-menu{
      padding-left: 0;
      width: auto !important;
      min-width: auto;
      display:block;

      li{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 100%;
      }
    }
  }

  .footer{
    .szh-menu{
      display:flex;
      min-width:0;
      width: 250px !important;
      flex-wrap: wrap;
      padding: 10px;
      color: #2411ff;
    }
  }
}


.pscf_single_finance_tab{
  height:auto;
  max-height: 76vh !important;
  min-height: 60vh !important;
  border: 1px solid #F0F1F7 !important;
  padding: 0 !important;

  .pscf_single_finance_chart_container{
    box-sizing: border-box !important;
    padding-top: 15px !important;
    //padding-left: 16px !important;
    //padding-right: 16px !important;

    .pscf_single_finance_chart_div{
      background: white !important;
      display: block !important;
      padding-bottom:10px !important;
      width: auto !important;
      box-sizing: border-box !important;


      .pscf_single_finance_chart_div_content{
        display: flex !important;
        justify-content: space-between !important;
        //margin-top: -8px;
        box-sizing: border-box !important;
        align-items: center !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        .pscf_single_finance_chart_paragraph{
          padding-top: 4px !important;
        }
        .pscf_single_finance_chart_button{
          display: flex !important;
          align-items: center !important;
          grid-gap: 20px !important;
          width: 170px;

        }
      }

      .pscf_single_finance_chart_main_charts{
        display: flex !important;
        box-sizing: border-box !important;
        width:100% !important;
        padding: 0 10px 0 0 !important;
        .pscf_single_finance_chart_first_chart{
          width: 75%  !important;
          height: 210px !important;
          box-sizing: border-box !important;
        }

        .pscf_single_finance_chart_second_chart{
          width: 25%  !important;
          height: 210px !important;
          box-sizing: border-box !important;
          padding-left:20px !important;
        }
      }
    }
  }

  .pscf_single_finance_tab_content_div{
    padding-left: 17px !important;
    padding-right: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    align-content: flex-start !important;

    .dialog_table_container{
      overflow: auto;
      width:100% !important;
    }
    .dialog_table_container:hover{
      //overflow-x:overlay !important;
      //overflow-y:overlay !important;
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: transparent;
      z-index:99999;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      z-index:99999;

    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(128, 215, 254, 0.7);
      border-radius: 20px;
      z-index:99999;

    }

    ::-webkit-scrollbar-track-piece:start {
      //margin-top: 42px;
      //margin-left:240px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #80D7FE;
    }
  }


}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
  z-index:99999;
}

::-webkit-scrollbar-track {
  background: transparent;
  z-index:99999;

}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 215, 254, 0.7);
  border-radius: 20px;
  z-index:99999;

}

::-webkit-scrollbar-track-piece:start {
  //margin-top: 42px;
  //margin-left:240px;
}

::-webkit-scrollbar-thumb:hover {
  background: #80D7FE;
}


.spsfcfs_formula_div_tool{
  margin: 0;
  padding: 0;
  .MuiTouchRipple-root:focus-visible{
    outline: none !important;
    background: transparent;
  }
  .MuiTouchRipple-root{
    display: none;
  }

  .MuiButtonBase-root:hover{
    background-color: transparent !important;
  }
}

.spsfcfs_formula_tool_tip{
  font-family: Inter;
  padding: 5px;
  margin: 0;
}

.restricted_access_page{
  font-family: Inter;

  .r_a_container{
    margin-left: auto;
    margin-right: auto;
    width:fit-content;

    &.ordinary_text{
      font-size: 32px;
      font-weight: 300;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: center;
      padding: 0 150px;
    }

    .title{
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: center;
    }

    //display:flex;
    //align-items: center;
    //justify-content: center;
  }
}
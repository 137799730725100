.dialog_content {

  .MuiTabs-flexContainer {

    .MuiTab-root {
      margin-right: 39px;

      .tab_label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 160%;

        text-align: center;
        position: relative;
        text-transform: capitalize;
        color: #252525;
        visibility: hidden;
      }
      .tab_label.small {
        width:100%;
        position: absolute;
        text-align: center;
        display: flex;
        justify-content: center;
        font-weight: 400;
        top: 0;
        left: 0;
        visibility: visible;

      }
    }
    .MuiTab-root.Mui-selected {

      .tab_label {
        visibility: visible;
      }
      .tab_label.small {
        visibility: hidden;
      }
    }
  }
  .MuiTabs-indicator {
     visibility: hidden;
    height:10px;

    .mctabs_indicator_container{

      width:100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .mctabs_indicator{
        width: 24px;
        height: 4px;
        visibility: visible;
        /* b2 */

        background: #01AFFD;
        border-radius: 5px;
      }
    }
  }
}


.MuiButton-root.company_btn{
  text-transform: unset;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
  color: #000;
  width: max-content;

  &.Mui-disabled{
    color: #000;
  }
}
.marker::marker {
  //text-align: unset !important;
  //text-indent: unset !important;
  //text-align-last: unset !important;
  //text-transform: unset !important;
  //unicode-bidi: unset !important;
  //font-variant-numeric: unset !important;
}

.tb_list{
  margin-block: unset;
}

[contentEditable=true]:empty:not(:focus):before {
  content: attr(data-text);
  font-size: 12px;
  font-family: Inter;
  margin: 0;
}
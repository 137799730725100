.notification_container {
  width: 100%;
  height: 0;

  display: flex;
  justify-content: center;

  .notification.MuiPaper-root {
    z-index: 999999999;
    font-family: Inter;
    position: absolute;
    bottom: 30px;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2);

    .MuiAlert-icon {
      display: none;
    }

    background: white;
  }
}


.mx_swtich_amsw{
  .MuiSwitch-thumb{
    color: #01AFFD;
  }

  .Mui-checked{
    color: #01AFFD;
  }




}
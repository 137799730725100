.bubble_chart_container{
  font-family: Inter;
  background-color: white;

  .bc_heading{
    width:100%;
    display: flex;
    justify-content: space-between;

    .MuiButton-root{
      column-gap: 10px;
      text-transform: unset;
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      color: #252525;
    }

    .bc_h_item{
      display: flex;
      column-gap: 32px;
    }

    .dropdown_container{
      display: flex;
      align-items: center;
      column-gap: 10px;

      .dropdown_label{
        font-weight: 700;
        font-size: 14px;
        line-height: 160%;
      }
    }

  }
  .bc_sub_heading{
    width:100%;
  }
}

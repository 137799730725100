.reusable_menu {
  font-weight: 400;
  font-family: Inter;
  color: #979797;
  .szh-menu {
    .szh-menu__item {
      color: #979797;
    }
    .szh-menu__item.selected {
      color: black;
      font-weight: 600;

      .MuiButton-root {
        color: black;
        font-weight: 600;
      }
    }
  }

  .link {
    padding: 0;
    text-align: left !important;
  }

  .MuiButton-root {
    width: 100%;
    height: 100%;
    text-transform: unset;
    justify-content: left;
    color: #979797;

    padding: 3px 24px 3px 24px;
  }
}
.menu_item_search{
  display:flex;
  flex-direction: row;

  .MuiFormControl-root{
    width:80%;

    .MuiOutlinedInput-root{
      width:180px;
      height:34px;
      margin-left:16px;
      border-radius:8px;
    }
    .MuiOutlinedInput-root.Mui-focused{
      border:none;
      outline:none;
    }
  }

  .MuiButton-root{
    min-width: 15px;
    color:black
  }
}
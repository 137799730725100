
.lp_container{
  padding: 50px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .lp_paragraph{
    font-family: Inter;
    font-weight: 700;
    font-size: 40px;
    color: #52BEEE;
  }

  .lp_HandHoldingImage{
    width: 668px;
    height: 668px;
  }

  .lp_logoImage{
    width: 322px;
    height: 77px;
  }
}

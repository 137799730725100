.scdfab_dialog_actions_div{
  justify-content: space-between !important;
  background: #F5F5F5 !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

.scdfab_dialog_paragraph{
  margin-top: 20px;
  :first-child{
    margin-top: 10px;
  }
}

.scdfab_dialog_list{
  margin: 0 0 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
  column-gap: 10px;

  :last-child{
    margin-top: 10px;
  }

}

.scdfab_dialog_first_item{
  //width: 200px;
  //min-width: 200px;
}
.fdd_calculated_driver_container{
  //width:-webkit-fill-auto;
  display:flex;
  flex-direction: column;
  align-items:flex-start;
  padding-left:20px;
  padding-right:20px;
  height:452px;

  p{
    margin-bottom:22px;
  }

  .psfccc_white_card_container{
    width: 100%;
    display: grid;
    grid-template-columns: calc(100% - 320px) 320px;

    .psfccc_wcc_cont{
      grid-column-start: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 16px 16px;
    }
  }

  .driver_card{
    overflow-x: overlay;
    margin-bottom:16px;
    background: white;
    border: 1px solid #F0F1F7;
    border-radius: 5px;
    width:-webkit-fill-available;
    width: -moz-available;
    min-height:65px;
    display:flex;
    align-items: center;
    padding-left:15px;
    color:#252525;
  }

  .driver_card.error{
    border-color:red;
  }

  .white_card{
    margin-bottom:16px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    background: white;
    //border: 1px solid #E0e0e0;
    border-radius: 5px;
    padding: 16px 0 16px 16px;
    width: -moz-available;
    width: -webkit-fill-available;

    .notification_text{
      font-size:14px;
      font-family:Inter;
      line-height:160%;
      color:#252525;
      font-style: normal;
      font-weight: 400;
      margin-right:30px;
    }
    .buttons_names{
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 160%;

    }
    .buttons_names.revenue_type{
      display:flex;
      flex-direction: row;
      min-width: max-content;
    }
    svg{
      min-width: 13px;
      min-height: 13px;
    }
    .icon_i{
      margin-right: 37px;
    }
    .button{
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      background: #FBFBFB;
      min-height:34px;
      min-height:34px;
      line-height: 34px;
      border: 1px solid #F0F1F7;
      border-radius: 8px;
      padding-left:16px;
      cursor:pointer;
      margin-right:16px;
      color:black;
      white-space: nowrap;
      padding-right: 16px;
    }
    .button.small{
      padding:0;
      min-width:36px;
      text-align: center;
      margin: 0 6px 0 0;

    }
    .button.small.white{
      background: #fff;
    }
    .button.small.white.complex{
      padding-left: 4px;
      padding-right: 4px;
    }


    .formula_buttons_row{
      width:100%;
      display:flex;
      justify-content: space-between;
      overflow-x: overlay !important;


      .formula_buttons{
        display:flex;
        gap:8px;
        align-items: center;
      }
    }

    .formula_row{
      margin-top:24px;
      display:flex;
      align-items: center;
      column-gap:8px;




      .blue_container{
        background: #E1F2F6;
        width:100%;
        min-height:38px;
        display:flex;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        padding-left:2px;
        overflow: hidden;
        margin-right: 24px;

        .button{
          margin:0 0 0 2px;
        }

      }

      .blue_container.error{
        border-color: red;
      }

      .blue_container:hover{
        overflow-x: overlay;
        overflox-y:hidden;
      }
    }

    .button_row{
      width:100%;
      display: flex;
      justify-content: flex-end;

      .formula_button{
        align-self: right;
        margin-top:15px;
        width:137px;
        height:35px;
      }


    }


    button{
      width:40px;
      height:40px;
    }
    .complex_operator{
      color: #0080BF !important;
      font-weight: 600;
    }
  }

  .white_card.create_formula{
    flex-direction: column;
  }
  .white_card.no_white{
    background: transparent;
  }

  .no_margin_padding{
    padding: 0;
    margin: 0;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
    z-index:99999;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    z-index:99999;

  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(128, 215, 254, 0.7);
    border-radius: 20px;
    z-index:99999;

  }

  ::-webkit-scrollbar-track-piece:start {
    //margin-top: 42px;
    //margin-left:240px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #80D7FE;
  }

  .psfccc_title_div_b{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
}

.spsccc_complex_operator_div{
  display: flex;
  //.spsccc_complex_operator_div{
  //  visibility: visible !important;
  //}
  .mx_numinput {
    max-width: 30px;
    min-width: 30px;
    padding-right: 10px;
    //visibility: visible !important;
  }
  //
  //.mct_ft_dialog_input{
  //  visibility: visible !important;
  //}
  //
  //.mct_ft_editable{
  //  visibility: visible !important;
  //}

}

.spsccc_operator_icon{
  display: flex;
  align-items: center;
  .spscc_formula_name{
    margin-left: 10px;
  }
  .spscc_trash_icon {
    visibility: hidden;
    stroke: red

  }
  &:hover{
    .spscc_trash_icon {
      visibility: visible;
    }
  }
}

.single_tab_display_formula_div {
  display: inline-flex;
  column-gap: 5px;
  margin-top: 4px;
  //max-width: fit-content;

  overflow-x: scroll;
  @media (min-width: 0px) and (max-width: 768px) {
    max-width: 600px;
  }

  @media (min-width: 769px) and (max-width: 950px) {
    max-width: 700px;
  }

  @media (min-width: 950px) and (max-width: 1200px) {
    max-width: 750px;
  }

  @media (min-width: 1200px) and (max-width: 1300px) {
    max-width: 850px;
  }
  @media (min-width: 1301px)and (max-width: 1400px)   {
    max-width: 1200px;
  }
  @media (min-width: 1401px)and (max-width: 1500px)   {
    max-width: 1300px;
  }
  @media (min-width: 1501px)  {
    max-width: 1400px;
  }
}

.single_tab_display_formula_button {
  display:flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: space-between;
  background: white;
  border-radius: 5px;
  padding: 5px 15px 5px 15px;
  width: -moz-available;
  width: -webkit-fill-available;
  font-size: 14px;
}


.spsfccc_ref_div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 550px;
  padding-top: 5px;
  padding-bottom: 5px;
  .MuiButtonBase-root{
    height: 25px;
  }
}
.spss_settings_div{
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;



  .spss_settings_button_save{
    width: 1127px;
    display: flex;
    justify-content: flex-end;
  }
}

.spss_cards_container{
  display: flex;
  column-gap: 16px;
  width: 100%;
  height: 100%;
  justify-content: center;

  .spss_cards_container_input{
    margin-top: 15px;
    width: 100%
  }

  .spss_cards_container_label{
    margin-top: 8px;
    width: 100%;
  }

  .spss_cards_container_logo{
    margin-top: 19px;
    width: 100%

  }

  .spss_cards_logo_image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &.disabled{
      cursor: unset;
    }
  }
  .spss_image_style{
    width: 120px;
    height: 120px;
  }

  .spss_cards_image_upload{
    display: flex;
    column-gap: 15px;
    align-items: center;
    margin-top: 25%;
    .spss_cards_upload_icon{
      padding-top: 1px;
    }


  }
  .spss_format_allowed{
    width: 100%;
    text-align: left !important;
    margin-top: 10px
  }

  .spss_container_inv_input{
    width: 100%;
    height: 100%;
    background: red;
    top:0;
    left:0;
    display: none !important;
  }
  .spss_logo_actions{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    column-gap: 10px;
  }

  .spss_second_container_label{
    width: 100%;
    padding-top:2px;
    padding-left: 2px;
  }

  .spss_second_container_date{
    width: 100%;
    padding-top:10px;
    padding-left: 2px;
  }

  .spss_second_container_input{
    width: 100%;
    padding-top:16px;
    padding-left: 2px;
    &.remove_padding_top{
      padding-top: 0;
    }
  }

  .spss_second_container_currency{
    padding-left: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

  }
  .spss_seoncd_container_currency_input{
    margin-top: 16px;
    display: block;
    width: 100%;
    .spss_second_container_currency_label{
      margin-bottom: 15px;
    }
  }


}

.spss_settings_err{
  font-size: 12px;
  margin-top: 20px;
  color: red;
  font-family: Inter;
  height: 20px;
  display: flex;
  align-content: space-between;
  justify-items: center;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
}

.spss_settings_msg{
  font-size: 12px;
  padding: 0;
  margin: 0;
  font-family: Inter;
  text-align: left;
  align-self: flex-start;
}


.spss_logo_text{

}

.spss_settings_text_message_logo{
  font-size: 14px;
  font-family: Inter;
  color: #01AFFD;

}


.spsss_settings_input_save_mask{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1010;
  display: content;
}
.spss_settings_set_zIndex{
  z-index: 998
}

.spss_settings_div_index{
  z-index: 1011;
}
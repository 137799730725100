.font_size_container{
  width:102px;
  border: 1px solid #F0F1F7;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  height: 33px;
  align-items: center;

  .input_btn_container{
    display: flex;
    width:40px;
    height:100%;


    input{
      width:23px;
      z-index: 10;
      outline: none;
      border: none;
    }

    button{
      margin: 0!important;
      padding:0;
    }
  }

    .font_size_button{
      margin: 0 !important;
      padding:0;
      width:100%;
      height:100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child{
        border-right: 1px solid #F0F1F7 !important;
      }

      &:last-child{
        border-left: 1px solid #F0F1F7 !important;
      }
    }
  .font_size_button.active{
    color: #01AFFD ;
  }


    .font_size{
      width:100%;

    }
}

.font_size_menu{
  .szh-menu--state-open{
    display:block !important;
  }
  .szh-menu{
    width: 37px;
    min-width: 0;
    //left:-70px !important;

    .szh-menu__item{
      padding: 1px 0 1px 0;
      align-items: center;
      justify-content: center;
    }
  }
}
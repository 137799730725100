.se_right_menu_container{
 .closing_btn{
   transform: scale(-1);
   left:-14px;
   position: absolute;
   border: 1px solid #F0F1F7;
 }
}
.se_right_menu_container.hidden{
  .closing_btn{
    transform: scale(1);
    left:-20px;
  }

  .MuiList-root{
    display:none;
  }
}

.options_menu {
  position: relative;

  .closing_btn{
    width:28px;
    height:28px;
    background: #FFF;
    border-radius: 50%;
    border: 1px solid #F0F1F7;
    position: absolute;
    left:-14px;
    z-index: 1;
    top:15px;
  }

  .templates_collapse {

    .MuiCollapse-wrapperInner {
      padding-top: 5.5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 290px;
      overflow-y: auto;

      .template_container {
        margin: 5.5px;
        border: 1px solid #FAFBFE;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        position: relative;
        width: 200px;
        height: 112.5px;
        aspect-ratio: 16/9;
        padding:0;

        .selection_checkbox {
          z-index: 2;
          position: absolute;
          right: -7px;
          top: -7px;
        }
        .selection_checkbox.hidden {
          visibility: hidden;
        }
        .template_img {
          width: 200px;
          border-radius: 5px;
        }

      }

      .template_container.selected {
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
      }

      //#template_buttons {
      //  position:sticky;
      //  bottom:0;
      //  width: 100%;
      //  display: flex;
      //  justify-content: space-around;
      //  background: white;
      //}

      #template_helper_buttons{
        position:sticky;
        bottom:0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: white;
      }
    }

    &.bp{
      .MuiCollapse-wrapperInner{
        height: auto;
      }
    }
  }
  .instructions_collapse {
    .MuiCollapse-wrapperInner {
      padding: 14px 15px 0 17px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      text-align: left;
    }
  }
}
.options_menu.hidden {
  display: none;
}

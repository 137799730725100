.spsvd_container_table{
  .finance_table_main_wrapper_div{
    margin: 25px 0 0 0 !important;
  }

  .mct_ft_tablerow_header{
    //border-left: none !important;

  }

  .finance_table_main_wrapper_div{
    border: none !important;
  }
  .mct_ft_tablehead_cell.max_height {
    border-left: 1px solid #E0E0E0 !important;
  }

  .mct_ft_tablerow{
    width: 100% !important;
  }

  .MuiTableRow-head{
    width: 100% !important;
  }
  .mct_ft_tablehead_cell {
      border-top: 1px solid #E0E0E0 !important;
  }

}
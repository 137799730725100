.portfolio_management{
  display: grid;
  overflow: hidden;
  .p_m_main_container{
    padding-top: 50px;
    overflow: hidden;
    justify-self: center;

    @media (max-height: 870px) {
      padding-top: 20px;

      .white_card{
        height: calc(100% - 50px) !important;
      }
    }

    .white_card{
      padding:20px;
      height: calc(100% - 120px);
      width: 1400px;
      background-color: white;
      border-radius: 8px;
      display: grid;
      grid-template-rows: 60px 74px auto;
      row-gap: 20px;
      overflow: hidden;

      .empty_div{
        width: 150px;
      }

      @media (max-width: 1465px) {
        width:auto;
      }

      .title_container{
          padding:0 5px;
          display: flex;
          align-items: center;
          column-gap: 30px;

        .card_title{
          font-family: Inter;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
        }
      }
      .MuiTableCell-root {
        font-family: Inter;

        &.bold{
          font-weight: 700;
        }
      }

      .table_container{
        overflow: auto;
      }

      .main_table_container{
        display: grid;
        overflow: hidden;
        grid-template-rows: 36px auto;

        .search_box{
          display: flex;
          justify-content: space-between;

          .szh-menu{
            right: -66px;
            left: unset !important;
          }
        }
      }

      th{
        z-index: 999;
      }

      td{
        &:first-child{
          width:205px;
        }
        &:last-child{
          max-width:330px;
          min-width:330px;
        }
        &:nth-child(3){
          width:240px;
          //width:240px;
        }
        overflow: hidden;
        text-overflow: ellipsis;
      }

      th.MuiTableCell-root{
        font-weight: 700;
      }

      .p_stages{
        display: flex;
        justify-content: space-around;
        column-gap: 30px;

        .p_stage{
          width: 150px;
          height: 74px;
          border-radius: 5px;

          .p_stage_text{
            font-size: 16px;
            line-height: 19px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height:100%;
          }
        }
      }
    }
  }
}
.edit_stages_dialog{
  .MuiDialogContent-root{
    overflow: visible;
  }
  .MuiPaper-root{
    overflow: visible;
  }

  .MuiTableBody-root{
    position:relative;

    tr{
      background-color: white;
    }

    td:first-child{
      min-width:355px;
    }
    td:nth-child(2){
      min-width:120px;
    }
  }

  .MuiTableCell-root{
    font-family: Inter;
    .name_holder{
      width:100%;
    }

    .szh-menu__item{
      height:36px;
      color: red;
    }
    .flex{
      column-gap: 10px;
      align-items: center;
      display: flex;

      &.end {
        justify-content: flex-end;
      }

      .blank_btn {
        min-width: 36px;
      }

      &.center {
        justify-content: center;
      }
    }

    .color_box{
      width:36px;
      height:35px;
      border-radius: 3px;
    }
  }
  .dialog_actions{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    padding-bottom: 16px;
  }
}
.cai_custom_input{
  touch-action: none;
  background-repeat: no-repeat;
  box-sizing: border-box;
  text-align: start;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  width: 100%;
  color: #3A3A3A;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  font-family: Inter;
   .MuiOutlinedInput-root.fieldset{
     border: transparent;

   }

  .MuiOutlinedInput-notchedOutline{
    border: 1px solid #E0E0E0 !important;
    //border-color: #E0E0E0;
    :hover{
      border-width: unset !important;
      //border: 1px solid #E0E0E0 !important;
    }

  }


  .MuiInputBase-input{
    padding: 0 16px 0 16px;
    font-size: 14px !important;
    font-family: Inter;


  }

  .MuiInputBase-root{
    height: 34px !important;
    font-size: 14px !important;
    font-family: Inter;

  }

  &:focus {
    outline: none;
    border: none;

  };
  ::placeholder {
    opacity: 1;
  }

  :-ms-input-placeholder {
    font-size: 14px !important;
    font-family: Inter;
  }

  .Mui-focused{
    .MuiInputBase-input-{
      outline: none !important;
      font-size: 14px !important;
      font-family: Inter;
    }
  }
  //& .MuiOutlinedInput-root{
  //  background: #FFFFFF;
  //  /* Light_Grey/150 */
  //  padding: 0;
  //  border: 0.821429px solid #F0F1F7;
  //  box-sizing: border-box;
  //  border-radius: 6.57143px;
  //  color:#D4D5DE;
  //  height:24px;
  //  width:48px;
  //  font-size: 10px;
  //  input::-webkit-outer-spin-button,
  //  input::-webkit-inner-spin-button {
  //    -webkit-appearance: none;
  //    margin: 0;
  //  }
  //
  //  /* Firefox */
  //  input[type=number] {
  //    -moz-appearance: textfield;
  //  }
  //
  //  &:active{
  //    border: none;
  //  }
  //
  //  &:hover{
  //    border: none;
  //  }
  //
  //
  //  & .MuiTypography-root{
  //    color:#D4D5DE;
  //    align-self: center;
  //    margin-left: -12px;
  //    width: 4px;
  //
  //    font-family: 'Inter';
  //    font-style: normal;
  //    font-weight: 400;
  //    font-size: 10px;
  //    line-height: 160%;
  //  }
  //
  //}
  & .MuiOutlinedInput-input {
    padding: 0 0 0 20px;
    text-align: left;

    font-size: 14px
  }

  & .MuiOutlinedInput-root.Mui-focused{
     border: none !important;
   }
  & .MuiOutlinedInput-root:active{
    border: none;
  }
  & .MuiOutlinedInput-root:focus{
    border: none;
  }
}
.scdfci_cash_balance_div{
  display: flex;
  padding: 0;
  font-size: 14px;
  text-overflow:ellipsis;
  min-width: 200px;
  align-items: center;
  justify-content: center;
  .MuiTouchRipple-root:focus-visible{
    outline: none !important;
    background: transparent;
  }
  .MuiTouchRipple-root{
    display: none;
  }

  .MuiButtonBase-root:hover{
    background-color: transparent !important;
  }
  .MuiButtonBase-root{
    min-width: 350px;
    width: auto;
  }
  .scdfci_text_balance{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: red;
    font-size: clamp(10px, 0.9vw, 14px);

  }
  .scdfci_black_color{
    color: #252525;
    margin-left: 20px;
  }
}

.scdfci_cash_balance_actuals{
  margin-top: 0;
  margin-bottom: 5px;
}

.scdfci_text_button{
  color: #01AFFD;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 5px;
  font-size: clamp(10px, 0.9vw, 14px);
  margin-right: 5px;

}
.scdfci_text_button.italic{
  font-style: italic;
}

.scdfci_dialog_div{
  font-size: 14px;
  font-family: 'Inter';
  margin-left: 25px;
  ul {
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
  }


  //li.menu-item:not(:last-child) {
  //  margin-bottom: 3px;
  //}
}

.scdfci_list_item{
  margin-top: 5px
  //margin-bottom: 5px;
  //color: red;
}

.scdfci_title_dialog{
  font-size: 20px;
}

.scdfci_link_button{
  text-decoration: none;
}
.text-input {
  &::placeholder {
    // font-style: italic;
  }

  box-sizing: border-box;
  width: 100%;

  &.error {
    border-color: rgba(255, 0, 0, 0.5);
    box-shadow: 0 0 5px rgba(150, 0, 0, 0.5);
    color: black;
  }

  &.password {
    padding-right: 40px !important;
  }
}

.input-err-message {
  margin-top: 5px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0;
  color: rgba(255, 0, 0, 0.5);
}

.input-err-tip {
  margin: 10px 0;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0;
  color: rgba(169, 169, 169, 1);
}

.input-end-adornment-container {
  position: relative;

  .input-adornment {
    position: absolute;
    top: 50%;
    right: 5px; /* Adjust the distance from the right */
    transform: translateY(-50%);
    color: rgba(169, 169, 169, 1);
  }
}


.transactions_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .table_container{
    height: 475px;
    overflow: auto;

    .MuiTableSortLabel-root .MuiTableSortLabel-icon {
        opacity: 0.5;
    }
  }
  .button_container {
    display: flex;
    justify-content: flex-end;
    &.margin{
      margin-top: 15px;
    }
  }
}
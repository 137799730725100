.mx_numinput {
  width: 100%;
  height: 30px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  font-family: Inter !important;
  &.mct_ft_dialog_input{
    border-radius: 8px !important;
    height: 32px !important;
    width: 264px;
    border: 1px solid #E0E0E0 !important;
    min-width: 140px;
  }
  &.mct_ft_dialog_input_small{
    border-radius: 8px !important;
    height: 32px !important;
    width: 164px;
    min-width: 140px;
  }
  &.mct_ft_dialog_input_error{
    border: 1px solid red !important;
  }
  .mx_numinput_input.mct_ft_disabled{
    color: #D4D5DE;
  }
  .mx_numinput_input {
    background-color: unset;
    display: flex;
    align-items: center;
    justify-content: right;
    //width: 90%;
    height: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    //line-height: 160%;
    border: none;
    text-align: right;
    padding: 0 5px 0 0;
    color: #979797;
    vertical-align: center;
    white-space: nowrap;
    &:focus {
      outline: none;
      border: none;

    }
  }

  .mx_numinput_input.mct_ft_editable{
    color: #01AFFD;
    font-weight: 500;

    &.blue_color{
      color: #01AFFD;
    }
  }

  .mct_ft_bold-{
    color: #252525;
    font-weight: 300 !important;;
  }
  .mct_ft_bold-0{
    color: #252525;
    font-weight: 700 !important;;
  }
  .mct_ft_bold-1{
    color: #252525;
    font-weight: 500 !important;
  }
  .mct_ft_bold-2{
    //color: #252525;
    font-weight: 300 !important;;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  input.mx_numinput_input{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    padding-right: 6px;
    background-color: transparent;
    color: transparent;
    font-family: Inter;

    &:focus{
      background-color: white;
      color: #252525;
    }
  }






}

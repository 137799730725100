.sc_to_table{

  &, tr, td{
    border-spacing: 0;
    padding:0;
    text-wrap: normal;
    word-break: break-word;
    border-collapse: collapse;
    position: relative;
  }

  tr:last-child{
    .sc_cell_bottom{
      //display: none;
    }
  }

  td:last-child{
    .sc_cell_right{
      display: none;
    }
  }

  td{
    min-width: 10px;
  }

  .sc_cell_top{
    position: absolute;
    top: -4px;
    left:0;
    width: 100%;
    height: 7px;
    z-index: 10;
    cursor: row-resize;
  }

  .sc_cell_right{
    position: absolute;
    top:0;
    right:-4px;
    height: 100%;
    width: 7px;
    z-index: 10;
    cursor: col-resize;
  }

  .sc_cell_bottom{
    position: absolute;
    bottom:-4px;
    left:0;
    width: 100%;
    height: 7px;
    z-index: 10;
    cursor: row-resize;
  }

  .sc_cell_textbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

.sp_cp_mainpanel_selected{

  .sc_cell_selected{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00c4cc20;
  }
}

table.sc_to_table_selected, .sc_to_table_selected * th, .sc_to_table_selected * td, .sc_to_table:hover, .sc_to_table:hover * th, .sc_to_table:hover * td {
  &.hover{
    border: 1px dashed black;
  }

}

.pfl_email {
  .error {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 160%;
    color: #FF3D3D;
    padding-left: 8px;
  }
  .error.invisible {
    visibility: hidden;
    //margin-bottom: -10px;
  }

  .pfl_login_card_inputs {
    span{
      padding: 0;
      margin: 0;
    }
    .error {
      height: 12px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: #FF3D3D;
      padding-left: 8px;
    }
    .error.invisible {
      height: 12px;
      visibility: hidden;
    }


  }
}
.MuiAutocomplete-root {
  //width: 265px;
  height: 34px !important;
  background-color: white;
  border-radius: 8px;

  .MuiFormControl-root {
    height: 34px;

  }

  .MuiOutlinedInput-root {
    height: 34px;
    padding: 0 !important;
    border: 1px solid #F0F1F7;
    border-radius: 8px;

    .MuiOutlinedInput-input {
      height: 28px;
      padding: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      color: #252525;
      margin-left: 4px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiInputAdornment-root {
      width: 12px;
      padding-right: 5px;
    }
  }
}

.MuiAutocomplete-popper {
  z-index: 100000;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #D4D5DE;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #80D7FE;
  }
  .MuiAutocomplete-listbox {
    max-height: 270px;
    overflow: overlay;
    padding: 0;


    background: white;
    &li:nth-child {
      background: white;

    }
  }

}

.MuiAutocomplete-option{
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #211125;

  &:hover {
    .pscai_autocomplete_hightlight {
      color: #01AFFD !important;
    }
  }

}


.MuiAutocomplete-paper {
  box-shadow: none;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: rgba(0, 0, 0, 0.01);
}


.create_management_report_dialog {
  .MuiPaper-root {
    position: relative;
    max-height: 700px;
    width: 960px;
    padding: 20px;
    font-family: Inter;
    color: #252525;
    font-style: normal;

    .flex {
      display: flex;

      &.m_l {
        margin-left: 15px;
      }
    }

    .cmrd_close_button {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .cmrd_title {
      font-weight: 700;
      font-size: 20px;
    }

    .cmrd_title_2 {
      font-weight: 700;
      font-size: 17px;
      padding-right: 20px;
    }

    .cmrd_message {
      width: 100%;
      box-sizing: border-box;
      font-size: 13.5px;
      line-height: 160%;
    }

    .cmrd_name {
      margin-top: 20px;

      .cmrd_input {
        width: 276px;
        margin-top: 10px;
        margin-bottom: 10px;

        &.wide {
          width: 472px;
        }

        &.mid_wide {
          width: 304px;

        }
      }

      &.flex {
        //justify-content: space-between;
        align-items: center;


      }
    }

    .cmrd_select_dashboard {
      margin-top: 30px;

      .cmrd_message {
        margin-top: 15px;
      }
    }

    .cmrd_buttons_container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      column-gap: 30px;
    }

    .cmrd_customDashboardsContainer {
      box-sizing: border-box;

      padding: 0 0 10px 0;
      width: 100%;
      display: flex;
      overflow: auto;

      .custom_dashboard {

        cursor: pointer;
        box-sizing: border-box;
        margin: 16px 0 0 16px;
        min-width: 220px;
        height: 160px;

        background: #FFFFFF;
        /* Light_Grey/150 */

        border: 1px solid #F0F1F7;
        /* cards */

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        overflow: hidden;

        .mr_svg_container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 0;
        }

        .custom_dashboard_name {
          font-family: 'Inter';
          font-size: 14px;
          box-sizing: border-box;
          padding: 10px;
          color: #FFF;
          background: linear-gradient(80.96deg, rgba(21, 45, 157, 0.8) 0%, rgba(0, 177, 254, 0.8) 100%),
          linear-gradient(0deg, #F9F9F9, #F9F9F9);
          width: 100%;

          height: 60px;

          font-weight: 700;
          line-height: 20px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;

        }

        &:hover {
          background: #F8FEFF;
        }

      }

      .custom_dashboard.selected {
        box-shadow: 0 4px 4px 0 #0000001A inset;
        background: #E1F2F6;
      }
    }
  }
}
.management_reports {
  .flex {
    display: flex;
    padding-right: 15px;


    .r_name {
      width: calc(100% - 30px);

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
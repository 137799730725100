.payment_failed_dialog {
  .mslt_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    width: 100%;

    p{
      margin: 0 auto;
    }

    .p1Strong{
      font-weight: bold;
      font-size: 20px;
    }
  }

  .MuiPaper-root {
    height: 160px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    padding: 10px;
  }
}
//#MenuItems:hover {
//    color:black
//}
#FontFamilyEdit {
    padding-left: 16px;
    padding-right: 16px;
    width: 285px;
    height:45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:#B9BFC3;

    #fontFamilyType{
        width:85%;
    }
}
#FontFamilyEdit:hover #fontFamilyType{
    color: black;
}

.bold{
    font-weight: bold;
}


.active{
    color: #01AFFD;
}

#FontFamilyEditTitle {
    padding-left: 16px;
    padding-right: 16px;
    width: 285px;
    height:45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.SubMenuButton:hover .undoIcon{
    stroke: #D4D5DE;

}
.SubMenuButton:hover .undoIconFill{
    fill: #D4D5DE;
}

.SubMenuButton:hover #tooltipSubMenuButton{
    visibility: visible;
}

.SubMenuButton{
    position: relative;
    display: inline-block;

}
#tooltipSubMenuButton {
    visibility: hidden;
    height: 20px;
    width: 100px;
    color: black;
    font-size: 10px;
    background-color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    margin-top:6px;
    left: -30px;
    top:130%;
    z-index: 1;
    border: 1px solid #F0F1F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
#tooltipSubMenuButton:before {
    content: '';
    display: block;
    position: absolute;
    left:41px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-bottom-color: #F0F1F7;
}

#tooltipSubMenuButton:after {
    content: '';
    display: block;
    position: absolute;
    left: 42px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: white;
}

.mx_main_loader {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  top: 0;
  left: 0;
  background-color: #FFF;
  align-items: center;
  justify-content: center;
  z-index: 9999 !important;

  &.blurred {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    z-index: 99 !important;
  }
}

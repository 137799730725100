
.financial_main_total_wrapper {
  display: flex;
  padding-bottom: 42px;

  .financial_main_total_monthly_chart_container {
    box-sizing: border-box;
    width: 76%;
    //padding-right: 20px;
    //padding-left: 25px
  }
  .financial_main_total_yearly_chart_container {
    box-sizing: border-box;
    width: 24%;
    //padding-right: 20px;
    //padding-left: 25px
  }
}
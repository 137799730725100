.sccc_chart_menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;
  height: 42px;

  .spsffcf_title{
    margin-right: 10px;
  }
  .sccc_chart_icon{
    display: flex;
    //column-gap: 15px;
    align-items: center;
    justify-content: space-between;
    //width: 100px;
    line-height: 100% !important;
  }
  .sccc_chart_divider{
    width: calc(100% - 170px);
  }
}

  .main_chart_seris_container{
    display: flex;
    gap: 4px;
    justify-content: center;
    background-color: white;
    height: 155px;
    .chart_series_row{
      display: block;
      height: 1.5rem;
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }

  .chart_series_title{
    text-align: center;
    width: 100%;
    font-family: Inter;
  }

  .chart_seris_menu{
    .szh-menu{
      width: 290px;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 15px;

      .sketch-picker{
        width: 269px !important;
      }


      .sccc_chart_color_picker_button{
        text-align: center;
        font-family: Inter;
      }
    }
  }


  .scccc_chart_color_shades_menu{
    .szh-menu{
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 15px;
    }
  }
.sccc_main_container_chart_colors{
  .szh-menu__item{
    font-size: 14px !important;
    font-family: Inter !important;
  }
}
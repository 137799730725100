.pscfc_single_finance_calculated_driver_title{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formula_bar_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.psfcf_single_finance_calculated_driver_button{
  display: flex;
  column-gap: 0;
  //margin-right: 10px;
  align-items: center;
  //width: 170px;
  justify-content: flex-end;
  padding-right: 60px;
}


.psfcf_single_finance_calculated_driver_button_actual{
  //width: 340px;
}
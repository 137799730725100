.financials_navigation_dropdown {
  display: none;
  margin-top: -1px;
  //margin-left:25px;
  height: 39px;

  .svg_container {
    height: 24px;
    width: 24px;
  }

  .reusable_menu {
    z-index: 999;
  }
}

.financials_nav_bar_container {
  max-width: 430px;
  min-width: 380px;
  width: 100%;
  display: flex;
  //justify-content: space-between;
  padding-right: 10px;
  justify-content: flex-end;
  grid-column-gap: 20px;
  align-items: center;
}

.loader_chart_container {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
  height: -moz-available;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader_charts {
    z-index: -1;
    width: 600px;
    height: 100px;
    display: flex;
    justify-content: center;
  }
}

.loader_tr_m_td_m {
  width: -webkit-fill-available;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 10%;
}

.spsi_financials_navigation_streams_div {
  margin-top: -4px;
  width: 100%;
  justify-content: flex-start;
  padding-left: 17px;
}

.spsi_financial_nav_bar_scen_button :first-child {
  text-transform: none !important;
  text-align: center !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 160% !important;
}

.spsi_financials_navigation_streams_div.apply_hidden {
  @media (max-width: 1280px) {
    //max-width: 750px !important;
    //.MuiTabs-centered{
    //  :nth-child(n+7){
    //    display: none !important;
    //  }
    //}
  }
}

.instruction_button {
  @media (max-width: 1481px) {
    //1481px 1362px
    display: none !important;
  }
}

.instruction_container {
  display: flex;
}

.pfr_main_revenue_content {
  overflow-y: auto;

  .pfr_main_cont {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;

    &.revenue {
      overflow-y: scroll;
    }

    .psf_upper_table_cont {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 100%;
      overflow: hidden;

      .psf_main_streams_wrapper_div {
        max-height: -moz-calc(100vh - 287px) !important;
        /* WebKit */
        max-height: -webkit-calc(100vh - 287px) !important;
        /* Opera */
        max-height: -o-calc(100vh - 287px) !important;
        /* Standard */
        max-height: calc(100vh - 287px) !important;
        //height: 95%;
      }
    }
  }
}

.pfr_main_revenue_content.streams {
  display: grid;
  grid-template-columns: 100%;
  //grid-template-rows: 76px auto 88px;
  //grid-template-rows: 76px auto 10px;
  align-content: start;
  overflow-y: auto;

  .main_table_container {
    padding: 0 16px 0 16px;
    max-height: calc(100vh);
    //min-height: 190px;
    overflow-y: hidden;

    .psf_reports_export_pdf {
      height: 100%;
      overflow-y: hidden;
      max-height: 100%;

      &.dashboard {
        .spsffcf_warning_div {
          padding: 0;
        }

        .sccc_chart_divider {
          width: calc(100% - 240px);
        }

        .sccc_chart_icon {
          //width: 170px;
          white-space: nowrap;
        }

        .spsffcf_collapse_div {
          //width: 170px;
        }

        .spsffcf_title {
          white-space: nowrap;
          margin-right: 10px !important;
        }
      }
    }

    .finance_table_main_wrapper_div {
      margin: 0;
      max-height: -moz-calc(100% - 16px);
      /* WebKit */
      max-height: -webkit-calc(100% - 16px);
      /* Opera */
      max-height: -o-calc(100% - 16px);
      /* Standard */
      max-height: calc(100% - 16px);
      //height: 95%;
      overflow-y: auto;
       margin-bottom: 16px;

      //margin-bottom: 16px;
      &.finance_table_main_wrapper_div_kpi {
        max-height: -moz-calc(100% - 27px);
        max-height: -webkit-calc(100% - 27px);
        max-height: -o-calc(100% - 27px);
        max-height: calc(100% - 27px);
      }
    }

    .finance_table_main_wrapper_div_extra_height {
      margin: 0;
      max-height: -moz-calc(100% - 42px);
      /* WebKit */
      max-height: -webkit-calc(100% - 42px);
      /* Opera */
      max-height: -o-calc(100% - 42px);
      /* Standard */
      max-height: calc(100% - 42px);
      //height: 95%;
      overflow-y: auto;
    }

    .finance_table_main_wrapper_emp_sch_extra_height {
      margin: 0;
      max-height: -moz-calc(100% - 53px);
      /* WebKit */
      max-height: -webkit-calc(100% - 53px);
      /* Opera */
      max-height: -o-calc(100% - 53px);
      /* Standard */
      max-height: calc(100% - 53px);
      //height: 95%;
      overflow-y: auto;
    }
  }
}

.pfr_main_revenue_content.forecast_streams {
  grid-template-rows: 55px auto;
  overflow-y: hidden;
}

.pfr_main_revenue_content.forecast_reports {
  display: grid;
  grid-template-columns: 100%;
  //grid-template-rows: 76px auto 88px;
  //grid-template-rows: 56px auto 10px;

  overflow-y: auto;

  .main_table_container {
    padding: 0 16px 0 16px;
    max-height: 100%;
    overflow-y: hidden;

    .psf_reports_export_pdf {
      height: 100%;
      overflow-y: hidden;
      max-height: 100%;
    }

    .finance_table_main_wrapper_div {
      margin: 0;
      max-height: -moz-calc(100% - 16px);
      /* WebKit */
      max-height: -webkit-calc(100% - 16px);
      /* Opera */
      max-height: -o-calc(100% - 16px);
      /* Standard */
      max-height: calc(100% - 16px);
      //height: 95%;
      overflow-y: auto;
      //margin-top: 16px;
      //margin-bottom: 16px !important;
    }
  }
}

.pfr_main_revenue_content.reports {
  display: grid;
  grid-template-columns: 100%;
  //grid-template-rows: 76px auto 88px;
  grid-template-rows: 76px auto 10px;

  overflow-y: auto;

  .main_table_container {
    padding: 0 16px 0 16px;
    max-height: 100%;
    overflow-y: hidden;

    .psf_reports_export_pdf {
      height: 100%;
      overflow-y: hidden;
      max-height: 100%;
    }

    .finance_table_main_wrapper_div {
      margin: 0;
      max-height: -moz-calc(100% - 16px);
      /* WebKit */
      max-height: -webkit-calc(100% - 16px);
      /* Opera */
      max-height: -o-calc(100% - 16px);
      /* Standard */
      max-height: calc(100% - 16px);
      //height: 95%;
      overflow-y: auto;

      //margin-bottom: 16px !important;
    }
  }
}

//@media (max-width: 1280px) {
@media (max-width: 1481px) {
  .nav_f {
    padding-right: 0 !important;
  }
  .financials_navigation_bar.financials {
    display: none !important;
  }
  .financials_navigation_dropdown {
    display: inline-block;
  }
}

@media (max-width: 1481px) {
  //1481px 1362px
  .financials_navigation_dropdown {
    display: inline-block;
  }
}

@media (max-width: 1245px) {
  //1481px 1362px
  .mr_btn {
    display: none !important;
  }
  .mr_menu_btn {
    display: block !important;
  }
}

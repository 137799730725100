.date_picker_year_month{

  .date_picker_input{
    width:100% !important;

  }
  .MuiInputBase-input{
    font-size: 12px !important;
  }

  .MuiInputBase-root{
    max-height: 34px !important;
    background: white;
    border-radius: 8px !important;
    //font-size: 16px !important;
  }
}
.MuiTextField-root{
  width: 100%;

  &:hover{
    border-color: red !important;
  }
}


//.MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline:hover{
//  border-color:red !important;
//
//  &:hover{
//  }
//}


.MuiPickersCalendarHeader-label{
  //font-size: 16px !important
}

.Mui-focused{
    //color: red !important;
}

.MuiOutlinedInput-root{
  width:100% !important;

  & fieldset{
    border-color:#F0F1F7;
    border-radius: 8px;
  }
  &:hover fieldset{
    border-color:#F0F1F7 !important;
  }




  .MuiSvgIcon-root{
    fill:#B9BFC3;
    width:15px;
    height: 15px;
  }
  //background: white;
  //color:#B9BFC3;
}
.most_used_title{
  padding: 10px;
  font-size: 10px;
  color: #000000;
  font-weight: 700;
  font-family: Inter;
}

.color_container{
  width: 200px;
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  .color_box{
    background: rgb(74, 144, 226);
    height: 16px;
    width: 16px;
    cursor: pointer;
    position: relative;
    outline: none;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
  }
}
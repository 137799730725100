.form-control {
    color: #585858;
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    margin-bottom: 2px;
}

.container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;

}

/*input[type=checkbox] {*/
    /*position: relative;*/
    /*cursor: pointer;*/
/*}*/
/*input[type=checkbox]:before {*/
    /*content: "";*/
    /*display: block;*/
    /*position: absolute;*/
    /*width: 13px;*/
    /*height: 13px;*/
    /*top: 0;*/
    /*left: 0;*/
    /*background-color:#e9e9e9;*/
    /*border-radius: 2px;*/
/*}*/
/*input[type=checkbox]:checked:before {*/
    /*content: "";*/
    /*display: block;*/
    /*position: absolute;*/
    /*width: 13px;*/
    /*height: 13px;*/
    /*top: 0;*/
    /*left: 0;*/

    /*border-radius: 2px;*/
/*}*/
/*input[type=checkbox]:checked:after {*/
    /*content: "";*/
    /*display: block;*/
    /*width: 3px;*/
    /*height: 7px;*/
    /*border: solid white;*/
    /*border-width: 0 1.5px 1.5px 0;*/
    /*-webkit-transform: rotate(45deg);*/
    /*-ms-transform: rotate(45deg);*/
    /*transform: rotate(45deg);*/
    /*position: absolute;*/
    /*top: 0.5px;*/
    /*left: 4.25px;*/
/*}*/
.sales:checked:before{
    background-color: #004D8C;
}
.subscription:checked:before{
    background-color: #0088F7;
}
.services:checked:before{
    background-color: #01AFFD;
}
.production:checked:before{
    background-color: #80D7FE;
}



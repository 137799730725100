
.psfac_actual_navigation{
  display: flex;
  height: 76px;
  max-height: 76px;
  justify-content: space-between;
  margin-left: 21px;
  margin-right: 35px;
  margin-top: 2px;
  margin-bottom: 11px;
  align-items: center;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  font-family: Inter;

  .psfac_actual_title{
    white-space: pre;
    min-width: 200px;
  }

  .psfac_actual_group_buttons{

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    font-family: Inter;

  }

  .psfac_actual_filters{
    width: -webkit-fill-available;
    margin-left: 100px;
    display: flex;
    align-items: center !important;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;

    .psfac_actual_filters_date{
      display: flex;
      align-items: center !important;


    }
  }
}
.psfac_actuals_single_filter{
  display: flex !important;
  align-items: center !important;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;

  .date_picker_year_month{
    max-width: 120px !important;
    width: 120px;
    min-width: 120px;

  }


  &.vertical{
    flex-direction: column;
    align-items: flex-start !important;
  }

  &.gap{
    row-gap:7px;
  }

  &.align_label{

    row-gap:7px;
    margin-bottom: 19px;
  }

}

.psfac_actuals_single_input_select{
  .MuiInputBase-root{
    max-width: 200px;
    min-width: 200px;

  }
}

.psfaci_actions{
  justify-content: center !important;
  background: #F5F5F5 !important;
  margin-left: 16px !important;
}
.psfaci_actual_filters{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
}

.psfac_actuals_single_dialog_input{
  max-width: 180px;
}

.psfaci_import_container{
  //height: 183.139px;
  //min-height: 183.139px;
  overflow-y: hidden;

}


.psfaci_import_div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.psfac_dialog_actual{
  display: inline-flex !important;
  width: 100%;
}

.psfac_dialog_actual_single_filter{
  display: flex;
  align-items: center;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  width: 240px;
  min-width: 240px;
}
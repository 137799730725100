.scadfi_dialog_container{
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between !important;
  width: 100%;


  .scadfi_action_middle_buttons{
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;
  }

  .scadfi_save_btn{
    display: flex;
    justify-content: flex-start;
    margin-right: 50px;
    width: 170px;
  }




}


.scdi_info_dialog_container{
  position: fixed;
  bottom: 0;
  width: 100%;
  min-width:1024px;
  max-height:100%;
  .scdi_info_dialog_div{
    margin-left: 5px;
    margin-right: 18px;
    background: #F5F5F5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }
}

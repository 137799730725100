.scaa_select_m_input_label{
  display: flex;
  max-height:  34px;
  font-size:  12px !important;
  font-weight:  400;
  line-height:  160%;
  font-style: normal;

}

.scaa_select_input{
  max-height:  34px;
  //minWidth: props.minWidth,
  color: #252525;
  font-size:  14px;
  font-weight:  400;
  line-height:  160%;
  font-style: normal;
  //border-left:  1px solid #E0E0E0;
  background-color: white;

  .MuiMenu-root{
    z-index: 10;
    margin-top: -2.5;
    margin-bottom: 0;
    padding-bottom: 0;
    box-shadow: none;
    border-radius: 0;
    border-left: 1px solid #F0F1F7;
    border-right: 1px solid #F0F1F7;
    border-bottom: 1px solid #F0F1F7;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .MuiList-root{
    padding-bottom: 0;
    border-color: #F0F1F7;
  }

}

.scaa_menu_item{
  z-index: 10;
  color:#252525;
  font-size: 12px;
  font-weight: 400;
  line-height: 160%;
  font-style:normal;
  margin:0;
}
.register-wrapper {
  position: relative;
  background-color: #f4fbff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .register-content-wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: auto;

    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .logo {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;
        margin: 30px 0 10px 0;

        span {
          position: relative;
          font-weight: 800;
          font-size: 30px;
        }

        svg,
        span {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .copyright {
        position: fixed;
        width: 100%;
        bottom: 0;
        margin-bottom: 10px;
        color: #d9d9d9;
        text-align: center;
        z-index: 2;
      }

      .register-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .name-inputs {
          width: 90%;
          max-width: 500px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 10px;
          margin-bottom: 15px;

          .name-input-field {
            flex-grow: 1;
            //width: 40%;
            display: flex;
            flex-direction: column;
          }
          .surname-input-field {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding-top: 17px;
          }

          .input-label {
            font-size: 14px;
            margin-bottom: 8px;
            color: #01affd;
          }

          .text-input {
            padding: 10px;
            font-size: 16px;
            border: 1px solid #d9d9d980;
            border-radius: 12px;
            outline: none;
            &:focus {
              border-color: #01affd;
              box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
            }
            &::placeholder {
              color: #d9d9d9;
              // font-style: italic;
            }
          }
        }
        .recieve-emails {
          width: 90%;
          max-width: 500px;
        }
        .toc {
          width: 90%;
          max-width: 500px;
          margin: 20px 0;
        }
        .socials-login {
          width: 90%;
          max-width: 500px;
          margin: 0 0 20px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 15px;

          .facebook-login,
          .google-login {
            &:hover {
              cursor: pointer;
            }
          }
        }
        .signup-btn {
          width: 150px;
          margin-top: 20px;

          .MuiButtonBase-root {
            //padding: 29px 16px;
            font-size: 23px;
          }
        }
        .line-wrapper {
          width: 200px;
          display: flex;
          align-items: center;
          margin-top: 15px;
        }

        .line {
          flex-grow: 1;
          height: 2px;
          background-color: #d9d9d9;
        }

        .text {
          margin: 0 7px;
          font-size: 18px;
          color: #d9d9d9;
        }
      }
      .login-content {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .register-title {
      width: 90%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #0a226b;
      font-size: 42px;
      font-weight: 800;
      margin: 20px 0 40px 0;
      gap: 10px;
      text-align: center;

      &.invited-acc {
        font-size: 26px;
      }
    }

    .input-field {
      width: 90%;
      max-width: 500px;
      display: flex;
      flex-direction: column;

      .input-label {
        font-size: 14px;
        margin-bottom: 8px;
        color: #01affd;
      }

      .text-input {
        padding: 10px;
        font-size: 16px;
        border: 1px solid #d9d9d980;
        border-radius: 12px;
        outline: none;

        &:focus {
          border-color: #01affd;
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }
        &::placeholder {
          color: #d9d9d9;
          // font-style: italic;
        }
      }
    }

    .have-acc {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 15px;
      .have-acc-text {
        color: #01affd;
        font-weight: 600;
      }

      .MuiButtonBase-root {
        color: #01affd;
        background-color: white;
        font-weight: 600;
      }
    }
  }

  .top-left-shape {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
  }
  .bottom-right-shape {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
  }
  .mobile-title-shape {
    position: absolute;
    top: 120px;
    left: 0;
    z-index: 1;
    overflow: hidden;
    display: none;
  }

  .background-balls-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
    overflow: hidden;
    z-index: 1;

    .background-ball {
      position: absolute;
      background-color: #c0e0ee;
      opacity: 0.5;
      border-radius: 50%;
    }

    .small {
      width: 32px;
      height: 32px;
      animation: smallBallAnimation 8s ease-in-out infinite;
    }

    .big {
      width: 120px;
      height: 120px;
      animation: bigBallAnimation 8s ease-in-out infinite;
    }

    .big-left-top {
      left: -60px;
      top: 5%;
    }

    .small-left-top {
      top: 15%;
      left: 20%;
    }

    .small-center-mid {
      bottom: 20%;
      left: 50%;
    }

    .small-center-top {
      left: 50%;
      top: 30%;
    }

    .small-right-top {
      right: 8%;
      top: 20%;
    }

    .small-right-bottom {
      right: 1%;
      bottom: 20%;
    }

    .big-right-top {
      right: -40px;
      top: 5%;
    }

    .big-left-bottom {
      left: -40px;
      bottom: 5%;
    }

    .small-left-center {
      top: 50%;
      left: 1%;
    }

    .small-right-center {
      bottom: 40%;
      right: 3%;
    }

    .big-left-center {
      bottom: 40%;
      right: -40px;
    }

    @keyframes bigBallAnimation {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(70%);
      }
      100% {
        transform: translateY(0);
      }
    }

    @keyframes smallBallAnimation {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .register-wrapper {
    input {
      padding: 8px !important;
    }
  }
  .top-left-shape {
    width: 100% !important;
  }
  .bottom-right-shape {
    display: none;
  }
  .content {
    width: 70% !important;
  }
}

@media screen and (max-width: 600px) {
  .logo {
    width: 90% !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    gap: 15px;
    margin: 10px 0 10px 0;
  }
  .register-title {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    gap: 0 !important;
  }
  .mobile-title-shape {
    display: block !important;
  }
  .content {
    width: 100% !important;
  }
  .toc {
    text-align: justify;
  }
}
@media screen and (max-width: 450px) {
  .register-title {
    margin: 5px 0 15px 0 !important;
    font-size: 37px !important;
  }
  .action-btns-wrapper {
    padding-top: 40px !important;
  }
  .copyright {
    font-size: 12px;
  }
}

#container{
    display:flex;
    /*justify-content: space-between;*/
    align-items: center;
    gap:8px;
    font-family: Inter;
    padding-top: 16px;
    font-size: 16px;
    width:100%;
}
#OptionsInput{
    border-radius: 8px;
    border: 1px solid #F0F1F7;
    width:198px;
    color: #000000;
    height:34px;
}
#OptionsInput:focus{
    border: 1px solid #F0F1F7;
    outline: none;
}
#Title{
    color: #000000;
}
#EndContainer{
    display:flex;
    justify-content: space-between;
    align-items: center;
    /*gap:8px;*/
    font-family: Inter;
    font-size: 16px;
    padding-top: 16px;
    width:100%;
}
#add{
    display:flex;
    gap:11px;
    width:50%;
}

.MuiDataGrid-cell{
    border: 1px solid #F0F1F7;
    width: 140px;
    height: 40px !important;
}


.chart_datagrid_header {
    font-weight: bold;
    background-color: #FAFBFE
    ;
}

.sum{
    font-weight: bold;
    background-color: #FAFBFE
;
}

.test{
    display: inline;
    border-radius: 50%;
    overflow: hidden;
}
/*.MuiPaper*/

.column_pop_over > div:nth-child(3){
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

//
//
//.l_r_n_editable{
//    .MuiDataGrid-cell{
//        background: red;
//    }
//    .MuiDataGrid-cell--editable{
//        background: unset;
//    }
//}


.MuiDataGrid-virtualScroller{
    /*overflow-y: hidden;*/
    /*overflow-x: hidden;*/
    max-height: 300px;
}
/*.MuiDataGrid-virtualScroller:hover{*/
    /*overflow-y: overlay;*/
    /*overflow-x: overlay;*/

/*}*/

.chart_table{
    /*height:auto;*/
    max-height:300px;
}
.MuiDataGrid-cell{
    height:46px;
    width:140px;
}

.MuiTooltip-tooltip {
  background: white !important;
  color: black !important;
}

.se_invalid_link_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.preview_hidden_container {
  //position: absolute;
  //left: -2000px;
  visibility: hidden;
  width: 738px;
}


.preview_slide {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .se_scale_panel_top {
    height: 95%;
    width: 100%;
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    page-break-after: always;
    page-break-before: always;
    box-sizing: border-box;

    margin: 0 0 20px 0;
    padding: 0 0 10px 0;

    .se_panel_container {
      height: 100%;
      width: 100%;
      display: block;
      align-self: center;
      position: relative;
      justify-self: center;


      .se_scale_panel {
        display: block;
      }
    }

    .se_panel {
      width: 1280px;
      height: 720px;
      background-color: #fff;
      transform-origin: top left;
      position: relative;
      overflow: hidden;
    }


    .se_panel_placeholder {
      border: 1px solid transparent;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.preview_slide.bp {
  padding: 80px;
  width: auto !important;
  height: auto !important;

  .se_scale_panel_top {
    height: auto;
    width: 100%;
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    page-break-after: always;
    page-break-before: always;
    box-sizing: border-box;

    margin: 0 0 20px 0;
    padding: 0 0 10px 0;

    .se_panel_container {
      height: 100%;
      width: 100%;
      display: block;
      align-self: center;
      position: relative;
      justify-self: center;


      .se_scale_panel {
        display: block;
      }
    }

    .se_panel.bp {
      width: 816px;
      min-height: 1154px;
      height: auto;
      padding: 49px;
      padding-top: 140px;
      box-sizing: border-box;
    }

    .se_panel.padding-top {
      padding-top: 140px;
    }

    .se_panel.padding-bottom {
      padding-bottom: 80px;
    }


    .se_panel_placeholder {
      border: 1px solid transparent;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}
.company_limit_dialog {

  .MuiDialogTitle-root{
    font-weight: 700;

  }

  &.center{
    text-align: center;

    .cld_typography2_buttons {
      justify-content: center;
      align-items: center;
    }
  }
  .MuiPaper-root {
    width: 800px;
    padding: 20px;
    position: relative;
    font-family: Inter;
    color: #252525;

    .p1_strong {
      font-weight: 700;
      font-size: 20px;
    }

    .p3_strong {
      font-weight: 700;
      font-size: 14px;
    }

    .cld_heading {
      padding-top: 12px;
    }

    .cld_typography {
      width: 100%;
      font-size: 14px;

      .lcd_typography_link {
        font-size: 14px;
        font-weight: 700;
        color: #252525;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .cld_typography2_buttons {
      display: flex;
      padding-top: 10px;
      column-gap: 5px;
    }


    .cld_close_button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .scdlw_warning_actions{
    column-gap: 15px;
  }



  .video_container{
    aspect-ratio: 16/9 !important;
    iframe{
      width:100%;
      height:100%;
    }
  }
}
.full_screen_dialog{
  .MuiPaper-root{
    min-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    text-align: center;
    font-size: calc(.5em + 1vw) !important;
  }
}
.scdls_success_content{
  justify-items: center;
  text-align: center;
  justify-content: center;

  font-size: 18px;
  font-weight: 700;
  color: #66D151;
}
.icon_button:hover{
  .trash_can_2{
    stroke:red;
  }
}
.icon_button{
  width:25px;
  height:25px;

}
.icon_button.hidden{
  visibility: hidden;
}
.CustomInputBox{
    height:28px;
    width:70px;
    border: 1px solid #F0F1F7;
    border-radius: 4px;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.Input{
    width:100%;
    height:20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
#Arrows{
    height:100%;
    width:50%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#UpwardArrow{
    height:100%;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    padding-top:5px;
    color: #979797;
    &:active{
        color: black;
    }
}
#DownwardArrow{
     display:flex;
     align-items: center;
     justify-content: center;
    height:100%;
    width:100%;
    padding-bottom:5px;
    color: #979797;
    &:active{
        color: black;
    }
}
.Input{
    padding-left:6px;
    border: none;
    color: #585858;
}

.Input:focus{
    outline: none;
}

.input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding: 4px 0 4px 0;
  box-sizing: border-box;
  .input_container_actions{
    display: inline-flex;
  }

  &.no_padding{
    padding: 0;
  }

  .section_name_input {
    border: 1px solid #F0F1F7;
    border-radius: 5px;
    outline: none;
    height:100%;
    width: 185px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #252525;
  }
}
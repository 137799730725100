.sctfc_header{
  display: inline-flex;
  .sctfc_header_icon{
    margin-left: 2px;
    margin-right: -8px;
    align-self: center;

  }
  .mct_ft_tablerow_header_paragraph{
    align-self: center;
    margin-left: 4px !important;
  }
  .MuiTableCell-root{
    line-height: unset;
  }

  .MuiButtonBase-root{
    font-size: 12px;
    font-weight: 600;
    padding: 4px;
  }

  .sctfc_header_icon_splitter{
    align-self: center;
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px !important;
    color: #D9D9D9;

  }
  .sctfc_header_icon_splitter.sctfc_text_size-10{
    font-size: 10px;
  }
  .sctfc_header_icon.sctfc_text_size-10{
    .MuiButtonBase-root{
      font-size: 10px;
    }
  }
}
.exceeded_limit_content {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .btns_container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    button {
      width: 100%;
      text-transform: unset;
      font-family: Inter;
    }
  }
}

.MuiDialogTitle-root.downgrade_subscription_title {
  padding-left:0;

  .blue {
    color: #01AFFD;
  }

  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.downgrade_subscription {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .blue {
    color: #01AFFD;
  }

  .p1 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

    &.bold {
      font-weight: 700;
    }
  }

  .item {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }


  .flex_cont {
    display: flex;
    justify-content: space-between;

    div {
      text-align: left;
    }
  }
}

.MuiDialogActions-root.downgrade_subscription_actions {
  justify-content: space-between;
}

.upgrade_subscription {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.manage_subscription_btns_cont{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height:210px;
}

.u_s_image_cont {
  max-width: 100%;
  max-height: 100%;

  img {
    height: 100%;
    width: 100%;
  }
}

.upgrade_plan {

  .p1 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

    &.bold {
      font-weight: 700;
    }
  }

  .item_cont{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .item {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  .MuiPaper-root {
    width: 566px;
    min-height: 384px;
    border: 1px;

    background: white url("./rocket.png");
  }
}

.cancel_subscription{
  .MuiDialogContent-root{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .MuiPaper-root {
    position: static;
    min-width: 800px;
    overflow: visible;
  }

  .button_container{
    display: flex;
    justify-content: center;
    align-items: center;

    &.info_text{
      font-size: 13px;
      column-gap: 8px;
    }
  }

  .floating{
    position: absolute;
    top:0;
    left:0;
  }
}

.check_password{
 .MuiDialogTitle-root{
   font-weight: 700;
 }
  .MuiDialogContent-root{
    .text_cont{
      font-family: Inter;
      font-size: 14px;
    }
  }

  .MuiDialogActions-root {
    justify-content: space-around;
  }
}

.MuiDialogTitle-root{
  font-family: Inter !important;
}

.info_dialog_container{
  .MuiDialogTitle-root{
    color: #01AFFD;
    text-align: center;
    padding: 8px 0 16px;
  }
  .MuiDialogContent-root{
    text-align: center;

    .blue{
      color: #01AFFD;
    }
  }
  .MuiDialogActions-root{
    justify-content: center;
    padding-top: 20px;
  }
}
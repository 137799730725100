.intro_screen_container{
  overflow: auto;
  width: 100%;
  display: flex;
  justify-content: center;

  .i_s_c_content{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .i_s_c_c_desription{
      max-width: 60%;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      line-height: 160%;
      margin-top: 20px;
      color: #000000;

      &:nth-child(2){
        margin-top: 15px;
      }
    }

    .financial_blank{
      max-width: 100%;
    }
  }
}
.spsdcc_custom_dashboards_add_dialog_content{
  display: flex ;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 15px;

  .spsdcc_custom_dashboards_heading{
    margin-bottom: 10px;
  }

}

.spsdcc_custom_text_area{
  background: #F5F5F5;
  border: 1px solid #F0F1F7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px 15px 12px 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
}
.psfce_export_report{
  break-inside: avoid !important;
  div {
    font-size: 18px !important;
  }
  p {
    font-size: 18px !important;
  }
  td {
    border-style : hidden!important;
  }
  td {
    border-style : hidden!important;
  }

  table {
    border-style: hidden !important;
  }

  .MuiTableCell-root{
    border: none !important;
  }


  //.mct_ft_table .mct_ft_tablehead .mct_ft_tablehead_cell{
  //  border : none!important;
  //}
  .main_table_container.finance_table_main_wrapper_div{
    border: none !important;
  }

  .finance_table_main_wrapper_div{
    border: none !important;
  }

  td:first-child {
    max-width: 310px !important;
    width: 310px !important;
  }

  .mct_ft_tablerow_header_icons{
    display: none !important;;

  }
  .mct_ft_tablerow_header_arrow{
    display: none !important;
  }

  table, tr>th, tr>td, .MuiTableCell-root{
    border: 0.5px solid #E0E0E0 !important;
    border-spacing: 0;
  }
  .MuiTableCell-root.MuiTableCell-head{
    border: 0.5px solid #E0E0E0 !important;
    border-spacing: 0;
  }


  .mx_numinput{
    background: #fff !important;
  }


  .psfco_overview_table{

    .psfco_overview_table_header.psfco_overview_table_cell{
      border-top: 1.2px solid #E0E0E0  !important;
      font-size: 17px !important;
    }

    .mct_ft_tablerow_header_arrow{
      display: none !important;
    }
    .mct_ft_tablerow_header_icons{
      display: none !important;
    }
    .mct_ft_tablehead_paragraph{
      font-size: 17px !important;
    }
    .mx_numinput_input{
      font-size: 17px !important;
    }
    .mct_ft_tablerow_header_paragraph{
      font-size: 17px !important;
    }

    .mct_ft_table_row_header_bold-0{
      color: #252525;
      font-weight: 700 !important;
    }
    .mct_ft_tablerow_header{
      background: #ffffff !important;;
    }
    .mx_numinput{
      background: #ffffff !important;
    }

    .mct_ft_tablehead_cell {
      border-right: 1.2px solid #E0E0E0  !important;
      border-bottom: 1.2px solid #E0E0E0  !important;
      border-top: 1.2px solid transparent  !important;
      border-left: 1.2px solid transparent  !important;

    }

    .psfco_company_table_cell {
      border-right: 1.2px solid #E0E0E0  !important;
      border-bottom: 1.2px solid #E0E0E0  !important;
      border-top: 1.2px solid transparent  !important;
      border-left: 1.2px solid transparent  !important;
      min-width: 120px !important;
      max-width: 120px !important;
      width: 120px !important;
      .mx_numinput{
        width: 100%;
        min-width: max-content;
        border: none !important;
      }
    }
    .psfco_overview_table_cell{
      border-right: 1.2px solid #E0E0E0  !important;
      border-bottom: 1.2px solid #E0E0E0  !important;
      border-top: 1.2px solid transparent  !important;
      border-left: 1.2px solid transparent  !important;
      min-width: 120px !important;
      max-width: 120px !important;
      width: 120px !important;
      .mx_numinput{
        width: 100%;
        min-width: max-content;
        border: none !important;
      }
    }

    .psfco_company_table_cell  {
      border-left: 1.2px solid #E0E0E0  !important;
    }
    .psfco_overview_table_cell{
      border-left: 1.2px solid #E0E0E0  !important;
      min-width: 120px !important;
      max-width: 120px !important;;
      font-size: 17px !important;
      .MuiTableCell-root{
        font-size: 17px !important;
      }
    }

    .psfco_overview_table_header{
      font-size: 17px !important;
    }
    .MuiButton-endIcon{
      display: none !important;
    }


    .mct_ft_tablerow_header {
      border-right: 1.2px solid #E0E0E0  !important;
      border-left: 1.2px solid #E0E0E0  !important;
    }

    .MuiTableCell-root{
      border-bottom: 1.2px solid #E0E0E0  !important;
    }

    .mct_ft_tablebody_cell {

      border-right: 1.2px solid #E0E0E0 !important;
      border-bottom: 1.2px solid #E0E0E0 !important;
      border-top: 1.2px solid transparent !important;
      border-left: 1.2px solid transparent !important;

    }

    .max_height{
      width: 300px  !important;
    }

    .mct_ft_tablehead_cel{
      min-width: 120px !important;
    }
    .mct_ft_tablebody_cell{
      min-width: 120px !important;;
    }
    .MuiTableCell{
      max-height: 30px !important;
      height: 30px !important;
    }
    .MuiTableCell-body{
      max-height: 30px !important;
      height: 30px !important;
    }

  }

  .psfco_company_table{

    .psfco_overview_table_header.psfco_overview_table_cell{
      border-top: 1.2px solid #E0E0E0  !important;
      font-size: 17px !important;
    }

    .mct_ft_tablerow_header_arrow{
      display: none !important;
    }
    .mct_ft_tablerow_header_icons{
      display: none !important;
    }
    .mct_ft_tablehead_paragraph{
      font-size: 17px !important;
    }
    .mx_numinput_input{
      font-size: 17px !important;
    }
    .mct_ft_tablerow_header_paragraph{
      font-size: 17px !important;
    }

    .mct_ft_table_row_header_bold-0{
      color: #252525;
      font-weight: 700 !important;
    }
    .mct_ft_tablerow_header{
      background: #ffffff !important;;
    }
    .mx_numinput{
      background: #ffffff !important;
    }

    .mct_ft_tablehead_cell {
      border-right: 1.2px solid #E0E0E0  !important;
      border-bottom: 1.2px solid #E0E0E0  !important;
      border-top: 1.2px solid transparent  !important;
      border-left: 1.2px solid transparent  !important;

    }

    .psfco_company_table_cell {
      border-right: 1.2px solid #E0E0E0  !important;
      border-bottom: 1.2px solid #E0E0E0  !important;
      border-top: 1.2px solid transparent  !important;
      border-left: 1.2px solid transparent  !important;
      min-width: 120px !important;
      max-width: 120px !important;
      width: 120px !important;
      .mx_numinput{
        width: 100%;
        min-width: max-content;
        border: none !important;
      }
    }
    .psfco_overview_table_cell{
      border-right: 1.2px solid #E0E0E0  !important;
      border-bottom: 1.2px solid #E0E0E0  !important;
      border-top: 1.2px solid transparent  !important;
      border-left: 1.2px solid transparent  !important;
      min-width: 120px !important;
      max-width: 120px !important;
      width: 120px !important;
      .mx_numinput{
        width: 100%;
        min-width: max-content;
        border: none !important;
      }
    }

    .psfco_company_table_cell  {
      border-left: 1.2px solid #E0E0E0  !important;
    }
    .psfco_overview_table_cell{
      border-left: 1.2px solid #E0E0E0  !important;
      min-width: 120px !important;
      max-width: 120px !important;;
      font-size: 17px !important;
      .MuiTableCell-root{
        font-size: 17px !important;
      }
    }

    .psfco_overview_table_header{
      font-size: 17px !important;
    }
    .MuiButton-endIcon{
      display: none !important;
    }


    .mct_ft_tablerow_header {
      border-right: 1.2px solid #E0E0E0 !important;
      border-left: 1.2px solid #E0E0E0  !important;
    }

    .MuiTableCell-root{
      border-bottom: 1.2px solid #E0E0E0  !important;
    }

    .mct_ft_tablebody_cell {

      border-right: 1.2px solid #E0E0E0 !important;
      border-bottom: 1.2px solid #E0E0E0 !important;
      border-top: 1.2px solid transparent !important;
      border-left: 1.2px solid transparent !important;

    }

    .max_height{
      width: 300px  !important;
    }

    .mct_ft_tablehead_cel{
      max-height: 20px !important;
      height: 20px !important;
      min-width: 120px !important;
    }
    .mct_ft_tablebody_cell{
      max-height: 20px !important;
      height: 20px !important;
      min-width: 120px !important;
    }

    .MuiTableCell-root.input{
      max-height: 30px !important;
      height: 30px !important;
    }
    .MuiTableCell-body{
      max-height: 30px !important;
      height: 30px !important;
    }
  }


}





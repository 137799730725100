.reusable_menu {
  font-weight: 400;
  font-family: Inter;
  color: #979797;
  .szh-menu {
    z-index: 1000;
    .szh-menu__item {
      color: #979797;
    }

    .szh-menu__item--hover{
      background: #ebebeb;
      cursor: pointer;
    }
    .szh-menu__item.selected {
      color: black;
      font-weight: 600;

      .MuiButton-root {
        color: black;
        font-weight: 600;
      }
    }
  }

  .link {
    padding: 0;
    text-align: left !important;
  }

  .MuiButton-root {
    width: 100%;
    height: 100%;
    text-transform: unset;
    justify-content: left;
    color: #979797;

    padding: 3px 24px 3px 24px;
  }

  .add_new_scenario_button, .menu_item{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .add_new_scenario_button {
    color: #01AFFD;
  }
  .szh-menu__item--disabled {
    .add_new_scenario_button {
      color: #979797;
      fill: #979797;
    }
  }
}
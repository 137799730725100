.management_reports_main_container {
  width: 100%;
  height: calc(100vh - 129px);
  background-color: #FFFFFF;
  overflow: hidden;
  display: grid;
  grid-template-rows: 240px auto;
  grid-template-columns: 100%;

  .mr_main_content {
    width: 100%;
    padding: 0 0 0 20px;
    display: grid;
    grid-template-columns: 250px calc(100% - 250px);
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
    grid-template-rows: 100%;

    .mr_mc_left_menu_container {
      padding-right: 10px;

      .se_menu_container {
        height: 100%;

        .left_menu {
          height: 100%;
        }
      }

      .MuiBox-root {
        height: calc(100% - 63px);
        overflow: hidden;

        .MuiTabs-root {
          overflow: auto;
        }
      }
    }

    .mr_mc_parent_table_container {
      background-color: #F9F9F9;
      box-shadow: 0px 2px 4px 0px #0000001A;
      padding: 20px;

      .mr_mc_table_container {
        box-shadow: 0px 0px 10px 0px #0000001A;
        background-color: #FFFFFF;
        overflow: hidden;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px;
      }
    }
  }
}
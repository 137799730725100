
.mct_ft_table {
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  table-layout: fixed;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  .mct_ft_tablehead {

    .mct_ft_tablehead_cell.mct_ft_tablehead_cell_year {
      //background: #FAFBFE;
      .mct_ft_tablehead_paragraph{
        color: #00B3B0 !important;
      }

    }
    .mct_ft_tablehead_cell {
      line-height: unset;
      .mct_ft_table_title{
        display: grid;
        align-content: center;
        .mct_ft_table_title_units{
          font-style: italic;
          font-size: 12px;
          font-weight: 400;
        }
      }


      &:last-child {
        border-radius: 0 5px 0 0;
      }
      height: 40px;
      padding: 0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 1px solid #E0E0E0;
      border-top: 1px solid transparent;
      //border-left: 1px solid transparent;
      text-align: right;
    }

    .mct_ft_table_full{
      min-width: 100%;
      max-width: 100%;
    }


    .mct_ft_tablehead_div{

      display: flex;
      justify-content: space-between;
      :last-child{
        align-self: center;
      }
    }
    .mct_ft_tablehead_paragraph.mct_ft_disabled{
      color:#D4D5DE;
    }
    .mct_ft_tablehead_paragraph {
      margin: 0 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #0088F7;

    }

    .mct_ft_tablehead_paragraph_title{
      margin-left: 8px;
      padding-right: 6px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #000000;

    }
  }

  .mct_ft_tablebody {


    .mct_ft_table_header_sticky{
      font-weight: 700;
    }

    .mct_ft_tablerow_header {
      padding: 0;
      //padding-left: 15px;
      position: sticky;
      left: 0;
      z-index: 1;
      background: #FAFBFE;
      border-right: 1px solid #E0E0E0;
      font-family: Inter;
      .mct_ft_tablerow_header_div{
        .mct_ft_tablerow_header_arrow.hidden{
          visibility: hidden;
        }
        display: flex;
        align-items: center;
        .mct_ft_tablerow_paragraph{
          margin-left:8.5px;
        }
      }
    }

    .mct_ft_tablebody_cell_lastCell {
      height: 40px;
      margin: 0 8px 0 0;
      padding: 0;
      border-right: 1px solid #E0E0E0;
      border-left: 3px solid #E0E0E0;


      .mct_ft_tablebody_input {
        width: 100%;
        height: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        //line-height: 160%;
        border: none;
        text-align: right;
        color: #979797;

        margin-left: -3px;

        &:focus {
          outline: none;
          border: none;

        }

      }

    }
    .mct_ft_tablebody_cell.t{
      border-top: 1px solid #01AFFD !important;
      outline: none !important;
    }
    .mct_ft_tablebody_cell.b{
      border-bottom: 1px solid #01AFFD !important;
      outline: none !important;
    }
    .mct_ft_tablebody_cell.l{
      border-left: 1px solid #01AFFD !important;
      outline: none !important;
    }
    .mct_ft_tablebody_cell.r{
      border-right: 1px solid #01AFFD !important;
      outline: none !important;
    }
    .mct_ft_tablebody_cell.mct_ft_year {
      background: #FFF;
      .mct_ft_tablebody_cell_div{
        .mx_numinput{
          background-color: #FAFBFE;
        }
      }
    }
    .mct_ft_tablebody_cell {
      //min-width: 110px;
      //width:170px;
      //max-width: 170px;
      line-height: unset;
      background-color: #FFF;
      border-right: 1px solid #E0E0E0;
      border-bottom: 1px solid #E0E0E0;
      border-top: 1px solid transparent;
      //border-left: 1px solid transparent;
      border-left: none;
      //border: 1px solid white;



      outline: none !important;

      &.mct_ft_tablebody_cell_error{
        border: 1px solid #f3333340;
      }

      margin: 0 8px 0 0;
      padding: 0;
      height: 30px;
      //border-right: 1px solid #F0F1F7;

      .mct_ft_tablebody_cell_div:hover {
        .autofill_container {
          display: flex;
        }
      }

      .mct_ft_tablebody_cell_div {
        width: 100%;
        height: 100%;
        position: relative;

        .autofill_container {
          position: absolute;
          z-index: 10001;
          display: none;
          align-items: center;
          height:100%;

          .autofill_btn {
            padding: 5px;
            opacity: 50%;
            display: inline-flex;

            svg {
              height: 10px;
              width: 10px;
            }
          }
        }

        .mct_ft_tablebody_input.mct_ft_disabled{
          color: #D4D5DE;
        }
        .mct_ft_tablebody_input {
          background-color: unset;
          display: flex;
          align-items: center;
          justify-content: right;
          width: 90%;
          height: 100%;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          //line-height: 160%;
          border: none;
          text-align: right;
          padding: 0 0 0 5px;
          color: #979797;
          vertical-align: center;

          &:focus {
            outline: none;
            border: none;

          }
        }

        input.mct_ft_tablebody_input{
          position: absolute;
          top: 0;
          left: 0;
          background-color: transparent;
          color: transparent;

          &:focus{
            background-color: white;
            color: #979797;
          }
        }


      }

      &.mct_ft_textColor_success{
        .mx_numinput_input{
          color: #2ecc71;
        }
      }
      &.mct_ft_textColor_warning{
        .mx_numinput_input{
          color: #FF3344;
        }
      }


    }
    //.mct_ft_tablehead_paragraph{
    //  margin: 0 8px;
    //  font-style: normal;
    //  font-weight: 700;
    //  font-size: 12px;
    //  line-height: 160%;
    //  color: #000000;
    //}
  }
}
.mct_ft_table.sticky {
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  //width: 100%;


  .mct_ft_tablehead {

    .mct_ft_tablehead_cell.mct_ft_tablehead_cell_year {
      background: #FAFBFE;

    }
    .mct_ft_tablehead_cell {

      &:first-child {
        background: #FAFBFE;
        text-align: left !important;

        border-radius: 5px 0 0 0;
        position: sticky;
        left: 0;
        z-index: 11;
      }
    }
  }
}


.MuiTableCell-root{
  letter-spacing: unset !important;
}
.mct_ft_table_customizable_10{
  font-size: 10px !important;


}
.mct_ft_table_customizable_12{
  font-size: 12px !important;
}
.mct_ft_table_customizable_14{
  font-size: 14px !important;
}
.mct_ft_table_customizable_16{
  font-size: 16px !important;
}
.mct_ft_table_customizable_18{
  font-size: 18px !important;
}

.mct_ft_table_full_numbers{
    display: block !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    .mx_numinput{
      font: Inter;
      //line-height: 40px;
    }
    .mx_numinput_input{
      //padding: 0 !important;
      line-height: 31px;
    }

}


.mct_ft_table_full_numbers.mct_ft_table_full_numbers_editing{
  overflow: visible !important;
  .mx_numinput_input{
    z-index: 10000 !important;
    min-width: max-content;
    left: 0;
  }
}

.mct_ft_header_customizable_10.mct_ft_show_headers{
  .MuiTableCell-root:first-child {
    width: 220px !important;
    min-width: 220px !important;
    max-width: 22px !important;
  }
}
.mct_ft_header_customizable_12.mct_ft_show_headers{
  .MuiTableCell-root:first-child {
    width: 240px !important;
    min-width: 240px !important;
    max-width: 24px !important;
  }
}

.mct_ft_header_customizable_14.mct_ft_show_headers{
  .MuiTableCell-root:first-child {
    width: 320px !important;
    min-width: 320px !important;
    max-width: 32px !important;
  }
}
.mct_ft_header_customizable_16.mct_ft_show_headers{
  .MuiTableCell-root:first-child {
    width: 320px !important;
    min-width: 320px !important;
    max-width: 32px !important;
  }
}
.mct_ft_header_customizable_18.mct_ft_show_headers{
  .MuiTableCell-root:first-child {
    width: 320px !important;
    min-width: 320px !important;
    max-width: 32px !important;
  }
}


.info_dialog{
  .MuiPaper-root {
    width:303px;
    height: auto;

    //max-height:360px;
    //max-width:606px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .Paragraph_P4{
      margin-bottom: 10px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 160%;
      color: #889299;
      text-align: center;
    }
  }
}
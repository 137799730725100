.usr_dialog {
  .usr_error_container {
    width: 100%;
    display: flex;
    gap: 141px;

    .error {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: #FF3D3D;
      padding-left: 8px;
      padding-bottom: 6px;
    }

    .error.invisible {
      visibility: hidden;
    }
  }

  .divider.error{
    border-color: #EA0000 !important;
  }
  .check_box_error_container{
    width:100%;

    .check_box_error {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: #FF3D3D;
    }
    .check_box_error.invisible{
      visibility: hidden;
    }
  }
}


.psfce_export_div{
  display: flex
}

//@media print {
//  @page { size: 24in 16in !important; }
//}

.psfce_export_report{

  .psfco_overview_table{
    padding: 50px !important;
    font-size : 54px !important;
  }
  .psfco_overview_table_header .psfco_overview_table_cell{
    border-top: 1.2px solid #252525  !important;
    font-size: 17px !important;
  }

  .mct_ft_tablerow_header_arrow{
    display: none !important;
  }
  .mct_ft_tablerow_header_icons{
    display: none !important;
  }
  .mct_ft_tablehead_paragraph{
    font-size: 17px !important;
  }
  .mx_numinput_input{
    font-size: 17px !important;
  }
  .mct_ft_tablerow_header_paragraph{
    font-size: 17px !important;
  }

  .mct_ft_table_row_header_bold-0{
    color: #252525;
    font-weight: 700 !important;
  }
  .mct_ft_tablerow_header{
    background: #ffffff !important;;
  }
  .mx_numinput{
    background: #ffffff !important;
  }

  .mct_ft_tablehead_cell {
    border-right: 1.2px solid #252525  !important;
    border-bottom: 1.2px solid #252525  !important;
    border-top: 1.2px solid transparent  !important;
    border-left: 1.2px solid transparent  !important;

  }

  .psfco_company_table_cell {
    border-right: 1.2px solid #252525  !important;
    border-bottom: 1.2px solid #252525  !important;
    border-top: 1.2px solid transparent  !important;
    border-left: 1.2px solid transparent  !important;
    min-width: 120px !important;
    max-width: 120px !important;
    width: 120px !important;
    .mx_numinput{
      width: 100%;
      min-width: max-content;
      border: none !important;
    }
  }
  .psfco_overview_table_cell{
    border-right: 1.2px solid #252525  !important;
    border-bottom: 1.2px solid #252525  !important;
    border-top: 1.2px solid transparent  !important;
    border-left: 1.2px solid transparent  !important;
    min-width: 120px !important;
    max-width: 120px !important;
    width: 120px !important;
    .mx_numinput{
      width: 100%;
      min-width: max-content;
      border: none !important;
    }
  }

  .psfco_company_table_cell  {
    border-left: 1.2px solid #252525  !important;
  }
  .psfco_overview_table_cell{
    border-left: 1.2px solid #252525  !important;
    min-width: 120px !important;
    max-width: 120px !important;;
    font-size: 17px !important;
    .MuiTableCell-root{
      font-size: 17px !important;
    }
  }

  .psfco_overview_table_header{
    font-size: 17px !important;
  }
  .MuiButton-endIcon{
    display: none !important;
  }


  .mct_ft_tablerow_header {
    border-right: 1.2px solid #252525  !important;
    border-left: 1.2px solid #252525  !important;
  }

  .MuiTableCell-root{
    border-bottom: 1.2px solid #252525  !important;
  }

  .mct_ft_tablebody_cell {

    border-right: 1.2px solid #252525 !important;
    border-bottom: 1.2px solid #252525 !important;
    border-top: 1.2px solid transparent !important;
    border-left: 1.2px solid transparent !important;

  }

  .max_height{
    width: 300px  !important;
  }

  .mct_ft_tablehead_cel{
    min-width: 120px !important;
  }
  .mct_ft_tablebody_cell{
    min-width: 120px !important;;
  }

}
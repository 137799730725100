#specialCharactersRow{
    display:flex;
    flex-direction: row;
    border-right:1px solid #CCCCCC;
    margin-right: 0;
}
#specialCharGrid{
    width: 21px;
    height:21px;
    border-left:1px solid #CCCCCC;
    border-top:1px solid #CCCCCC;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;
}
#specialCharactersGrid{
    width:220px;
    border-bottom:1px solid #CCCCCC;
}
.header_menu {

  &.scroll{
    .szh-menu{
      max-height: 85vh;
      overflow: scroll;
    }
  }

  .szh-menu {

    width: 361px;

    .szh-menu__item--hover {
      //background: white;

    }
    .szh-menu__item {
      padding: 8px 16px 8px 17px;
      display: flex;
      align-items: center;
      box-sizing: border-box;

      svg {
        width: 24px;
        height: 24px;
      }

      &:hover {
        color: #252525;

        .item_name {
          color: #252525;
        }

      }
      .item_name {
        padding-left: 11px;
        width: 100%;
        text-align: left;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #252525;
      }

      .helper_text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;

        color: #252525;

      }
    }
  }
  .szh-menu {
    .szh-menu__submenu {
      .szh-menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        min-width: 0;
        width: 226px;

        .szh-menu__item {
          box-sizing: content-box;
          padding: 6.5px;
          width: 24px;
          display: block;

          &:hover {
            .item_name{
              color: #252525;
            }
            .icon_fill {
              fill: #01AFFD;
            }
            .icon_stroke {
              stroke: #01AFFD;
            }
          }

        }
      }
    }
  }
}

.header_menu.arrow {
  .szh-menu {

    .szh-menu__item {

      .helper_text {
        padding-right: 15px;
      }
    }

  }
}

.header_menu.shapes {
  .szh-menu__submenu {
    .szh-menu {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      min-width: 0;
      width: 430px;



      .szh-menu__item {
        box-sizing: content-box;
        padding: 5.9px;
        width: 24px;
        display: block;

      }
      .separator_container {
        width: 430px;
        .horizontal_separator {
          width: 200px;
          height: 0;
          border: 1px solid #F0F1F7;
          margin: 13px 0 13px 0;
        }
      }
    }
  }
  .szh-menu__submenu.callouts {
    .szh-menu {
      width: 144px;
    }
  }
}

.border_weight {
  .szh-menu--state-open{
    display:block !important;
  }
  .szh-menu {
    min-width: 0;
    width: 100px;
    .szh-menu__item {
      padding: 0;
      width: 100%;
      justify-content: center;
      color: #252525;
      &:hover {
        color: #252525;
      }
    }
  }
}

.border_dash {
  .szh-menu--state-open{
    display:block !important;
  }
  .szh-menu {
    min-width: 0;
    width: 100px;
    .szh-menu__item {
      color: #252525;
      padding: 0;
      justify-content: center;

    }
  }
}

.border_fill{
  .szh-menu--state-open{
    display:flex !important;
    flex-wrap: wrap;
  }
  .szh-menu {
    min-width: 0;
    width: 120px;
    .szh-menu__item {
      margin-top: 5px;
      margin-right: 5px;
      color: #252525;
      padding: 0;
      justify-content: center;

    }
  }
}

.font_family {

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #D4D5DE;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #80D7FE;
  }
  .szh-menu--state-open{
    display:block !important;
  }
  .szh-menu {
    min-width: 0;
    width: 285px;
    height: 700px;
    overflow-y: overlay;

    .szh-menu__item {
      padding: 10px 0 10px 20px;

      .tick.hidden {
        visibility: hidden;
      }

      .font_name {
        padding-left: 14px;
      }
    }

    .horizontal_separator {
      width: 200px;
      height: 0px;
      border: 1px solid #F0F1F7;

      margin: 10px 0 10px 0;
    }
    .heading_container {
      width: 100%;
      margin-top: 10px;
      text-align: left;
      .heading {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #585858;
        padding: 20px 0 10px 20px;
      }
    }
  }

}
.color_menu{
  .szh-menu {
    padding: 0 !important;
  }

  &.block{
    .szh-menu {
      display:block;
    }
  }

  &.filter_component{
    z-index: 1000;
    .menu_item_filter{
      display: flex;
      justify-content: space-between;
      column-gap: 20px;

      &.div{
        padding: 10px 20px;
      }
    }
  }
}
.fdd_calculated_driver_object {
  //width: 100%;
  column-gap: 15px;
  padding-right: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .fdd_cd_object_data_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;


    //width: 707px;
    .fdd_cd_driver_autocomplete{
      width: 100%;
      height: 100%;
      .fdd_cd_driver_autocomplete_input_style{
        width: 200px
      }
    }

    .fdd_cd_object_data_name {
      min-width: 83px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px !important;
      white-space: nowrap;
    }
    .fdd_cd_object_data_name.data_type {
      min-width: 68px;

    }
    .fdd_cd_object_data_name.category {
      min-width: 10px;


    }
    svg {
      min-width: 13px;
      min-height: 13px;
    }

    .fdd_cd_checkbox_div {
      display: flex;
      white-space: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .MuiFormControlLabel-root {
        margin: 0 0 0 4px;

        .MuiCheckbox-root {
          margin-left: 2px;
          margin-right: -1px;
        }
      }
    }

    .fdd_cd_select_div{
      margin: 0 10px 0 10px;
    }


  }
  .fdd_cd_object_data_container:first-child{
    //min-width: 392px;
  }

}
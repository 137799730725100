@media (min-width: 1896px) {
  .m_btn {
    display: none !important;
  }
}

@media (max-width: 1895px) {
  #hd_mid_container {
    .xxl {
      display: none !important;
    }
  }
}

@media (max-width: 1853px) {
  #hd_mid_container {
    .xl {
      display: none !important;
    }
  }
}

@media (max-width: 1805px) {
  #hd_mid_container {
    .l {
      display: none !important;
    }
  }
}

@media (max-width: 1715px) {
  #hd_mid_container {
    .m {
      display: none !important;
    }
  }
}

@media (max-width: 1590px) {
  #hd_mid_container {
    .s {
      display: none !important;
    }
  }
}

@media (max-width: 1465px) {
  #hd_mid_container {
    .xs {
      display: none !important;
    }
  }
}

@media (max-width: 1342px) {
  #hd_mid_container {
    .xxs {
      display: none !important;
    }
  }
}

@media (max-width: 1202px) {
  #hd_mid_container {
    .xxxs {
      display: none !important;
    }
  }
}

.footer_input_container {
  width: max-content;
  padding: 7px;
  display: flex;
  column-gap: 7px;
}

#se_panel_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: white;
  z-index: 900;

  .insert_btn {
    font-family: Inter;
    font-size: 15px;
  }

  //button {
  //  margin-left: 10px;
  //  background: transparent;
  //  border: none;
  //
  //  &:hover {
  //
  //    color: #D4D5DE;
  //
  //    .undoIcon {
  //      stroke: #D4D5DE;
  //    }
  //
  //    .undoIconFill {
  //      fill: #D4D5DE;
  //    }
  //  }
  //  &:disabled {
  //    color: #D4D5DE;
  //
  //    .undoIcon {
  //      stroke: #D4D5DE;
  //    }
  //
  //    .undoIconFill {
  //      fill: #D4D5DE;
  //    }
  //  }
  //
  //}

  #format_painter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .font_family_btn {
    display: flex;
    width: fit-content;
    min-width: 150px;

    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }

  .font_family_btn.active {
    color: #01affd;
  }

  .sub_header_vertical_separator {
    height: 24px;
    width: 0;
    margin-left: 10px;
    border: 1px solid #d4d5de;
  }

  .separator {
    margin-left: 10px;
    width: auto;
  }
}

.header_default_main_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px 0 0;

  .object_insert_menu {
    .szh-menu:nth-child(1) {
      width: 84px;
      min-width: unset !important;
    }

    .szh-menu__item {
      .o_i_btn {
        padding: 0;
        margin: 0 !important;
      }

      .o_i_btn:hover {
        color: unset;
      }
    }

    .szh-menu__item--hover {
      .o_i_btn.table {
        path {
          stroke: #01affd !important;
        }
      }
    }

    .szh-menu__submenu>.szh-menu__item--hover::after {
      color: #01affd;
    }
  }

  #hd_left_container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  #hd_mid_container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  #hd_right_container {
    width: 280px;
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    align-items: center;

    .hd_rc_link_button {
      text-decoration: none;
    }

    .hd_rc_share_container {
      width: 25%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 250%;
      color: #01affd;
      display: flex;
      justify-content: space-between;
    }

    .hd_rc_share_menu {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;

      .hd_rc_sm_menu_item {
        color: #000;
      }

      .hd_rc_sm_menu_item:hover {
        color: #000000;
      }
    }

    .scenario_menu_button {
      white-space: nowrap;
      text-overflow: ellipsis;
      border-radius: 20px;
      height: 32px;

      text-transform: none;
      color: #252525;
      display: block;
      justify-content: space-between;
      width: 75px;
      overflow: hidden;
      box-sizing: border-box;
      margin: 0 !important;
      padding-left: 10px;

      svg {
        padding-left: 10px;
      }
    }
  }

  .smaller_header_menu {
    &>ul {
      width: 226px !important;
    }

    .nested_sub_menu {
      width: 100% !important;

      .szh-menu__item {
        display: flex !important;
        align-items: center;
        gap: 5px;
      }

      &>div {
        display: inline-block !important;
      }

      .nested_item_name {
        display: inline-block !important;
        box-sizing: border-box;
      }

      .szh-menu__item {
        width: 100% !important;
        box-sizing: border-box !important;
        text-align: left !important;

        svg {
          width: 20px !important;
          height: 20px !important;
          padding: 0 !important;
        }
      }
    }
  }
}

.header_default_main_container {
  .object_insert_menu.admin {
    .szh-menu:nth-child(1) {
      width: unset;
    }
  }
}
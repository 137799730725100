.szh-menu-container.context_menu {
  .szh-menu {
    min-width: 290px;
    width: 372px;

    .szh-menu__item--hover{
      //background: white;
      color: #252525;

    }
    .szh-menu__item {
      padding: 8px 50px 8px 50px;
      color: #252525;

      .cm_btn_name {
        text-align: left;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #252525;
      }

    &:hover{
      //background: white;

      .cm_btn_name {
        color: #252525;
      }
      color: #252525;

    }

  }
  .szh-menu__item.cm_icon_helper{
    display:flex;
    padding-left:15px;

      .cm_icon {
        min-width: 25px;
      }

      .cm_btn_name {
        width: 100%;
        padding: 0 0 0 10px;
      }

      .cm_helper_text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #252525;
      }
    }

    .szh-menu__submenu.cm_sub_align {
      .szh-menu {
        min-width: 150px;
        width: 251px;
      }
    }
    .szh-menu__item.szh-menu__item--submenu {
      padding-left: 50px;
      text-align: left;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #252525;

    }

    .szh-menu__item--hover.szh-menu__item--submenu{
      color: #252525;
    }
    .szh-menu__submenu {
      .szh-menu__item {
        padding-right: 16px;

      }
      .szh-menu__item--hover{
        color:#252525;

        .cm_sub_name{
          color:#252525;

        }
      }
    }
    .szh-menu__submenu.cm_sub_order {
      //width: px;

      .szh-menu {
        width: 361px;

        .cm_menu_item {
          display: flex;
          justify-content: space-between;
          padding-right: 16px;

          .cm_sub_name {
            text-align: left;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #252525;
          }



          .cm_helper_text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #252525;
          }
        }
        .szh-menu__item--hover.cm_menu_item{
          .cm_sub_name{
            color:#252525;
          }
        }
      }

    }
    .szh-menu__submenu.cm_sub_small {
      .szh-menu {
        width: max-content;
        min-width: 251px;

        .szh-menu__item {
          padding: 8px 16px 8px 50px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #252525;
        }
        .szh-menu__item--hover{
          color:#252525;
        }

      }
    }


    .cm_divider {
      width: 200px;
      height: 0px;
      border: 1px solid #F0F1F7;
      margin: 5px 0 5px 0;
    }
  }
}
.szh-menu-container.border_menu {
  .szh-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    width: 96px;
    padding:0;

    .szh-menu__item{
      padding:6px;
    }
  }
}
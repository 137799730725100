#avatar_menu {
  .menu_paper {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width:237px;

    .MuiList-root {
      background-color: white;

      .MuiMenuItem-root {
        font-family: "Inter";
        font-size: 14px;
        line-height: 18px;
      }

      .split_line {
        padding-top: 6px;
        margin-bottom: 6px;
        width: 100%;
        border-bottom: 1px solid #F0F1F7;
      }

      .user_item {
        font-weight: 700;
      }

      .switch_account{
        color: #01AFFD;
        display: flex;
        align-items: center;
        gap: 15px;

        .icon_cont {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .logout_item {
        color: red;
      }

      .upgrade_item {
        height: 30px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        padding-left: 15px;
        padding-right: 10px;
      }

    }
  }


  .menu_account{
    width: 320px;
    .MuiList-root{
      width: 320px;
    }
  }

  .fully_subscribed{
    width: 250px;
    .MuiList-root{
      width: 250px;
    }
  }
}
.avatar_icon_btn{
  height: 53px;
  width: 53px;
  position:relative;


  .img_cont_a{
    border-radius: 50%;
    overflow: hidden;

    display: flex;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    z-index: 1;
    img[src=""]{
      display: none;
    }
    background: white;
    img{
      max-width: 37px;
      max-height: 37px;
    }
  }

  .svg_cont{
    z-index: 0;
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
  }
}
.psfco_main_container{
  //margin: 0 16px 0 16px;
  display: flex;
  column-gap:100px;

  .psfco_opening_company{
    display: inline-block;
    flex-direction: column;
    grid-row-gap: 16px;
    width: 100%;

    .psfco_company_table {
      border: 1px solid #F0F1F7 !important;
      border-radius: 5px !important;

      .psfco_company_table_header{
        height: 40px !important;
        padding: 0 !important;
        border-right: 1px solid #E0E0E0 !important;
        border-bottom: 1px solid #E0E0E0 !important;

        .psfco_company_table_cell{
          font-family: Inter;
          min-width: 104px;
          max-width: 104px;
          width: 104px;
          padding: 0 5px  !important;
          //padding: 0 20px  !important;
          background: white;
          border-right: 1px solid #E0E0E0 !important;
          border-bottom: 1px solid #E0E0E0 !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;

          .mx_numinput.mct_ft_dialog_input{
            width: 100%;
            min-width: max-content;
            border: none !important;
          }



          &.bold {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 160%;
          }
        }
        .psfco_company_table_icon {
          width: 10px;
          margin-right: 10px;
          &.active {
            transform: rotate(180deg);
          }

          &.hide {
            color: transparent;
          }
        }



      }
    }

  }
}

.psfco_company_table_header.psfco_company_main_header {
  th:first-child{
    min-width: 200px !important;
  }
}



.psfco_opening_company_description{
  li:not(:last-child) {
    margin-bottom: 15px;
  }
}



.mx_big_numinput {
  position: relative;
  overflow: hidden;
  text-align: center !important;
  background: #FFFFFF;
  border: 1px solid #D4D5DE;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px 10px 0 9px;
  //width: 196px;
  width: 190px;
  max-height: 96px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 140%;
  .mx_big_numinput_input {
    background-color: unset;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 54px;
    line-height: 140%;
    border: none;
    text-align: center;
    padding: 0 0 0 0;
    color: #979797;
    vertical-align: center;
    justify-content: center;


    &:focus {
      outline: none;
      border: none;

    }
  }

  .mx_big_numinput_input.mx_big_editable{
    color: #252525;
    font-weight: 500;
  }

  .mx_big_numinput_input.mx_bigdisabled{
    color: #D4D5DE !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  input.mx_big_numinput_input{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    padding-right: 6px;
    font-weight: 700;
    font-size: 64px;

    background-color: transparent;
    color: transparent;
    justify-content: center !important;

    &:focus{
      background-color: white;
      color: #252525;
    }
  }






}


.psfco_company_table_div{
  width: 550px;
}

.psfco_company_table_cell{
  height: 40px;
  overflow-x: hidden;
  jusitfy-content: center;
  :first-child{
    border: none;
  }
}
.switch_account_dialog {
  .MuiPaper-root {
    width: 368px;
    height: 489px;

    .accounts_container {
      width:100%;
      height:100%;
    }
    .new_acc_btn{
      width:70%;
      display: flex;
      justify-content: space-between;
      text-transform: none;
      border-radius: 8px;
      color: #01AFFD;
      font-weight: 600;
      size: 16px;
      padding: 6px 17px 6px 17px;
      align-items: center;
    }

    .accounts_container.loader_container{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .accounts_container.choose_account{
      box-sizing: border-box;
      padding:17px 9px 18px 9px;
      overflow-y: scroll;



      .account_card{
        width:100%;
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        text-transform: none;

        .avatar_icon_badge{
          display: flex;
          .icon_badge_avatar{
            margin-left: -10px
          }
          .img {
            width: 37px;
            height: 37px;
            border-radius: 50%;
          }
        }

        .mail_container{
          display: flex;
          align-items: center;
          align-content: center;
          justify-items: center;
          column-gap: 15px;
        }
        .account_active{
          color: #1E1E1E;
          font-weight: 600;
        }
        .account{
          color: #1E1E1E;
          font-weight: 500;
        }
      }
    }

    .Paragraph{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      color: #252525;
      line-height: 160%;
    }
    .P1_Strong{
      font-size: 20px;
    }

    .P3_Strong{
      font-size: 14px;
    }


  }
}

.choose_account_title{
  padding: 10px 0 0 10px;
}
.no_company_access {
  background: #F3FAFF;
  width: 100%;
  height: 100%;
  font-family: Inter;
  display: flex;
  justify-content: center;
  align-items: center;

  .heading_text {
    font-size: 48px;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: 0em;
    text-align: center;
  }

  .main_text {
    font-size: 21px;
    line-height: 36px;
    text-align: center;
    font-weight: 800;

    .grey_text {
      max-width: 480px;
      color: #686868;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;

    &.column {
      flex-direction: column;
    }

    &.small_gap {
      row-gap: 42px;
    }

    &.big_gap {
      row-gap: 72px;
    }
  }

  .btn_container {
    column-gap: 28px;
  }
}
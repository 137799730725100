.dashboard_container{
  overflow: hidden;
  //height:100%;

  display: grid;
  //grid-template-rows: 60px auto ;
  grid-auto-columns: 100%;
  //display: grid;
  grid-template-columns: 100%;
  //grid-template-rows: 76px auto 88px;
  grid-template-rows: 76px auto 10px;


  &.apply_responsive{
    overflow-y: auto;

    .main_table_container{
      padding: 15px 16px 0 16px;
      max-height: 100%;
      //overflow-y: hidden;

      .psf_reports_export_pdf{
        height: 100%;
        overflow-y: hidden;
        max-height: 100%;
      }

      .finance_table_main_wrapper_div{
        margin: 0;
        max-height: -moz-calc(100% - 16px);
        /* WebKit */
        max-height: -webkit-calc(100% - 16px);
        /* Opera */
        max-height: -o-calc(100% - 16px);
        /* Standard */
        max-height: calc(100% - 16px);
        //height: 95%;
        overflow-y: auto;

        margin-bottom: 16px;
        &.finance_table_main_wrapper_div_kpi {
          max-height: -moz-calc(100% - 27px);
          max-height: -webkit-calc(100% - 27px);
          max-height: -o-calc(100% - 27px);
          max-height: calc(100% - 27px);
        }
      }
    }
  }


  &.shortened_menu{
    grid-template-rows: 36px auto ;
  }

  &.full_height{
    grid-template-rows: auto;
  }

  .charts_grid{
    overflow-y: auto;

    .react-grid-item{
      overflow: hidden;
      max-height: 100%;
      background-color: white;
      border-radius: 8px;

      .pfr_main_revenue_content{
        overflow: hidden;
        max-height: 100%;
      }

      .table_container{
        max-height: 490px;//calc(100% - 26px);
        width: 100%;
        overflow: hidden;
        padding: 0 12px 12px;
        .finance_table_main_wrapper_div{
          margin:0!important;
        }
      }

      .dashboard_container{
        display: block;
        max-width: 100%;
        max-height: 100%;
        overflow: auto;

        .MuiTable-root{
          min-width:100% !important;
        }
      }
    }
  }


  .bottom_align{
    padding-top:10px;
    .MuiButton-root{
      padding-bottom:0;
    }
  }
  font-family: Inter;
  .d_row{
    width:100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 25px;

    .psfac_actuals_single_filter{
      //min-width: 200px;
      //width: 200px;

    }

    @media (max-width: 1280px){
      .psfac_actuals_single_filter{
        //min-width: 150px;
        //width: 150px;
      }
    }

    .MuiButton-root{
      text-transform: none;
      color: #252525;
      font-weight: 700;
      font-size: 16px;
      height:33px;
      background-color: #fff;
      border-radius: 8px;

      svg{
        padding-left:14px;
      }
    }
    @media (max-width: 1115px){
      .MuiButtonBase-root.psdf_navigation_menu_tab{
        min-width: 50px;
      }
    }
  }

}

.psfac_actuals_single_filter.apply_hidden{
  @media (max-width: 1362px) {
    display: none !important;
  }
}
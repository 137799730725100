.mpsccc_menu_item{
  display: inline-flex;
  justify-content: space-between;
  padding: 10px;
  width: -webkit-fill-available;
  height: 15px;
  .mpscc_menu_item_name{
    font-size: 14px;
    font-weight: 500;
  }

  .mpscc_menu_item_icon{
    padding: 0;
    :hover{
      path{
        stroke:red;
      }
    }
    .mpscc_menu_item_trash_icon{
      width: 15px;
      height: 15px;
    }
  }

  .mpscc_menu_icons_div{
    display: flex;
    column-gap: 10px;
  }
}

.mpsccc_menu_item_add_new{
  display: inline-flex;
  justify-content: space-between;
  padding: 10px;
  width: -webkit-fill-available;
  font-size: 13px;
  font-weight: 600;
  color: rgb(1, 175, 253);
  .mpscc_menu_item_icon{
    padding: 0;
  }

  &.small{
    font-size: 12px;
  }
}

.spsccc_indicator_main_dialog{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spsccc_indicator_name_children{
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;

  .fdd_cd_object_data_name {
    font-size: 16px;
  }

  .MuiTypography-root{
    :first-child{
      font-size: 16px !important;
    }
  }
}
.section_name_input {
  border: 1px solid #F0F1F7;
  border-radius: 5px;
  outline: none;
  margin-top: -3px;
  width: 155px;
  height: 100%;
  margin-right: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #252525;
}
//.szh-menu{
//  padding: 0
//}



.psoc_custom_date_year_datepicker {
  background: white;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.psoc_onboarding_main {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.psoc_onboarding_contained {

  //background: blue;
  display: flex;
  margin-top: 5%;
  justify-content: center;
  flex-direction: column;
  //margin-top: 10px;
  align-items: center;
}

.sppaco_group_steps {
  align-items: center;
  &.dispay_none {
    display: none;
  }
}
@media (min-width: 750.98px) {

  .spsocwn2_checkbox_div{
    display: inline-grid;
    grid-row-gap: 10px;
  }
}

@media (min-width: 0px) and (max-width: 750.98px) {
  .p.title{
    font-size: 18px;
  }

  .spsocwn2_title{
    font-size: 14px !important;
  }

  //.MuiInputBase-root {
  //  min-width: 170px !important;
  //}

  .spsocwn2_checkbox_div{
    display: grid !important;
    align-content: center;
    column-gap: 10px
  }



}

@media (min-width: 0px) and (max-width: 768px) {
  #MainContainer{
    .psoc_onboarding_contained {
      margin-top: 1%;
    }
  }
  .psoc_logo_wrapper {
    width: 60px;
    height: 60px;
  }
  .sppaco_title {
    font-size: 14px !important;
  }
  .sppaco_sub_title {
    font-size: 10px !important;
  }
  .sppaco_group_steps {
    //column-gap: 10px !important;
  }
  .sppaco_icon_number {
    box-sizing: border-box;
    width: 25px !important;
    height: 25px !important;
  }

}

@media (min-width: 769px) and (max-width: 1024px) {
  #MainContainer{
    .psoc_onboarding_contained {
      margin-top: 1%;
    }
  }
  .psoc_logo_wrapper {
    width: 70px;
    height: 70px;
  }
  .sppaco_title {
    font-size: 16px !important;
  }
  .sppaco_sub_title {
    font-size: 12px !important;
  }
  .sppaco_group_steps {
    //column-gap: 20px !important;
  }
  .sppaco_icon_number {
    box-sizing: border-box;
    width: 30px !important;
    height: 30px !important;
  }

}

@media (min-width: 1025px) and (max-width: 1200px) {
  #MainContainer{
    .psoc_onboarding_contained {
      margin-top: 1%;
    }
  }
  .psoc_logo_wrapper {
    width: 80px;
    height: 80px;
  }
  .sppaco_title {
    font-size: 18px !important;
  }
  .sppaco_sub_title {
    font-size: 14px !important;
  }

  .sppaco_group_steps {
    //column-gap: 30px !important;
  }
  .sppaco_icon_number {
    box-sizing: border-box;
    width: 35px !important;
    height: 35px !important;
  }

}

@media (min-width: 1201px) {
  #MainContainer{
    .psoc_onboarding_contained {
      margin-top: 2%;
    }
  }

  .psoc_logo_wrapper {
    width: 100px;
    height: 100px;
  }
  .sppaco_title {
    font-size: 22px !important;
  }
  .sppaco_sub_title {
    font-size: 18px !important;
  }
  .sppaco_icon_number {
    box-sizing: border-box;
    width: 35px !important;
    height: 35px !important;
  }

}
//display: flex;
//justify-content: center;

.sppaco_wizzard_wapper {
  margin-bottom: 30px;
}


.psoc_body_wrapper {
  justify-content: center;
  display: block;
  max-width: 700px;
  width: -webkit-fill-available;

  .o_content_wrapper{
    height:310px;
    overflow-y: auto;

    .collaborators_container{
      overflow: auto;
      height: 112px;
    }
  }

  .o_btn_container{
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &.start{
      justify-content: flex-start;
    }
    .smaller_container{
      width:148px;
    }
  }
}

.spsocwn2_container{
  height:auto;
  overflow-y: auto;

}

.spsocwn2_container_plan{
  height:auto;
  overflow-y: auto;
  @media (max-width: 750.98px) {
    height:auto;
    overflow-y: inherit;
    .p.title{
      font-size: 18px;
    }
    .spsocwn2_title{
      font-size: 14px !important;
    }
  }
}

.psoc_buttom_button {
  height:36px;
  display: flex;
  justify-content: center;
}

.psoc_actions_buttons {
  //display: block;
  //height: 100%;
  //width: 100%;
}

@media ( max-width : 1540px) {
  .spso_boarding_cards_sub_container{
    padding: 0 150px !important;
  }
  .spso_boarding_header{
    padding: 6px 150px !important;
  }
}

@media ( max-width : 1395px) {
  .spso_boarding_cards_sub_container{
    padding: 0 100px !important;
  }
  .spso_boarding_header{
    padding: 6px 100px !important;
  }
}

@media ( max-width : 930px) {
  .spso_boarding_cards_sub_container{
    padding: 0 50px !important;
  }
  .spso_boarding_header{
    padding: 6px 50px !important;
  }
}

@media ( max-width : 435px) {
  .spso_boarding_container_main {

    .spso_boarding_cards_container {
      max-height: calc(100% - 100px) !important;
    }

    .spso_boarding_header{
      padding-top: 6px !important;
    }
    .spso_first_title {
      padding-left: 14px;
      text-align: left;
    }
    .spso_second_title {
      padding-left: 14px;
      text-align: left;
    }
  }
  .spso_boarding_cards_sub_container {
    padding: 0 10px !important;
  }
  .spso_boarding_header {
    padding: 6px 10px !important;
  }
}
.spso_boarding_container_main{
  height: 100%;
  //background: red;
  margin: 0;
  padding: 0;
  //background: lightgreen;
  overflow: hidden;

  .spso_boarding_header_container{
    display: grid;

  }

  display: flex;
  flex-direction: column;
  //row-gap: 70px;

  @media ( max-width : 600px) {
    margin-left: 0;
    margin-right: 0;
  }




  @media ( max-width : 375px) {
    .spso_boarding_title {
      .spso_first_title {
        font-size: 25px !important;
      }
      .spso_second_title {
        font-size: 15px !important;
      }
    }

  }

  .spso_boarding_header{
    //padding-top: 5px;
    //height: 100px;
    //background: lightblue;
    justify-self: center;
    max-width: 2145px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 6px 330px 0;

    .spso_boarding_logo{

      svg{
        width:70px;
      }
      align-self: center;
      display: grid;
      font-family: Inter;
      font-size: 15px;
      font-weight: 700;
      justify-items: center;
      @media ( max-width : 1080px) {
        font-size: 12px;
      }
    }

    .spso_boarding_title{
      align-items: center;
      display: inline-grid;
      text-align: center;
      .spso_first_title{
        font-family: Inter;
        color: #01AFFD;
        font-weight: 700;
        font-size: 24px;
        //@media ( max-width : 1200px) {
        //  font-size: 37px;
        //}
        //@media ( max-width : 1080px) {
        //  font-size: 34px;
        //}
        //@media ( max-width : 1000px) {
        //  font-size: 30px;
        //}
      }
      .spso_second_title{
        font-family: Inter;
        font-weight: 700;
        font-size: 20px;
      }

    }
    .spso_boarding_empty_div{
      width: 70px;

      @media ( max-width : 1080px) {
        width: 50px;
      }
      @media ( max-width : 600px) {
        width: 0;
      }
    }
  }



  .spso_boarding_cards_container{
    max-height: calc(100% - 250px); //121px top
    overflow: auto;
    display: grid;

    .spso_boarding_cards_sub_container{
      justify-self: center;
      display: flex;
      flex-direction: column;
      grid-row-gap: 10px;
      max-width: 1920px;
      padding: 0 330px;
    }

    .spsocb_boarding_card{
      min-height: 80px;
      width: inherit;
      background: #FFFFFF;
      //border: 1px solid #A9A9A9;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      column-gap: 30px;

      &.display_block{
        display: block;
        padding-left: 0;
        padding-right: 0;
      }


      .spsocb_boarding_part_one{
        display: flex;
        column-gap: 20px;

        .spsocb_boarding_card_text_div{
          align-items: center;
          display: inline-grid;
          text-align: start;
          .spsocb_boarding_card_first_text{
            font-family: Inter;
            color: #1E1E1E;
            font-weight: 700;
            font-size: 18px;
          }
          .spsocb_boarding_card_second_text{
            font-family: Inter;
            font-weight: 400;
            font-size: 14px;
            color: #1E1E1E;
          }
        }

      }

      .spsocb_boarding_card_input_div{
        min-width: 227px;
        width: 227px;
        &.align_right{
          text-align: end;
        }
      }


      .spsocb_boarding_collaborators_div{
        width: 240px;
        display: flex;
        flex-direction: column;
      }

      .spsocb_collaborators_inputs_div{
        display: flex;
        column-gap: 5px;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
        justify-content: space-between;
        align-content: center;
        .spsocb_collaborators_radio_div{
          display: flex;
          column-gap: 10px;
        }
      }
      .collaborators_div{
        margin-top: 10px;
        padding-left: 85px;
        padding-right: 20px;
        //display: flex;
        //column-gap: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 20%);
        justify-content: space-between;
        row-gap: 20px;
        column-gap: 10%;
        //padding: 20px;
        width: 80%;
        padding-bottom: 75px;


        @media ( max-width : 1200px) {
          grid-template-columns: repeat(auto-fill, 30%);
          width: 70%;
          grid-gap: 7%;
        }

        @media ( max-width : 500px) {
          grid-template-columns: 1fr;
          grid-gap: 2%;
          justify-items: center;
        }

        //margin: 0 auto;
      }

      @media ( max-width : 1320px) {
        display: block;
        .spsocb_boarding_part_one{
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .spsocb_boarding_card_input_div{
          padding-bottom: 15px;
          margin-left: 65px;
        }
      }

    }
  }



  .spso_boarding_action_buttons{
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
    margin-bottom:  20px;
  }
}

.spsocb_number_icon_div{
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #01AFFD;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  min-height: 45px;
  align-self: center;
  .spsocb_number{
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    color: #FFFFFF;

  }
}

.spspacv_validation_div{
  font-family: Inter;
  margin-left: 0;
  margin-top: 5px;
  margin-right: 20px;
  .spspacv_span{
    margin-bottom: 5px;
    margin-left: -20px;

  }
}
.draggable{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari, Chrome, Opera, Samsung */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Edge, IE */
  user-select: none; /* Modern browsers */
}
.se_menu_container.hidden{
  .left_menu {
    .closing_btn {
      right:-20px;
      transform: scale(-1);
    }
  }
}



.MuiTabs-indicator{
    left:0;
    //background-color: #01AFFD !important;
}
.Mui-selected{
    color:black;
}
.instructions_popup{

  .MuiPaper-root {
    max-width: 700px;
    height: 500px;
    width: 700px;

    :not(:only-child):first-child.MuiTab-root {
      border-right: 1px solid #000;

    }
    .iframe_container{
      aspect-ratio: 16 / 9;

      iframe{
        height: 100%;
        width: 100%;
      }
    }

    .MuiTab-root{
      text-transform: unset;
    }
  }
}
.slider_container{
  width:800px;
  height:340px;

  .MuiTab-root{
    width:580px;
    max-width: unset;
    aspect-ratio: 16/9;
    box-sizing: border-box;

    iframe{
      width: 100%;
      height: 100%;
    }
  }
  .MuiTabs-flexContainer {
    margin: 0 30px;
  }

  .MuiTabs-scrollButtons{
    width: 70px;

    .MuiSvgIcon-root{
      width:32px;
      height:32px;
    }
  }
}
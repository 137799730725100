.sp_cp_mainpanel_bp {
  width: 100%;
  display: block;
  box-sizing: border-box;
  border: 1px solid #fff;

  &.full_width {
    width: 100% !important;
  }

  &.auto_height {
    height: auto !important;
  }


  .sp_cp_mainpanel_bp.single-resizer {
    height: unset;
  }

  .full_height {
    height: 100% !important;
  }

  .sp_cp_childpanel {
    display: flex;
    height: 100%;
    width: auto;


    .c_c_chart_container {
      height: 100%;
    }

    .cropper-modal {
      background-color: #FFF;
      opacity: 0.5;

    }

    .crop_button {
      background: white;
      outline: none;
      font-family: Inter;
      font-size: 20px;
      height: 50px;
      border-radius: 15px;
      border: none;
      color: #01AFFD;

      &:hover {
        background: rgba(128, 215, 254, 0.2);

      }
    }
  }

  .square {
    border: none !important;
    background-color: #00c4cc !important;
  }

  &_autoheight {
    height: auto !important;

    .sp_cp_childpanel {
      height: auto;
    }
  }

  &_selected {
    border: 1px solid #00c4cc !important;

    .resizable-handler,
    .square,
    .rotate {
      display: block !important;
    }
  }

  &.hover:hover {
    border: 1px solid #00c4cc !important;
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }
}

.sp_cp_mainpanel_bp_centered {
  width: 25%;
  height: auto !important;
  display: block;
  margin-top: 20px;

  .sp_cp_childpanel {
    display: flex;
    height: 100%;
    width: auto;


    .cropper-modal {
      background-color: #FFF;
      opacity: 0.5;

    }

    .crop_button {
      background: white;
      outline: none;
      font-family: Inter;
      font-size: 20px;
      height: 50px;
      border-radius: 15px;
      border: none;
      color: #01AFFD;

      &:hover {
        background: rgba(128, 215, 254, 0.2);

      }
    }
  }

  .square {
    border: none !important;
    background-color: #00c4cc !important;
  }

  &_autoheight {
    height: auto !important;

    .sp_cp_childpanel {
      height: auto;
    }
  }


  &_selected {
    border: 1px solid #00c4cc !important;

    .resizable-handler,
    .square,
    .rotate {
      display: block !important;
    }
  }

  &.hover:hover {
    border: 1px solid #00c4cc !important;
  }

  *:focus {
    outline: none;
  }
}


.sp_cp_active_line_horizontal {
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  border-top: 1px dashed #00c4cc;
}

.sp_cp_active_line_vertical {
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
  border-left: 1px dashed #00c4cc;
}

.sp_cp_active_box {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;

  .sp_cp_active_box_inner {
    border: 1px dashed #00c4cc;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }
}

#contextMenuContentPanel {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sp_cp_mainpanel_bp.hover {
  box-sizing: border-box;
}
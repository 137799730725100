.scenario_menu {
  top: -40px;
  left: -30px;

  .menu_paper {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 251px;

    .MuiList-root {
      background-color: white;
      padding-top: 0;
      padding-bottom: 0;

      .menu_item {
        display:flex;
        justify-content: space-between;
        align-items: center;
        font-family: "Inter";
        font-size: 14px;
        line-height: 18px;
        color: #B9BFC3;
        background: white;
        padding:0 6px 0 0;

        .scenario_name{
          width:90%;
          padding: 6px 0 6px 16px;
          overflow: hidden;
        }

        .edit_icon{
          padding:5px;
          svg{
            height:18px;
            width:18px;
          }
          path {
            stroke: #D4D5DE;
          }
        }


        .edit_icon:hover {
          path {
            stroke: #61dafb;
          }
        }
        .trash_can{
          padding:5px;
        }
        .trash_can:hover{
          path{
            stroke:red;
          }
        }
      }
      .menu_item{
        color: #979797;
      }
      .menu_item:hover {
        background:#FBFBFB;
        color: #979797;

      }
      .menu_item.first {
        padding-top: 7px
      }
      .menu_item.selected{
        color:rgba(0, 0, 0, 0.54);
        font-weight:700;
      }

      .menu_item.selected_scenario{
        color: #01AFFD;
        font-weight: 700;
      }

      .scenario_divider{
        width: 100%;
        height: 6px;
        padding: 0;
        margin: 0;
      }

      .scenario_instruction{
        display: flex;
        width: 100%;
        align-items: center !important;
        padding-left: 12px;
        .scenario_instruction_title{
          font-family: Inter;
          font-size: 14px;
          color: #0080BF;
          padding-right: 5px;
        }

      }

      .scenario_videos{
        height: 30px;
        font-size: 14px;
        padding-left: 17px;
        .scenario_instruction_title{
          font-family: Inter;
          font-size: 14px;
          color: #0080BF;
          padding-right: 5px;
        }
      }

      .add_new_scenario_button {
        padding-right: 5px !important;
        font-family: "Inter";
        font-size: 14px;
        color: #01AFFD;
        font-weight: 700;
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 33px !important;

      }
    }
  }
  .scim_tool_tip_click{
    width: 100% !important;
    border-top: 1px solid #D4D5DE;

    height: 30px !important;
    border-radius: unset !important;
  }
}

.scenario_add_content{
  height:300px;
  display: flex;
  flex-direction: column;
  column-gap:10px;
  padding-top:25px;
  padding-left:11px;

  .sad_input{
    width:70%;
    margin-top:5px;
    margin-bottom:19px;
  }
  .existing_scenario_input{
    visibility: hidden;
  }

}

.scmsc_scenario_inputs_div{
  display: flex;
  align-items: center;
  .scmsc_inputs_div{
    width: 300px;
  }

}


.subscriptions_container_register{
  background-color: #fff;
  width: 100%;
  min-height:100%;

  .f_t_btn_cont{
    display:flex;
    width:100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none !important;
    font-family: "Inter",sans-serif;
  }
  .savings{
    text-align: center;
    justify-content: center;

    font-size: 16px;
    font-weight: 500;

    .price{
      font-weight: 700;
      color: #0080BF !important;
      font-size: 16px;
    }
  }

  h2.secondary{
    color: #0080BF !important;
  }

  h2.yearly{
    margin-bottom: 0 !important;
    line-height: 65px !important;
  }
  .text-center{
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  h1{
    font-size: 40px;
  }

  .hero-pricing {
    color: #221644
  }

  .hero-pricing .pricing-heading {
    font-weight: 900
  }

  .hero-pricing input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden
  }

  .hero-pricing .pricing-save {
    margin-bottom: 60px;
    margin-top: 10px;
    text-align: center
  }

  .hero-pricing input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%)
  }

  .hero-pricing label:active:after {
    width: 130px
  }

  .hero-pricing .switch-cards {
    /* display:none; */
  }

  .hero-pricing__plans-wrapper-monthly h1 {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }

  .hero-pricing__plans-wrapper-monthly {
    display: flex;
    justify-content: center;
  }

  .pricing-back_login{
    margin-left: 10%;
    padding-right: 10px;

  }

  @media (max-width: 767.98px) {
    .hero-pricing__plans-wrapper-monthly {
      flex-direction:column
    }
  }

  @media (max-width: 767.98px) {
    .hero-pricing__plans-wrapper-monthly div:nth-child(2) {
      margin-top:100px
    }
  }

  .hero-pricing__plans-wrapper-monthly span {
    font-size: 20px
  }

  .hero-pricing__plans-wrapper-monthly__card {
    box-shadow: rgba(99,99,99,0.2) 0px 2px 8px 0px;
    margin: 15px;
    padding: 29px;
    position: relative;
    width: 32%;
    border: 1px solid white;

    &.blue_border{
      border-color: #01AFFD;
    }
  }

  @media (max-width: 991.98px) {
    .hero-pricing__plans-wrapper-monthly__card {
      margin:10px
    }
  }

  @media (max-width: 767.98px) {
    .hero-pricing__plans-wrapper-monthly__card {
      width:calc(100% - 20px)
    }
  }

  .hero-pricing__plans-wrapper-monthly__card .most-popular {
    position: absolute;
    top: -49px;
    background: #15b2f8;
    width: 100%;
    left: 0;
    padding: 7px;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    display: flex;
    justify-content: center;

    &.recommended{
      background-color: #0080BF;
    }
  }

  .hero-pricing__plans-wrapper-monthly__card h1 {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px
  }

  .hero-pricing__plans-wrapper-monthly__card h2 {
    font-size: 58px;
    line-height: 73px;
    color: #01AFFD;
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px
  }

  @media (max-width: 1399.98px) {
    .hero-pricing__plans-wrapper-monthly__card h2 {
      font-size:56px;
      line-height: 71px
    }
  }

  @media (max-width: 1199.98px) {
    .hero-pricing__plans-wrapper-monthly__card h2 {
      font-size:46px;
      line-height: 61px
    }
  }

  @media (max-width: 991.98px) {
    .hero-pricing__plans-wrapper-monthly__card h2 {
      font-size:40px;
      line-height: 55px
    }
  }

  @media (max-width: 575.98px) {
    .hero-pricing__plans-wrapper-monthly__card h2 {
      font-size:36px;
      line-height: 51px
    }

    .pricing-heading{
      padding-top: 5px;
      font-size: 28px;
    }

    .pricing-back_login{
      margin-left: 10% !important;
      padding-right: 10px;
    }
  }

  .hero-pricing__plans-wrapper-monthly__card h2 span {
    color: #221644;
    font-size: 16px
  }

  .hero-pricing__plans-wrapper-monthly__card .first-paragraph {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #221644
  }

  .hero-pricing__plans-wrapper-monthly__card div {
    display: flex;
    align-items: center;
    margin-bottom: 10px
  }

  .hero-pricing__plans-wrapper-monthly__card div .feature_span {
    font-size: 18px;
    line-height: 20px;
    margin-left: 19px
  }

  .hero-pricing__plans-wrapper-monthly__card a {
    text-align: center;
    background-color: #01AFFD;
    text-transform: uppercase;
    color: white;
    font-size: 20px;
    text-decoration: none;
    padding: 10px 30px;
    margin-bottom: 26px
  }

  .hero-pricing__plans-wrapper-monthly__card__link {
    display: flex;
    justify-content: center;
    align-items: center
  }

  .hero-pricing__plans-wrapper-annual {
    display: flex;
    justify-content: center
  }

  @media (max-width: 384px) {
    .pricing-switcher{
      display: block !important;
      :last-child{
        margin-top: inherit !important;
        //padding-top: 20px;
      }
    }
  }

  @media (max-width: 767.98px) {
    .hero-pricing__plans-wrapper-annual div:nth-child(2) {
      margin-top:100px
    }
  }

  @media (max-width: 767.98px) {
    .hero-pricing__plans-wrapper-annual {
      flex-direction:column
    }
  }

  .hero-pricing__plans-wrapper-annual span {
    font-size: 20px
  }

  .hero-pricing__plans-wrapper-annual__card {
    box-shadow: rgba(99,99,99,0.2) 0px 2px 8px 0px;
    margin: 15px;
    padding: 30px;
    position: relative;
    width: 32%
  }

  @media (max-width: 991.98px) {
    .hero-pricing__plans-wrapper-annual__card {
      margin:10px
    }
  }

  @media (max-width: 767.98px) {
    .hero-pricing__plans-wrapper-annual__card {
      width:calc(100% - 20px)
    }
  }

  .hero-pricing__plans-wrapper-annual__card .most-popular {
    position: absolute;
    top: -49px;
    background: #15b2f8;
    width: 100%;
    left: 0;
    padding: 7px;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    display: flex;
    justify-content: center
  }

  .hero-pricing__plans-wrapper-annual__card h1 {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px
  }

  .hero-pricing__plans-wrapper-annual__card h2 {
    font-size: 58px;
    line-height: 73px;
    color: #01AFFD;
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px
  }

  @media (max-width: 1399.98px) {
    .hero-pricing__plans-wrapper-annual__card h2 {
      font-size:56px;
      line-height: 71px
    }
  }

  @media (max-width: 1199.98px) {
    .hero-pricing__plans-wrapper-annual__card h2 {
      font-size:46px;
      line-height: 61px
    }
  }

  @media (max-width: 991.98px) {
    .hero-pricing__plans-wrapper-annual__card h2 {
      font-size:40px;
      line-height: 55px
    }
  }

  @media (max-width: 575.98px) {
    .hero-pricing__plans-wrapper-annual__card h2 {
      font-size:36px;
      line-height: 51px
    }
  }

  .hero-pricing__plans-wrapper-annual__card h2 span {
    color: #221644;
    font-size: 16px
  }

  .hero-pricing__plans-wrapper-annual__card .first-paragraph {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #221644
  }

  .hero-pricing__plans-wrapper-annual__card div {
    display: flex;
    align-items: center;
    margin-bottom: 10px
  }

  .hero-pricing__plans-wrapper-annual__card div span {
    font-size: 18px;
    line-height: 20px;
    margin-left: 19px
  }

  .hero-pricing__plans-wrapper-annual__card a {
    background-color: #01AFFD;
    text-transform: uppercase;
    color: white;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    padding: 10px 30px;
    margin-bottom: 26px
  }

  .hero-pricing__plans-wrapper-annual__card__link {
    display: flex;
    justify-content: center;
    align-items: center
  }

  .pricing-switcher {
    margin: 20px auto 17px;
    text-align: center;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .pricing-back_login{
      margin-left: 30%;

    }
  }

  .pricing-switcher .fieldset {
    display: inline-block;
    position: relative;
    padding: 2px;
    margin-bottom: 0px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.1);
    border-radius: 79px
  }

  .pricing-switcher input[type="radio"] {
    position: absolute;
    opacity: 0
  }

  .pricing-switcher input[type="radio"]:checked+label {
    color: white
  }

  .pricing-switcher label {
    position: relative;
    z-index: 1;
    display: inline-block;
    float: left;
    width: 108px;
    height: 45px;
    line-height: 45px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #002739
  }

  .pricing-switcher .switch {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 45px;
    width: 108px;
    background-color: #15b2f8;
    border-radius: 50em;
    -webkit-transition: -webkit-transform 0.5s;
    -moz-transition: -moz-transform 0.5s;
    transition: transform 0.5s
  }

  .pricing-switcher input[type="radio"]:checked+label+.switch,.pricing-switcher input[type="radio"]:checked+label:nth-of-type(n)+.switch {
    -webkit-transform: translateX(108px);
    -moz-transform: translateX(108px);
    -ms-transform: translateX(108px);
    -o-transform: translateX(108px);
    transform: translateX(108px)
  }

  .container-custom-width {
    padding: 0px !important;
    max-width: 1204px
  }

  @media (max-width: 1399.98px) {
    .container-custom-width {
      padding:0px 20px !important
    }
  }

  @media (max-width: 767.98px) {
    .container-custom-width {
      padding:0px 16px !important
    }
  }

  .contact-sales {
    margin-top: 100px
  }

  .contact-sales .view-more {
    position: relative;
    padding: 70px 0px 183px;
    width: max-content;
    margin: 60px auto 0 auto
  }

  .contact-sales .view-more.pricing-title {
    padding-top: 0px;
    padding-bottom: 70px
  }

  @media (max-width: 1199.98px) {
    .contact-sales .view-more {
      padding-top:100px
    }
  }

  @media (max-width: 991.98px) {
    .contact-sales .view-more {
      padding:80px 0px 143px
    }
  }

  @media (max-width: 575.98px) {
    .contact-sales .view-more {
      width:100%
    }
  }

  .contact-sales .view-more .shape5 {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1
  }

  @media (max-width: 1199.98px) {
    .contact-sales .view-more .shape5 {
      top:-20px
    }
  }

  @media (max-width: 991.98px) {
    .contact-sales .view-more .shape5 {
      top:-33px;
      width: 220px
    }
  }

  @media (max-width: 575.98px) {
    .contact-sales .view-more .shape5 {
      top:-20px
    }
  }

  .contact-sales .view-more a {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #fff;
    background-color: #01AFFD;
    border: 2px solid #01AFFD;
    border-radius: 4px;
    padding: 10px 70px;
    text-decoration: none;
    transition: all 0.5s;
    position: relative;
    z-index: 10
  }

  @media (max-width: 575.98px) {
    .contact-sales .view-more a {
      padding:10px;
      display: block;
      text-align: center
    }
  }

  .contact-sales .view-more a:hover {
    background-color: #fff;
    color: #01AFFD
  }
}

.container-fluid{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;

  &.container-custom-width{
    padding: 0px !important;
    //max-width: 1204px;
  }
}
.cant_share_dialog {
  .MuiPaper-root {
    width: 600px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    font-family: Inter;
    color: #252525;
    box-sizing: border-box;
    overflow: hidden;

    .p1_strong {
      font-weight: 700;
      font-size: 20px;
    }

    .p3_strong {
      font-weight: 700;
      font-size: 14px;
    }

    .cld_heading {
      padding-top: 12px;
    }

    .cld_typography {
      width: 737px;
      font-size: 14px;

      .lcd_typography_link {
        font-size: 14px;
        font-weight: 700;
        color: #252525;
      }
    }

    .cld_typography2_buttons {
      display: flex;
      padding-top: 10px;
      justify-content: center;
    }


    .cld_close_button {
      position: absolute;
      top: 13px;
      right: 10px;
    }
  }
}
.section_dialog {

  .MuiDialogTitle-root{
    padding: 16px 18px;
  }
  .MuiDialogContent-root{
    padding: unset;
  }
  .MuiDialogActions-root{
    justify-content: center;
  }

  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 640px;
    width: 880px;

    .dialog_header {
      width: 100%;
      height: 92px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dialog_title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 160%;
        color: #252525;
        padding-top: 38px;
        padding-left: 16px;
      }

      .exit_btn_container {
        width: 40px;
        height: 100%;
        padding-top: 10px;
        padding-right: 6px;
      }
    }

    .dialog_content {
      box-sizing: border-box;
      scroll-behavior: smooth;
      .dialog_divider {
        margin-top: 6px;
        border: 1px solid #F0F1F7;
      }

      .sections_container {
        box-sizing: border-box;

        padding: 0 0 10px 0;
        overflow: overlay;
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .section_bp{
          box-sizing: border-box;
          margin: 16px 0 0 16px;
          //width: 105px;
          height: 180px;
          aspect-ratio: 347/491;

          background: #FFFFFF;
          /* Light_Grey/150 */

          border: 1px solid #F0F1F7;
          /* cards */

          display: flex;
          align-items: flex-end;
          justify-content: center;

          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          position: relative;
          box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);


          .section_thumbnail_bp{
            width: 100%;
            height: 100%;
            border-radius: 5px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .section_name_bp{
            font-family: "Inter";
            font-style: normal;
            font-size: 14px;
            font-weight: bold;
            width: 100%;
            color: #000000;
            border-radius: 5px;
            padding:  0 8px 8px;
            background-color: #FFFFFF;
            opacity: 90%;
            z-index: 1;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
          }

          &:hover {
            background: #F8FEFF;
          }

        }


        .section {
          box-sizing: border-box;
          margin: 16px 0 0 16px;
          width: 200px;
          height: 112.5px;
          aspect-ratio: 16/9;

          background: #FFFFFF;
          /* Light_Grey/150 */

          border: 1px solid #F0F1F7;
          /* cards */

          display: flex;
          align-items: flex-end;
          justify-content: center;

          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          position: relative;
          box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);

          .section_thumbnail{
            width: 100%;
            height: 100%;
            border-radius: 5px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .section_name {
            font-family: "Inter";
            font-style: normal;
            font-size: 14px;
            font-weight: bold;
            width: 100%;
            color: #000000;
            border-radius: 5px;
            padding: 8px;
            background-color: #FFFFFF;
            opacity: 90%;
            z-index: 1;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
          }

          &:hover {
            background: #F8FEFF;
          }
        }

        .section.selected {
          background: #E1F2F6;
          /* Light_Blue/150 */

          border: 1px solid #80D7FE;
          box-shadow: 0px 0px 4px rgba(0, 178, 255, 0.1);
        }

        .section_bp.selected{
          background: #E1F2F6;
          /* Light_Blue/150 */

          border: 1px solid #80D7FE;
          box-shadow: 0px 0px 4px rgba(0, 178, 255, 0.1);
        }
      }

    }
    .dialog_actions_container {
      height: 100%;
      vertical-align: bottom;
      padding: 50px 0 20px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      //align-items: center;
      justify-content: flex-end;

      .trash_can {
        padding: 5px;
      }
      .trash_can:hover {
        path {
          stroke: red;
        }
      }

      .dialog_actions {
        width: 65%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.MuiTooltip-popper{
  z-index: 5000;
}

.psfcf_finance_details_tab_titles_div{
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  margin: 0 !important;
  align-items: center;

  .MuiTabs-root {
    display: flex;
    height: 68px;
    padding-right: 20px;
  }

  .psfcf_finance_details_tab_paragraph{

  }
  .psfcf_finance_details_single_tab_title{

  }

  .psfcf_finance_details_tab_titles_single_tab_general{
    text-transform: none;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    margin-right: 2px;

    &.active {
      text-shadow: .25px 0 .1px, -.25px 0 .1px !important;
      transition: width 2s, height 4s;

    }

  }
  .psfcf_finance_details_tab_titles_content{
    margin-left: 20px;
    grid-gap: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin-right: 15px;
    margin-top: 12px;
    .psfcf_finance_details_tab_titles_tabs{
      display: flex;
      justify-content: center;
      align-content: center;




    }

  }

  .psfcf_finance_details_tab_titles_single_save_close{
    margin-right: 20px;
    margin-top: 8px;
    grid-gap: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .psfcf_finance_details_tab_titles_tabs_tooltip{
    display: flex;
    grid-gap: 0;
  }

}

.MuiTabs-scrollButtons{
  padding-top: 15px !important;
  color: #01AFFD !important;
  margin-right: 5px !important;
  margin-left: 5px !important;

}

.MuiTabs-indicator {
  background-color: transparent !important;
  .psfcf_finance_details_tab_titles_tabs_indicator{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


}

.psfcf_finance_details_tab_titles_single_tab_preview{
  text-transform: none;
  font-weight: 500;
  font-size: 12px;
  margin-right: 2px;
  color: rgba(255, 255, 255, 0.7);
}

.Mui-focusVisible{
  background-color: rgba(100, 95, 228, 0.32);
}

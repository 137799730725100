.psfco_main_container{
  //margin: 0 16px 0 16px;
  display: flex;
  column-gap:100px;

  .psfco_opening_overview{
    display: inline-block;
    flex-direction: column;
    grid-row-gap: 16px;
    width: 500px;

    .psfco_overview_table {
      border: 1px solid #F0F1F7 !important;
      border-radius: 5px !important;

      .psfco_overview_table_header{
        height: 40px !important;
        padding: 0 !important;
        border-right: 1px solid #E0E0E0 !important;
        border-bottom: 1px solid #E0E0E0 !important;

        .psfco_overview_table_cell{
          font-family: Inter;
          min-width: 124px;
          max-width: 124px;
          width: 124px;
          padding: 0 10px  !important;
          background: white;
          border-right: 1px solid #E0E0E0 !important;
          border-bottom: 1px solid #E0E0E0 !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;


          .mx_numinput.mct_ft_dialog_input{
            width: 100%;
            min-width: max-content;
            border: none !important;
          }


          &.bold {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 160%;
          }
        }
        .psfco_overview_table_icon {
          width: 10px;
          margin-right: 10px;
          &.active {
            transform: rotate(180deg);
          }

          &.hide {
            color: transparent;
          }
        }



      }
    }

  }
}

.psfco_overview_table_header.psfco_overview_main_header {
  th:first-child{
    min-width: 200px !important;
  }
}



.psfco_opening_overview_description{
  li:not(:last-child) {
    margin-bottom: 15px;
  }
}



.mx_big_numinput {
  position: relative;
  overflow: hidden;
  text-align: center !important;
  background: #FFFFFF;
  border: 1px solid #D4D5DE;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px 10px 0 9px;
  //width: 196px;
  width: 190px;
  max-height: 96px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 140%;
  .mx_big_numinput_input {
    background-color: unset;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 54px;
    line-height: 140%;
    border: none;
    text-align: center;
    padding: 0 0 0 0;
    color: #979797;
    vertical-align: center;
    justify-content: center;


    &:focus {
      outline: none;
      border: none;

    }
  }

  .mx_big_numinput_input.mx_big_editable{
    color: #252525;
    font-weight: 500;
  }

  .mx_big_numinput_input.mx_bigdisabled{
    color: #D4D5DE !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  input.mx_big_numinput_input{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    padding-right: 6px;
    font-weight: 700;
    font-size: 64px;

    background-color: transparent;
    color: transparent;
    justify-content: center !important;

    &:focus{
      background-color: white;
      color: #252525;
    }
  }






}


.psfco_overview_table_div{
  width: 550px;
}

.psfco_overview_table_cell{
  height: 50px;
  overflow-x: hidden;
  jusitfy-content: center;
  :first-child{
    border: none;
  }
}

.psfco_overview_table_cell.psfco_overview_hide_b{
  .span{
    color: red !important;
  }
  .MuiButton-root:hover{
    border: none !important;
  }

  .MuiButton-root{
    text-transform: none;
  }

  .MuiTouchRipple-root:focus-visible{
    outline : none !important;
  }
  .MuiTouchRipple-root:hover{
    outline : none !important;
  }
}

.psfco_overview_table_cell.psfco_ref_base{
  .psfco_ref_base_div{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .MuiSelect-select{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 160%;
      /* or 19px */


      /* Light_Blue/300 */

      color: #01AFFD;
    }

    .MuiInputBase-root{
      border: none !important;
    }

    .MuiSvgIcon-root {
      fill:#01AFFD;
    }
    .MuiOutlinedInput-notchedOutline{
      border: none;
    }

  }
  .psfco_span_ref_b{
    margin-top: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */
    /* Dark_Grey/500 */
    color: #252525;
  }

}

.psfco_select{
  background: white;
  border-right: 1px solid #E0E0E0 !important;
}
.pitch_scenario_menu{
  .szh-menu__item{
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 5px 8px 5px 8px;

    .MuiIconButton-root{
      padding: 3px;
    }
    .edit_icon{
      svg{
        height:14px;
        width:14px;
      }
      path {
        stroke: #D4D5DE;
      }
    }
    .trash_can{
      svg{
        height:14px;
        width:14px;
      }
    }
    .edit_icon:hover {
      path {
        stroke: #61dafb;
      }
    }
    .trash_can:hover{
      path{
        stroke: red;
      }
    }
  }
}
.mctabs_tabs {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;

  .mc_tabs_tab_cont {
    display: flex;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
  }

  & .MuiTabs-root {
    display: flex;
  }
  & .MuiTabs-indicator {
    //margin-left: 40px;

    background-color: transparent;

  }
  .MuiTabs-scroller {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;

    .MuiTabs-flexContainer {
      //
      justify-content: flex-start;
      grid-gap: 50px;

      //justify-content: space-between;


      width: 100%;
      min-width: 470px;

      .mctabs_tab {
        padding: 0;
        min-width: 0;
        color: #252525;

        &.blue{
          color: #0080BF;
        }
      }
      .p2 {
        text-transform: none;
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
      }
      .p3 {
        text-transform: none;
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
      }

      .selected {
        text-shadow: .25px 0 .1px,
        -.25px 0 .1px;
      }

      .not_selected {
        font-weight: 400 !important;
      }

      & .Mui-focusVisible {
        background-color: rgba(100, 95, 228, 0.32);
      }

    }
    .mctabs_indicator {
      display: flex;
      justify-content: center;
      width: auto;
      align-items: center;
    }
  }

}
.mctabs_tabs.sub_tabs{
  .MuiTabs-scroller {
    justify-content: flex-start;

    .MuiTabs-flexContainer{
      min-width: 738px;
      max-width: 1000px;
      grid-gap: 33px !important;
      
    }


  }


  .spsd_colored_tab{
    color: #01AFFD !important;
  }


}

.mctabs_tabs.profile_tabs{
  padding-left: 17px;
  .MuiTabs-scroller {
    justify-content: flex-start;

    .MuiTabs-flexContainer{
      min-width: 500px;
    }
  }
}

.spsfcrr_reports_tabs{
  .MuiTabs-flexContainer{
    min-width: 0 !important;
    grid-gap: 20px !important;
  }

}
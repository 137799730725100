.psfrss_single_subscription_content_div{
  margin-bottom: 10px;
  .psfrss_single_subscription_header{
    display: flex;
    .MuiButtonBase-root{
      height: 25px;
      align-self: center;
      margin-left: 5px;
    }
  }
  .psfrss_single_subscription_title{
   display: flex;
   justify-content: flex-start;
  }
  .psfrss_single_subscription_input_div{
    display: flex;
    align-self: start;
    column-gap: 20px;
    font-family: arial;
    width: 140px;
  }

}
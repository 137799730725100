.main_table_container {

  .expanded{
    padding:0 !important;

    border: none;
    .expanded_body{
    }
  }


  .finance_table_main_wrapper_div {
    margin: 25px 16px 0 16px;
    overflow-y: overlay;
    overflow-x: overlay;

    &:hover {
      //overflow-x: overlay !important;
      //overflow-y: hidden  !important;
    }

    height: auto;
    max-height: 440px;
    //border-bottom: 1px solid #F0F1F7;
    border-radius: 5px;
    width: 100%;
    max-width:-webkit-fill-available;
    max-width: -moz-available;
    //border: 1px solid #E0E0E0;
    border-left:1px solid #E0E0E0;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;

    .financetablemain_wrapper.mct_ft_table {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
      border: none;
      border-radius: 5px;




      .mct_ft_tablehead {
        position: sticky;
        top:0;
        z-index: 2;

        .MuiTableRow-head{
          //display:block;
          :last-child{
            //border-right: 1px solid transparent;
          }

          .MuiTableCell-root {
            &:first-child {
             min-width: 320px;
             width: 320px;
             max-width: 320px;

              &.max_height{
                min-width: 310px !important;
                width: 310px !important;
                z-index: 100 !important;
              }
            }
          }
        }

        .mct_cell_center{
          text-align: center;
        }

        .mct_cell_end{
          text-align: end;
        }

        .mct_ft_tablehead_cell.mct_ft_tablehead_cell_year {
          background: #FFFFFF;
        }
        .mct_ft_tablehead_cell {
          //min-width:110px;
          //max-width: 170px;
          //width: 170px;

          &:first-child {
            border-radius: 5px 0 0 0;
            text-align: left;
            background: #FFFFFF;
            position: sticky;
            left: 0;
            z-index: 11;

            &.low_z_index {
              z-index: 1;
            }
          }

          &:last-child {
            border-radius: 0 5px 0 0;
          }
        }

        .mct_ft_tablehead_paragraph.mct_ft_disabled {
          color: #D4D5DE;
        }
      }
      .mct_ft_tablebody.mct_ft_footer_sticky {
        position: sticky;
        bottom: 0;
        z-index: 10;
      }
      .mct_ft_tablebody {

        .mct_ft_tablerow:hover {


          .mct_ft_tablerow_header {
            background: #E9EAF0;

          }

          .mct_ft_tablebody_cell {
            background: #E9EAF0;
            .mct_ft_tablebody_cell_div {
              .mx_numinput {
                background: #E9EAF0;
              }
            }
          }
          .mct_ft_tablebody_cell.mct_ft_year {
            background: #E9EAF0;
            .mct_ft_tablebody_cell_div {
              .mx_numinput {
                background: #E9EAF0;
              }
            }

          }

          &:last-child {

            .mct_ft_tablerow_header.is_total {
              background: #E1F2F6;
              border-radius: 0 0 0 5px;


            }

            .mct_ft_tablebody_cell.is_total {
              background: #E1F2F6;

            }
          }


        }
        .mct_ft_tablerow.totals {

          .mct_ft_tablerow_header{
            background: #E1F2F6;
            //border-bottom: none;
            //border-radius: 0 0 0 5px;
          }

          .mct_ft_tablebody_cell {
             background: #E1F2F6;
             //border-bottom: none;
             //border-bottom: 1px solid transparent;
             .mx_numinput{
               background: inherit !important;
             }

             &:last-child {
               border-radius: 0 0 0 0;
               border-right: 1px solid transparent;

             }
           }
          .mct_ft_tablebody_cell.mct_ft_year {
            border-top: 1px solid #E1F2F6;

          }

          &:hover{
            .mct_ft_tablebody_cell{
              background: #E1F2F6;

            }
          }
        }


        .mct_ft_tablerow {
          min-height: 40px;
          &.mct_ft_table_italic{
            .mx_numinput_input{
              font-style: italic !important;
            }
          }


          .mct_ft_tablerow_header {
            min-width: 320px !important;
            width: 320px !important;
            border-right: 1px solid #E0E0E0;
            //border-left: 1px solid #E0E0E0;
            background: #FFFFFF;
            &.max_height{
              min-width: 310px !important;
              width: 310px !important;
            }
            &:hover {

              .mct_ft_tablerow_header_div {

                .mct_ft_tablerow_header_container {
                  .mct_ft_tablerow_header_icons {
                    visibility: visible;
                  }
                }
              }
            }

            .mct_ft_tablerow_header_div {
              display: flex;
              align-items: center;
              padding-right: 8px;
              margin-left: 8px;
              //width: 90%;
              width: -webkit-fill-available;
              width: -moz-available;

              //max-width: 90%;
              cursor: pointer;

              .mct_ft_tablerow_header_arrow {
                display: flex;
                align-items: flex-start;
              }
              .mct_ft_tablerow_header_arrow.hidden {
                visibility: hidden;
              }

              .mct_ft_tablerow_header_container {
                width: 100%;
                display: flex;
                max-width: 100%;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
                font-family: Inter;

                .mct_ft_table_header_input_div{
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  column-gap: 10px;
                  justify-items: center;
                  align-content: center;
                  align-items: center;
                }

                .mct_ft_table_header_input{
                  border: 1px solid transparent;
                  border-radius: 5px;
                  outline: none;
                  width: 100%;
                  height: 100%;
                  font-family: Inter;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 160%;
                  color: #252525;

                }

                .mct_ft_table_head_warning{
                  border: 1px solid red !important;
                }






                .mct_ft_tablerow_header_paragraph.mct_ft_disabled {
                  color: #D4D5DE;
                }

                .mct_ft_tablerow_header_paragraph {
                  margin-left: 8px;
                  //font-weight: 700;
                  font-size: 14px;
                  font-family: Inter;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  //max-width: 180px ;
                  &.max_height{
                    overflow: initial;
                  }
                  &.mct_ft_first_lvl{
                    max-width: 260px !important;
                  }

                  &.mct_ft_table_italic{
                    font-style: italic !important;

                  }
                }




                .mct_ft_table_row_header_bold-{
                  color: #252525;
                  font-weight: 300;
                }

                .mct_ft_table_row_header_bold-0{
                  color: #252525;
                  font-weight: 700 !important;
                }
                .mct_ft_table_row_header_bold-1{
                  color: #252525;
                  font-weight: 500 !important;;
                }
                .mct_ft_table_row_header_bold-2{
                  color: #979797;
                  font-weight: 300 !important;;
                }

                .mct_ft_table_row_header_bold-3{
                  color: #979797;
                  font-weight: 300 !important;;
                }

                .mct_ft_tablerow_header_paragraph.child {
                  font-family: 'Inter';
                  font-style: normal;
                  font-size: 14px;
                }

                .mct_ft_tablerow_header_icons {
                  display: flex;
                  column-gap: 3px;
                  visibility: hidden;
                }

                .mct_ft_tablerow_header_icons_visible{
                  display: inline-flex;
                  column-gap: 8px;
                  align-items: center;
                }


              }

            }

          }

          .MuiTableCell-root:focus{
            //border: 1px solid #01AFFD !important;

          }

          .mct_ft_tablebody_cell.selected{
            //border: 1px solid #01AFFD !important;
            outline: none !important;
          }
          .mct_ft_tablebody_cell.t{
            border-top: 1px solid #01AFFD !important;
            outline: none !important;
          }
          .mct_ft_tablebody_cell.b{
            border-bottom: 1px solid #01AFFD !important;
            outline: none !important;
          }
          .mct_ft_tablebody_cell.l{
            border-left: 1px solid #01AFFD !important;
            outline: none !important;
          }
          .mct_ft_tablebody_cell.r{
            border-right: 1px solid #01AFFD !important;
            outline: none !important;
          }
          .mct_ft_tablebody_cell.mct_ft_year {
            .mct_ft_tablebody_cell_div {
              .mx_numinput {
                background: #FFFFFF;
              }
            }
          }

          .mct_ft_tablebody_cell {



            outline: none !important;
            .mct_ft_tablebody_cell_div {
              -webkit-touch-callout: default; /* iOS Safari */
              -webkit-user-select: default; /* Safari */
              -khtml-user-select: default; /* Konqueror HTML */
              -moz-user-select: default; /* Old versions of Firefox */
              -ms-user-select: default; /* Internet Explorer/Edge */
              user-select: default; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
              width: 100%;
              height: 100%;

              .mct_ft_tablebody_input.totals {
                font-weight: 700;
                color: black;
              }

              .mct_ft_tablebody_input {
                color: black;
              }

              input.mct_ft_tablebody_input {
                color: transparent;
              }



            }

            &:last-child {
              border-right: 1px solid transparent;

            }

          }

        }

      }
    }
  }

  .max_height.finance_table_main_wrapper_div{
    max-height: 750px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(128, 215, 254, 0.7);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track-piece:start {
    margin-top: 42px;
    margin-left:321px;
  }
  ::-webkit-scrollbar-thumb:hover {
    //overflow-x: overlay;
  }

}

.mct_ft_table_head_warning_tooltip{
  font-size: 12px !important;
  font-family: 'Inter';
  color: red ;
  padding: 0;
  margin: 0;
}

.sctfcf_edit_div{
  display: flex;
  column-gap: 10px;
  height: 25px;
  input{
    border: 1px solid transparent;
    border-radius: 5px;
    outline: none;

  }
}

#dbl_srcl_wrapper1{
  width: 100%;
  border: none;
  overflow-x: scroll; overflow-y:scroll;}
#dbl_srcl_wrapper1{height: 9px;}
#dbl_srcl_div1 {height: 9px;}




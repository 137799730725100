.financial_main_charts_wrapper{
  width:-webkit-fill-available;
  //min-height: 300px;
  background: white;
  border: 1px solid #F0F1F7;
  border-radius: 5px;
  //padding:10px;
  //padding-left:9px;
  //margin-left: 15px;
  //margin-right: 15px;
  .financial_main_charts_menu{
    margin-left: 16px;
    margin-right: 16px
  }



  .financial_main_charts_charts_container{
    width: 100%;
    display: flex;
    max-height: 210px;
    padding-bottom: 30px;
    margin-top: 15px;
  }
}

.hide_chart{
    min-height: unset;
    background: transparent;
    padding: 10px 0 0 0;
    border:0;
 }
.share_dialog{

  .MuiPaper-root {
    width: 850px;
    .p1Strong {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
    }

    .p2Strong{
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
    }


    .share_dialog_main_container {
      font-family: Inter;
      padding: 33px;

      .sd_mc_line{
        border: 0.657432px solid #B9BFC3;
        height: 0px;
      }

      .sd_mc_close_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

      }

      .sd_mc_invite_container{
        margin-top: 18px;
      }

      .sd_mc_message_container{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        //justify-content: center;
        align-items: center;
        padding: 16px;
        margin-top: 12px;
        border: 1px solid #F0F1F7;
        border-radius: 5px;
        background-color: #FBFBFB;

        .sd_mc_mc_paragraph{
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 160%;
        }
      }

      .sd_mc_submit_invite_container{
        margin-top: 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 16px;

        .loader_container {
          visibility: visible;
          display:flex;
          justify-content: center;
          align-items: center;
          width:25px;
          height:36px;
          .loader_small {
            transform: scale(0.15);
            svg {
              margin: 0;
            }
          }
        }

        .loader_container.hidden{
          visibility: hidden;
        }
        .sd_mc_sic_input_container {
          height: 34px;
          width: 600px;
          .sd_mc_sic_input {
            box-sizing: border-box;
            width: 100%;
            border: 1px solid #F0F1F7;
            border-radius: 8px;
            line-height: 160%;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000;
            padding: 6px 16px 6px 16px;
          }
        }
      }

      .sd_mc_invited_mails_container {
        margin-top: 5px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;

        table {
          max-width: 100%;
          width: 100%;
          table-layout: fixed;

          th:nth-child(3) {
            width: 120px;
          }

          th:nth-child(4) {
            width: 100px;
          }

          th:last-child {
            width: 140px;
          }

          td {
            white-space: nowrap;

            overflow: hidden;
            text-overflow: ellipsis;
          }

        }

        .sd_mc_im_headers {
          .sd_mc_im_label {
            font-weight: bold;
            font-family: Inter;
            font-size: 12px;
            color: #252525;
            border: unset;
            padding: 2px 4px;

            span {
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }


        .sd_mc_im_row{
          .sd_mc_im_label {
            overflow: hidden;
            font-family: Inter;
            font-size: 12px;
            color: #252525;
            border: unset;
            //padding: 0;
            padding: 2px 4px;

            span {
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .sd_mc_im_buttons_container {
              display: flex;
            }

            &.inactive{
              color: #889299 !important;
            }

            .button{
             color: #01AFFD !important;
            }
          }
        }
        //.sd_mc_im_mail_valid{
        //  margin-top: 18px;
        //  box-sizing: border-box;
        //  width: 100%;
        //  display: flex;
        //  flex-direction: row;
        //  justify-content: space-between;
        //  align-items: center;
        //  .sd_mc_im_mv_mail{
        //    font-style: normal;
        //    font-weight: 400;
        //    font-size: 14px;
        //    line-height: 160%;
        //    color: #252525;
        //  }
        //  .sd_mc_im_mv_remove_acces{
        //    font-style: normal;
        //    font-weight: 600;
        //    font-size: 14px;
        //    line-height: 150%;
        //    color: #D4D5DE;
        //  }
        //}
        //
        //.sd_mc_im_mail_expired{
        //  margin-top: 15px;
        //  box-sizing: border-box;
        //  width: 100%;
        //  display: flex;
        //  flex-direction: row;
        //  align-items: center;
        //  justify-content: space-between;
        //
        //  .sd_mc_im_me_mail_container {
        //    display: flex;
        //    align-items: center;
        //    column-gap: 15px;
        //    .sd_mc_im_me_mail {
        //      font-style: normal;
        //      font-weight: 400;
        //      font-size: 14px;
        //      line-height: 160%;
        //      color: #D4D5DE;
        //    }
        //  }
        //  .sd_mc_im_me_remove_acces{
        //    font-style: normal;
        //    font-weight: 600;
        //    font-size: 14px;
        //    line-height: 150%;
        //    color: #D4D5DE;
        //  }
        //}
      }

      .sd_mc_send_link{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 14px;
        .sd_mc_sl_cb_container {
          display: flex;
          align-items: center;
          .sd_mc_sl_span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 160%;
            color: #585858;
          }
        }
      }

      .sd_mc_button_container{
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .sd_mc_span{
        font-family: Inter;
        font-weight: 400;
        font-style: italic;
        font-size: 12px;
        color: #889299;
        margin-left: 7px;
      }

      .sd_mc_copy_link_container{
        margin-top: 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .sd_mc_sl_cb_container {
          margin-left: -25px;
          margin-top: 3px;
        }
        .sd_mc_cl_input_container {
          height: 34px;
          width: 600px;
          .sd_mc_cl_ic_input {
            box-sizing: border-box;
            width: 100%;
            border: 1px solid #F0F1F7;
            border-radius: 8px;
            line-height: 160%;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000;
            padding: 6px 16px 6px 16px;
          }
        }
      }

      .sd_mc_copied_message{
        width: 100%;
        text-align: center;
        color: #01AFFD;
        font-weight: 700;
      }
    }
  }
}

.p2Strong{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
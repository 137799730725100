.line_object_container {
  &.hover:hover {
    svg{
      stroke:#01AFFD;
      fill:#01AFFD !important;
    }
    cursor: move;
    .line_object {
      svg{
        stroke:#01AFFD;
        fill:#01AFFD !important;
      }
    }
  }
  &.selected{
    svg{
      stroke:#01AFFD;
      fill:#01AFFD;
    }
    .line_object {
      svg{
        stroke:#01AFFD;
        fill:#01AFFD;
      }
    }
  }
}

.spsfrcf_reports_menu{
  .szh-menu__item{
    padding: 0;
  }

  .szh-menu__item--hover{
    background: none;
  }

  .MuiButton-root{
    width: 190px;
    height: 30px;
    border-radius: unset;
    color: black;
    justify-content: left;

  }

  .MuiButton-root:hover{
    width: 100%;
    border-radius: unset;
    background: #EBEBEB;
  }
}
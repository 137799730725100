.skeleton{
  background: linear-gradient(90deg, #F1EFEF -24.18%, #F9F8F8 50.26%, #E7E5E5 114.84%);
  border-radius: 6px;
}

.skeleton.tr{
  width:100%;
  height:19px;
}

.skeleton.chart_bar{
  width:36px;
  height:100%;
}

.chart_bar_container{
  box-sizing: border-box;
  height:150px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.skeleton.chart_bar_label{
  width:66px;
  height:10px;
}

.skeleton.name{
  width:214px;
  height:32px;
}

.skeleton.btn{
  width:142px;
  height:21px;
}

.skeleton.bottom_nav{
  width:100%;
  height:22px;
}

.skeleton.small_btn{
  width:34px;
  height:12px;
}

.skeleton.mid_btn{
  width:70px;
  height:12px;
}

.skeleton.avatar_btns{
  width:113px;
  height:24px;
}

.skeleton.top_nav{
  width:100%;
  height:26px;
}

.skeleton.modeliks{
  height:38px;
  width:33px;
}

.skeleton_container {
  z-index: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  padding-top: 72px;
  box-sizing: border-box;

  .skeleton_loader {
    height:100%;
    z-index: 0;

    display: flex;
    flex-direction: column;
    background-color: #fff;


    .skeleton_row.sub_menu{
      padding-top:15px;
      display:flex;
      justify-content: space-between;
      .sub_menu_btn{
        padding: 0 32px 0 34px;
      }

      .sub_menu_btn.big{
        width:55%;
      }
      .sub_menu_btn.small{
        width:27%;
      }
    }

    .skeleton_row.small{

      display: flex;
      justify-content: space-between;
      padding:26px 0 0 0;

      .small_container{
        padding:20px 0 0 21px;
        width:100%;
        display: flex;
        gap: 34px;
        align-items: center;

        &:nth-child(2){
          justify-content: flex-end;
          padding-right:35px;
        }
      }

    }

    .skeleton_row.charts{
      margin-top: 116px;
      //justify-content: space-between;
      display:flex;

      .chart{
        display: flex;
        justify-content: space-between;
      }
      .chart.big{
        padding-left:86px;
        width:80%;

        .chart_bar_container{
          &:nth-child(1){
            padding-top: 116px;
          }
          &:nth-child(2){
              padding-top: 80px;
            }
          &:nth-child(3){
              padding-top: 95px;
            }
          &:nth-child(4){
              padding-top: 60px;
            }
          &:nth-child(5){
              padding-top: 35px;
            }
          &:nth-child(6){
              padding-top: 75px;
            }
          &:nth-child(7){
              padding-top: 80px;
            }
          &:nth-child(8){
              padding-top: 58px;
            }
          &:nth-child(9){
              padding-top: 75px;
            }
          &:nth-child(10){
              padding-top: 22px;
            }
          &:nth-child(11){
              padding-top: 50px;
            }
          &:nth-child(12){
              padding-top: 60px;
            }
          }
      }
      .chart.small{
        padding-left:70px;
        width:20%;
        padding-right:40px;

        .chart_bar_container {
          &:nth-child(1) {
            padding-top: 70px;
          }
          &:nth-child(2) {
            padding-top: 60px;
          }
          &:nth-child(3) {
            padding-top: 10px;
          }
        }
      }
    }

    .skeleton_row.table{
      margin-top: 100px;
      display:flex;
      flex-direction: column;
      gap:21px;

      .table_row{
        display:flex;

        .table_header{
          padding-left:24px;
          padding-right:26px;
          width:15%;
        }

        .table_data{
          padding-left:20px;
          padding-right:20px;
          width:80%;
        }

      }
    }
  }
}

#instructions_container {

  padding: 20px;
  box-sizing: border-box;
  font-family: ABeeZee;

  .kb-article.tinymce-content {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .kb-article.tinymce-content .hs-image-align-none {
    margin: 0;
  }

  .kb-article.tinymce-content .hs-image-align-center {
    margin: 0 auto;
    display: block;
  }

  .kb-article.tinymce-content .hs-image-align-left {
    max-width: 300px;
    margin: 10px 40px 20px 0;
    float: left;
    clear: both;
  }

  .kb-article.tinymce-content .hs-image-align-right {
    max-width: 300px;
    margin: 10px 0 20px 20px;
    float: right;
    clear: both;
  }

  .kb-article.tinymce-content .hs-table-align-none {
    margin: 0;
  }

  .kb-article.tinymce-content .hs-table-align-center {
    margin: 0 auto;
  }

  .kb-article.tinymce-content .hs-table-align-left {
    margin: 20px 20px 20px 0;
    float: left;
  }

  .kb-article.tinymce-content .hs-table-align-right {
    margin: 20px 0 20px 20px;
    float: right;
  }

  .kb-article.tinymce-content td {
    border-color: inherit;
  }

  .kb-article.tinymce-content ul, .kb-article.tinymce-content ol {
    margin: 1.25rem 0 1.25rem 8px;
  }

  .kb-article.tinymce-content li {
    list-style-position: unset;
    margin-left: 17px;
    padding-left: 8px;
  }

  .kb-article.tinymce-content ul {
    list-style-type: disc;
  }

  .kb-article.tinymce-content ul li ul {
    list-style-type: circle;
  }

  .kb-article.tinymce-content ul li ul li ul {
    list-style-type: square;
  }

  .kb-article.tinymce-content ul li ul li ul li ul {
    list-style-type: disc;
  }

  .kb-article.tinymce-content ul li ul li ul li ul li ul {
    list-style-type: circle;
  }

  .kb-article.tinymce-content ul li ul li ul li ul li ul li ul {
    list-style-type: square;
  }

  .kb-article.tinymce-content ol {
    list-style-type: decimal;
  }

  .kb-article.tinymce-content ol li ol {
    list-style-type: lower-alpha;
  }

  .kb-article.tinymce-content ol li ol li ol {
    list-style-type: lower-roman;
  }

  .kb-article.tinymce-content ol li ol li ol li ol {
    list-style-type: decimal;
  }

  .kb-article.tinymce-content ol li ol li ol li ol li ol {
    list-style-type: lower-alpha;
  }

  .kb-article.tinymce-content ol li ol li ol li ol li ol li ol {
    list-style-type: lower-roman;
  }

  .kb-article.tinymce-content img {
    display: unset;
    max-width: 100%;
  }

  .kb-article.tinymce-content a {
    text-decoration: underline;
  }

  .kb-article.tinymce-content [class^="hs-callout-type-"],
  .kb-article.tinymce-content [class*=" hs-callout-type-"] {
    border-left-style: solid;
    border-left-width: 5px;
    clear: both;
    padding: 20px 30px 10px;
    //width: 100%;
  }

  .kb-article.tinymce-content [class^="hs-callout-type-"]:after,
  .kb-article.tinymce-content [class*=" hs-callout-type-"]:after {
    clear: both;
    content: "";
    display: block;
  }

  .kb-article.tinymce-content .hs-callout-type-tip {
    background-color: #e5f5f8;
    border-left-color: #7fd1de;
  }

  .kb-article.tinymce-content .hs-callout-type-note {
    background-color: #e5f8f6;
    border-left-color: #7fded2;
  }

  .kb-article.tinymce-content .hs-callout-type-caution {
    background-color: #fdedee;
    border-left-color: #f8a9ad;
  }

  .kb-article.tinymce-content .hs-callout-type-warning {
    background-color: #ffebe6;
    border-left-color: #e68250;
  }

  /* Horizontal rule */
  .kb-article.tinymce-content hr {
    border: 0;
    height: 0;
    border-bottom: 1px solid #99acc2;
    margin-top: 1.25rem;
    margin-bottom: 0;
  }

  .kb-article.tinymce-content hr:first-child {
    margin-top: 0;
  }

  /* hrSmallMarginElement + hr, hr + hrSmallMarginElement => hrSmallMargin */
  .kb-article.tinymce-content p + hr,
  .kb-article.tinymce-content hr + p,
  .kb-article.tinymce-content pre + hr,
  .kb-article.tinymce-content hr + pre,
  .kb-article.tinymce-content table + hr,
  .kb-article.tinymce-content hr + table,
  .kb-article.tinymce-content [class^="hs-callout-type-"] + hr,
  .kb-article.tinymce-content hr + [class^="hs-callout-type-"],
  .kb-article.tinymce-content [class*=" hs-callout-type-"] + hr,
  .kb-article.tinymce-content hr + [class*=" hs-callout-type-"] {
    margin-top: .9375rem;
  }

  /* hrLargeMarginElement + hr, hr + hrLargeMarginElement => hrLargeMargin */
  .kb-article.tinymce-content hr + h3,
  .kb-article.tinymce-content h3 + hr,
  .kb-article.tinymce-content hr + h4,
  .kb-article.tinymce-content h4 + hr,
  .kb-article.tinymce-content hr + blockquote,
  .kb-article.tinymce-content blockquote + hr {
    margin-top: 1.875rem;
  }

  /* hrSmallMarginElement + hr + hrLargeMarginElement => hrSmallMargin */
  .kb-article.tinymce-content p + hr + h3,
  .kb-article.tinymce-content p + hr + h4,
  .kb-article.tinymce-content p + hr + blockquote,
  .kb-article.tinymce-content pre + hr + h3,
  .kb-article.tinymce-content pre + hr + h4,
  .kb-article.tinymce-content pre + hr + blockquote,
  .kb-article.tinymce-content table + hr + h3,
  .kb-article.tinymce-content table + hr + h4,
  .kb-article.tinymce-content table + hr + blockquote,
  .kb-article.tinymce-content [class^="hs-callout-type-"] + hr + h3,
  .kb-article.tinymce-content [class^="hs-callout-type-"] + hr + h4,
  .kb-article.tinymce-content [class^="hs-callout-type-"] + hr + blockquote,
  .kb-article.tinymce-content [class*=" hs-callout-type-"] + hr + h3,
  .kb-article.tinymce-content [class*=" hs-callout-type-"] + hr + h4,
  .kb-article.tinymce-content [class*=" hs-callout-type-"] + hr + blockquote {
    margin-top: .9375rem;
  }

  /* hrLargeMarginElement + hr + hrSmallMarginElement => hrLargeMargin */
  .kb-article.tinymce-content h3 + hr + p,
  .kb-article.tinymce-content h3 + hr + pre,
  .kb-article.tinymce-content h3 + hr + table,
  .kb-article.tinymce-content h3 + hr + [class^="hs-callout-type-"],
  .kb-article.tinymce-content h3 + hr + [class*=" hs-callout-type-"],
  .kb-article.tinymce-content h4 + hr + p,
  .kb-article.tinymce-content h4 + hr + pre,
  .kb-article.tinymce-content h4 + hr + table,
  .kb-article.tinymce-content h4 + hr + [class^="hs-callout-type-"],
  .kb-article.tinymce-content h4 + hr + [class*=" hs-callout-type-"],
  .kb-article.tinymce-content blockquote + hr + p,
  .kb-article.tinymce-content blockquote + hr + pre,
  .kb-article.tinymce-content blockquote + hr + table,
  .kb-article.tinymce-content blockquote + hr + [class^="hs-callout-type-"],
  .kb-article.tinymce-content blockquote + hr + [class*=" hs-callout-type-"] {
    margin-top: 1.875rem;
  }
}
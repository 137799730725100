.driver_grid_container {
  border: 1px solid #F0F1F7;
  border-radius: 8px;
  background: white;

  .Paragraph_P3{
    padding-left:5px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
  }

  .driver_grid_item_container {
    padding: 1px 5px 3px 2px;
    .driver_grid_item {
      width: 100%;
      height: 100%;
      background: #FBFBFB;
      /* Light_Grey/150 */
      border: 1px solid #F0F1F7;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .Paragraph_P3 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .MuiIconButton-root {
        svg {
          width: 15px;
          height: 15px;
        }
      }
      .btns_container{
        display: flex;
        align-items: center;
      }
    }

    .MuiAutocomplete-root {
      height: auto !important;
      .MuiFormControl-root {
        height: auto !important;
      }

      .MuiOutlinedInput-root {
        height: auto !important;

        .MuiOutlinedInput-input {
          height: auto !important;
        }
      }
    }
  }
}

@import url("https://fonts.googleapis.com/css?family=ABeeZee:regular,bold,italic&subset=latin,latin-ext");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .font-outline-1 {
    -webkit-text-stroke: 1px #01affd;
  }
  .font-outline-2 {
    -webkit-text-stroke: 2px #01affd;
  }
}

body {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #f5f5f5;
  font-family: Inter;
}

#MainContainer {
  .upgrade_plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    color: #01affd;
    font-size: 20px;
    font-weight: 700;
  }
}

.main_circle_loader_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.white_back {
    background-color: white;
    z-index: 1000;
  }
}

.main_circle_loader_container_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 899;

  &.grey{
    background-color: #F5F5F5;
  }
}
#chat_bot_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.high {
    z-index: 999;
  }

  button {
    position: absolute;
    bottom: 15px;
    right: 15px;

    height: 62px;
    width: 62px;

    z-index: 2147483647;
  }
}

#printableContainer {
  position: absolute;
  top: 0;
  left: 0;
  //display:none;
  width: 100%;
  z-index: 9997;
  visibility: hidden;
  overflow-x: hidden;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#tmpPrint {
  position: absolute;
  top: 0;
  left: 0;
  //display:none;
  width: 816px;
  height: auto;
  z-index: 9997;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .print_slide_container {
    break-after: auto;
    page-break-after: auto;
    .slide_print_container {
      break-after: auto;
      page-break-after: auto;
      .print_slide_comp {
        height: 1154px;
        width: 816px;
        background: #fff;
        position: relative;
      }
    }
  }
}

#printableContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

//
//html{
//  overflow: auto;
//  min-width:1024px;
//}

.grid_container {
  display: grid;
  grid-template-rows: 61px auto;
  grid-template-columns: 100%;
  overflow: hidden;
  align-content: start;
}

.beta_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  line-height: 14px;

  .beta_item {
    background: #01affd;
    color: white;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    padding: 0 10px;
    line-height: 12px;
  }
}

.mr_menu_btn {
  display: none !important;

  .beta_item {
    height: 18px;
    padding: 0 8px;
  }
}

#exportReportContainer {
  display: none;
  visibility: hidden;
  font-family: Inter;

  .report_header {
    display: flex;
    justify-content: space-between;
  }
  .table_container {
    padding-top: 1cm;

    &:nth-child(2) {
      padding-top: 3mm;
    }
  }

  .report_title {
    font-weight: 600;
  }
  table {
    th {
      padding-right: 3px;
      border: 0;
    }
    td {
      padding-right: 3px;

      &:first-child {
        min-width: min-content;
        white-space: nowrap;
      }

      &.color_warning {
        color: #ff3344;
      }
      &.color_success {
        color: #2ecc71;
      }
    }
  }
}

@media print {
  html,
  body,
  #root {
    height: initial !important;
    overflow: visible !important;
    -webkit-print-color-adjust: exact;
  }

  #helphero-dom {
    display: none !important;
  }

  .callout_rect {
    svg {
      width: 100%;
    }
  }

  @page {
    margin: 0;
  }
  #MainContainer {
    visibility: hidden;
    display: none !important;
    overflow: auto !important;
    min-width: 1024px !important;
  }

  #exportReportContainer {
    display: block;
    visibility: visible;
    margin: 1cm;

    .table_container {
      break-inside: avoid;

      table {
        break-inside: avoid;
      }
    }
  }

  #printableContainer {
    display: block;
    visibility: visible;
    break-inside: auto;

    .print_slide_comp {
      break-inside: avoid;
      position: relative;
      height: 720px;
      width: 1280px;
      overflow: hidden;

      &:last-child {
        break-after: avoid;
      }

      .MuiTable-root {
        width: 100% !important;
      }

      .mct_ft_tablerow_header_div {
        padding: 0 !important;
      }

      .mct_ft_tablerow_header {
        padding: 0 !important;
      }

      .mx_numinput_input {
        font-size: 10pt !important;
      }

      .spsffcf_warning_div {
        display: none;
      }

      .MuiTableCell-root {
        min-width: 0 !important;
        width: max-content !important;
        max-width: 120px;
      }

      .mct_ft_tablebody_cell {
        height: auto !important;
        margin: 0 !important;
        max-width: 120px;
      }

      .dashboard_container {
        grid-template-rows: 0 auto;
      }

      .mct_ft_tablerow {
        min-height: 0 !important;
      }

      .mct_ft_tablehead_cell {
        &:first-child {
          min-width: 160px !important;
          width: 160px !important;
        }

        height: auto !important;
      }

      .mx_numinput {
        height: auto;
      }

      .finance_table_main_wrapper_div {
        margin: 0;
        overflow: visible !important;
        height: 100% !important;
        max-height: unset !important;
      }

      .main_table_container {
        overflow: visible !important;

        .mct_ft_tablebody_cell,
        .mx_numinput_input {
          font-size: 8.5pt !important;
          padding: 0 !important;
        }

        .mct_ft_tablerow_header_paragraph {
          font-size: 8.5pt !important;
          margin: 0 !important;
        }
      }

      .pfr_main_revenue_content {
        overflow: visible !important;
      }
    }
  }
}

.p {
  line-height: 160%;
  font-weight: 400;
  font-style: normal;
  font-family: Inter;

  &.strong {
    font-weight: 700;
  }

  &.center {
    text-align: center;
  }

  &.title {
    font-size: 22px;
    text-align: center;
  }

  &.p_t {
    padding-top: 20px;
  }
  &.p_b {
    padding-bottom: 20px;
  }

  &.p_l {
    text-align: center;
    padding-left: 5px;
  }
}
.p1 {
  font-size: 20px;
}
.p2 {
  font-size: 18px;
}
.p3 {
  font-size: 16px;
}
.p4 {
  font-size: 14px;
}
.p5 {
  font-size: 12px;
}
.p6 {
  font-size: 10px;
}

.p7 {
  font-size: 12px;
}

.hidden {
  display: none;
}

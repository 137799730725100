.recent_reports_container{
  width: 100%;
  font-family: Inter;
  font-style: normal;
  color: #252525;
  box-sizing: border-box;
  .rrc_heading{
    font-size: 16px;
    font-weight: 700;
    line-height: 160%;
    border: 1px solid #F9F9F9;
    padding: 15px 20px;
    box-shadow: 0px 3px 3px 0px #00000008;
  }
  .rrc_report_cards_container {
    display: flex;
    column-gap: 15px;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;

    //padding: 10px 0;
    padding: 20px 20px 10px;

    .rrc_card {
      box-sizing: border-box;
      aspect-ratio: 16/9;
      height: 142px;
      overflow: hidden;
      background: #FFFFFF;
      /* Light_Grey/150 */
      min-width: 250px;
      border: 1px solid #F0F1F7;
      /* cards */

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      position: relative;

      .rr_mask {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;

        z-index: 100;
        transition: background-color 250ms ease;

        &:hover {
          background: rgba(248, 254, 255, 0.4);
        }

        cursor: pointer;

      }
    }
  }
}
.spsfrs_report_summary{
  .MuiButton-root {
    width: 74px;
    color: black;
    font-weight: 600;
    font-size: 12px;
    font-family: Inter;
  }
  .spsfrs_selected_charts{
    background: #01AFFD !important;
    color: #FFFFFF !important
  }
}
.spsvc_val_container{
  background: white;
  height: 100%;
  max-width: 648px;
  min-width: 648px;
  align-self: center;
  justify-self: center;
  border: 1px solid #F0F1F7;
  border-radius: 5px;
  margin-top: 11px;
  padding: 26px 15px 20px 15px;
  overflow-x: scroll;
}
.spsvc_cards_container{
  background: white;
  height: 142px;
  border: 1px solid #F0F1F7;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  column-gap: 18px;
}

.spsvc_val_overall_container{
  height: 100%;
  overflow-x: scroll;
}
.spsvc_large_gray_container{
  /* Background */
  background: #FBFBFB;
  /* Light_Grey/150 */
  //#F0F1F7
  border: 1px solid #F0F1F7;
  border-radius: 5px;

}
.spsvc_padding{
  margin: 7px 16px 7px 30px
}
.spsvc_padding_small{
  margin: 0 12px 0 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  display: inline-flex;

  /* Dark_Grey/500 */
  width: 100%;
  color: #252525;
}

.spsvc_margin_top{
  margin-top: 10px;
  display: flex;
}

.spsvc_gray_small_container_aligment{
  width: 100%;
  display: flex;
  justify-content: space-between;
  p:first-child {
    width: 240px;
  }
  .spsvc_input_label_div{
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  .spsvc_padding_small{
    margin: 0 12px 0 12px !important;
  }
}

.spsvc_gray_small_container_exit_aligment{
  width: 100%;
  display: flex;
  justify-content: space-between;
  p:first-child {
    align-self: center;
    width: 45%;
  }
  .spsvc_input_label_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .spsvc_padding_small{
    margin: 0 12px 0 12px !important;
  }


}

.spsvc_gray_small_container_exit_aligment.spsvc_gray_exit_aligment_reverse{
  justify-content: flex-start;
  column-gap: 50px;
}

.spsvc_padding_check_box{
  display: flex;
  height: 35px;
  justify-content: space-between;
  width: 350px;
  padding: 7px 0 7px 0;
    .MuiButtonBase-root{
      min-width: 45px;
      max-width: 45px;
    }
}
.spsvc_max_width_input{
  padding-left: 10px;
  .mx_numinput.mct_ft_dialog_input{
    max-width: 80px;
    min-width: 80px;
  }
}

.spsvc_double_text_div{
  margin: -8px 8px -10px 8px;
  padding:0;
  display: flex;
  column-gap: 25%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */


  /* Dark_Grey/500 */

  color: #252525;
}

.spsvc_add_company_show_hide_div{
  padding: 0 20px 0 20px;
  display: flex;
  justify-content: space-between;
  .psdf_menu_navigation_show_hide_div{
    align-self: center;
  }
}

.spsvc_double_text_large_div_padding{
  margin: 25px 0 0 0;
  padding:0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #252525;
}

.spsvc_double_text_large_div{
  margin: 0 80px 0 25px;
  padding:0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #252525;
  .spsvc_input_wacc_range{
    display: flex;
    align-items: center;
    column-gap: 15px;
  }


  .spsvc_input_icon{
    display: flex;
    column-gap: 5px;
    align-items: center;
  }

}


.spsvc_double_text_large_div.spsvc_inputs_container{
  align-items: flex-start ;
  column-gap: 30px;
  .spsvc_child_inputs_container{
    width: 50%;
  }
  .spsvc_input_label_div{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.spsvc_hight_light_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: #252525;
  padding-bottom: 20px;
}

.spsvc_center_div{
  padding: 15px 35px 7px 35px;
}

.spsvc_sub_center_div{
  padding: 10px 10px 5px 10px;
}

.spsvc_sub_light_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  color: #252525;
}

.spsvc_sub_sub_light_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
}

.spsvc_sub_sub_light_text_normal{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #252525;
}
.spsvc_multi_checkboxes{
  display: flex;
  justify-content: space-between;
}
.spsvc_sub_center_div_margin{
  margin: 0 10px 0 10px;
}

.spsvc_main_header{
  /* Paragraph/P1_Strong */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  /* identical to box height, or 32px */
  text-align: left;
  /* Dark_Grey/500 */
  color: #252525;
  margin: 15px 0 15px 0;
  padding: 0 20px 0 20px;

}

.spsvc_card_body{
  background: #FBFBFB;
  width: 100%;
  /* Light_Grey/150 */
  border: 1px solid #F0F1F7;
  border-radius: 8px;
  padding-left: 20px;
  .spsvc_card_title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */
    /* Dark_Grey/500 */
    color: #01AFFD;
  }
  .spsvc_card_value{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    /* or 50px */
    color: #000000;
  }

}

.spsvc_main_add_div{
  display: flex;
  column-gap: 34px;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-items: center;
  align-items: center;
  .spsvc__main_add_title{
    /* Paragraph/P2_Strong */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    /* identical to box height, or 26px */


    /* Dark_Grey/500 */

    color: #252525;

  }
}

.fill_height{
  height: -moz-available;          /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;
}
.sccn_nav_p{
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
  min-width: 200px;
  max-width: 200px;
  white-space: nowrap;
}
#reports_right_portal{
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}

.blank{
  .sccn_nav_first_div{
    width: 350px;
  }
}
.sccn_nav_first_div{
  width: 450px;
}

.sccn_nav_content_end{
  justify-content: end;
}


.sccn_nav_second_div{
  //margin-left: -200px;
  .sccn_nav_center{
    text-wrap: nowrap !important;
  }
}

.sccn_nav_paragraph{
  
  min-width:185px;
}

.sccf_container{
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;

}

.sccn_reporst_menu_container{
  //background: red;
}


.psdac_message_actual_div {
  align-self: center;
  font-size: 12px !important;
  margin-left: 160px;
  .psdac_message_actual_div_text {
    font-family: Inter;
    font-size: 12px;
    color: #01affd;
    font-weight: bold;
  }
  &.size-12 {
    margin-left: 80px;
  }
  &.size-10 {
    margin-left: 60px;
  }
}

#add_driver_menu{
  z-Index:9999;
  left:-16px;
  top:7px;

  .menu_paper{
    width:163px;
    border-radius:8px;
    box-shadow: none;
    border: 1px solid #F0F1F7;

    .MuiList-root{
      background: white;
      padding:0;
      color:#D4D5DE;

      .driver_item{
        font-size: 14px;
        font-family: Inter;
        padding-left:15px;
        padding-right:5px;
        color: #252525;
      }
      .driver_item:hover{
        color:#585858;

      }

      .driver_item:first-child{
        padding-top:7px;
      }

      .driver_item:last-child{
        padding-top:3px;
        padding-bottom:7px;
      }

    }
  }
}
  .psfccc_formulabox_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .psfccc_formulabox_to_simple_driver_group{
    display: flex;
    align-items: center;
    column-gap:10px;
    padding-bottom: 4px;
  }

  .psfccc_formulabox_button{
    display: flex;
    //width: 100%;
    //margin-right: 52px;
    justify-content: flex-end;
  }

  .psfcc_formulabox_error{
    padding: 0;
    margin: 0;
    white-space: nowrap;
    color: red;
    font-size: 20px;
    font-family: Inter;
  }


.Crop {
  display: block;
  width: 300px;
  height: 220px;
  position: relative;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 0;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 55px;
  margin-top: 15px;
  .reactEasyCrop_Image{
    //transform: translate(-0px, -0px) rotate(0deg) scale(0.5) !important;
  }

  .reactEasyCrop_CropArea{
    min-width: 150px !important;
    max-width: 150px !important;
    max-height: 150px !important;
    min-height: 150px !important;
  }
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  .spssic_cropper_actions{
    display: flex;
    width: 100%;
    justify-content: space-between;
    column-gap: 25px;
  }
}

.slider {
  padding: 22px 0px;
}

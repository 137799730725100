.se_panel_wrapper {

  &.bp{
    overflow: auto;
  }

  .preview_se_panel_container {
    padding: 30px;

    #preview_scale_panel_container {
      background: white;

      .preview_mid_container {
        .preview_se_panel {
          transform-origin: top left;
          width: 816px;
          padding: 49px;
          padding-top: 140px;
          box-sizing: border-box;
        }

        .preview_se_panel.fixed_height {
          height: 1154px;
        }

        .preview_se_panel.padding-top {
          padding-top: 140px;
        }
        .preview_se_panel.padding-bottom {
          padding-bottom: 80px;
        }
      }
    }

    #preview_scale_panel_container.split_page{
      margin-top: 20px;
    }
  }
}
.charts{
  .se_panel_wrapper{
    overflow: hidden;
  }
  .chart_component_container {
    grid-column-start: 2;
    grid-row-start: 1;
    //height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    background: #E5E5E5;

    .Label_3{
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0em;
      min-width: fit-content;
    }

    .separator{
      border-top: 1px solid #F0F1F7;
    }

    .charts_component {
      background: #FBFBFB;
      padding: 0 15px 0 15px;

      .c_c_row {
        display: flex;
        padding: 10px;
        box-sizing: border-box;
        justify-content: space-around;

        .chart_row{
          height: auto;
        }

        .c_c_r_select_container {
          //width: 40%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .Label_3{
            padding-right: 13px;
          }

          .c_c_r_sc_label {
            align-self: center;
          }
        }
        .c_c_r_select_container_vertical{
          .Label_3{
            padding-bottom: 8px;
          }
        }
      }

      .c_c_row.split {
        justify-content: space-between;

      }

      .c_c_row.left_align {
        justify-content: left;
      }

      .c_c_r_container{
        display: flex;
        justify-content: space-between;
        min-width: max-content;
        column-gap: 35px;
      }
      .gap {
        column-gap: 90px;
      }

    }
  }
}
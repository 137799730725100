.shape{
    position: absolute;
    height: 100%;
    overflow: visible;
    display: block;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
}
.shape_text_box{
    width:100%;
    height:100%;
    position:absolute;
    z-index: 9999999;
    display:flex;
    align-items: center;
    justify-content: center;
}
.text_box_container {
    width:60%;
    height:60%;
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.text_box{
    position: absolute;
    box-sizing: border-box;
    cursor: default;
}
.text_box.focused{
    cursor: text;
}

.delete_dialog{
  .Label_2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;

    text-align: center;

    color: #000000;
  }
  .delete_dialog_buttons{
    display: flex;
    justify-content: center;
    column-gap: 10px;
  }

  .MuiPaper-root{
    height: 100px;
    width: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
  }
}
.chart_items_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 16px 0 16px;
  box-sizing: border-box;
  font-family: Inter;



  .chart_title_container {

    .chart_title_wrapper {

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;

      .chart_title {
        display: flex;
        align-items: center;
        justify-content: space-around;
        column-gap: 10px;
        font-weight: 700;
      }

      .chart_subtitle {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: #979797;

        .MuiButton-root {
          color: #979797;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          text-transform: unset;
          justify-content: flex-start;
        }
      }
      .chart_actual {
        font-size: 18pt;
      }
      .c_title_percent_container {
        padding-left: 5px;
        height: 100%;
        justify-content: space-between;
        //align-items: center;
        display: flex;
        flex-direction: column;

        svg {
          height: 5px;
          width: 11px;
        }

        .c_title_percent_change {
          padding-left: 5px;
          font-size: 10pt;
        }

      }

      .red {
        color: red;
      }
      .green {
        color: green;
        svg {
          transform: scale(1, -1);
        }
      }
    }

    .chart_settings_container {
      display: flex;
      column-gap: 10px;
      align-items: flex-start;
      box-sizing: border-box;
      padding-bottom: 18px;

      .psdf_navigation_menu_buttons {
        border: 1px solid;
        max-height: unset !important;
        height: unset !important;

        .Mui-selected {
          path {
            stroke: #01AFFD;
          }
        }
        .MuiButtonBase-root {
          min-width: 60px;
        }
      }

      .menu_item_focus {
        display: flex;
        justify-content: space-between;
        input {
          outline: none;
          width: 50%;
          border-radius: 8px;
          height: 24px;
          border: 1px solid grey;
        }
      }
    }
  }
  .bottom_item_container{
    display: flex;
    justify-content: space-between;
    column-gap:16px;
    padding-top: 10px;

    .box{
      width:100%;
      padding: 16px;


      .period_name{
        font-weight: 700;
        font-size: 14px;
        padding-bottom:8px;
      }
      .big_value{
        font-size: 52px;
        font-weight: 700;
      }
      .small_value{
        font-size: 16px;
        font-weight: 700;
      }
      .percentage_diff{
        font-size: 36px;
        font-weight: 700;
        line-height: 50.4px;

        display: flex;
        align-items: center;
        column-gap: 15px;
      }
      .positive{
        color: #66D151;
      }
      .negative{
        color: #EA0000;
      }
    }
    .box.colored{
      background: #FBFBFB;
      border: 0.424658px solid #F0F1F7;
      box-shadow: 0 0 1.69863px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }
  }
  .bottom_item_container.block{
    display: block;

    .row{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0 5px 0;

      .key{
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;

        .circle{
          border-radius:50%;
          height:20px;
          width:20px;
        }
      }

      .key.flex{
        display: flex;
        align-items: center;
        column-gap: 10px;
      }
      .value{
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
      }
    }

    .splitting_line{
      width: 100%;
      border: 1px solid #B9BFC3;
      height:0;
    }

    .MuiButton-root{
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      color: #000000;
      text-transform: unset;
    }
  }
}
.colors_container{
  display: flex;
  flex-direction: row;
  column-gap: 5px;

  .color_column{
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;

    .szh-menu__item{
      border-radius: 50%;
      height:21px;
      width:21px;
      padding: 0;
    }
  }
}
.szh-menu{
  font-family: Inter !important;
}
.themes_menu{
  .szh-menu{
    width:290px !important;

    .szh-menu__submenu{
      .szh-menu {
        width: auto !important;
        padding: 0;
      }
    }
  }
  .text{
    display:flex;
    justify-content: space-between;
    column-gap: 15px;

  }

  span.text{
    width:100%;
  }
}


.report_header{
  display: flex;
  justify-content: space-between;
}

.report_image{
  margin-right: 5px;
  img{
    height: 60px;
    width: 60px;
    text-indent:-9999px;
  }
}

.report_pdf_container{
  .s_e_ft_thead{
    background: #0080BF !important;
    color: #FFFFFF;
    font-size: 12px;
  }
  .s_e_ft_th{
    p{
      margin: 2px;
    }
    padding: 3pt 6px 4pt 1px !important;
  }

  tr:last-child td {
    border-bottom: 1px solid black !important;
  }


  .mx_numinput{
    height: unset;
  }

  .s_e_table tbody tr td{
    border: unset;
  }
  .s_e_ft_tb_td.color_success{

    .mx_numinput_input{
      color: #0b622e !important;
    }

  }
  .s_e_ft_tb_td.border_right{
    border-right: 1px solid black;
    //.mx_numinput_input{
    //  color: #2ecc71 !important;
    //}

  }

  .s_e_ft_tb_td.color_warning{
    .mx_numinput_input{
      color: #FF3344 !important;
    }
  }
  .s_e_ft_thead.comparative{
    .s_e_ft_th{
      border-left: 1px solid white !important;
      border-right: 1px solid white !important;
      text-align: center;
      align-items: center;
      border-bottom: unset;
    }
    .s_e_financial_table_container{
      border: unset;
    }

  }

  .s_e_ft_tb_tr {
    td{
      font-weight: 400;
      font-family: Inter;
      background: white;
      font-size: 10px;
      .mx_numinput_input{
        font-weight: 400 !important;
        background: white;
        font-size: 10px;
      }
    }
  }

  .s_e_ft_tb_td.actual_td{
    background-color: #F5F5F5 !important;
    border-right: 1px solid black;
    border-left: 1px solid black;
    .mx_numinput_input{
      background:  #F5F5F5 !important;
    }
  }





  .s_e_ft_tb_tr.italic {
    td{
      font-weight: 400;
      font-family: Inter;
      background: white;
      font-style: italic;
      color: #878787;
      .mx_numinput_input{
        font-weight: 400 !important;
        background: white;
        font-style: italic;
        color: #878787;
      }
    }
  }

  .s_e_ft_tb_tr.boldBorderTop {
    td{
      font-weight: 600;
      font-family: Inter;
      border-top: 1px solid black !important;
      background: lightgray !important;
      .mx_numinput_input{
        font-weight: 600 !important;
        background: lightgray !important;
      }
    }
  }
  .s_e_ft_tb_tr.boldBorderBottom {
    td{
      font-weight: 600;
      font-family: Inter;
      border-bottom: 1px solid black !important;
      background: lightgray !important;
      .mx_numinput_input{
        font-weight: 600 !important;
        background: lightgray !important;
      }
    }
  }


  .s_e_ft_tb_tr.boldAndGray {
    td{
      font-weight: 600;
      font-family: Inter;
      background: lightgray !important;
      .mx_numinput_input{
        font-weight: 600 !important;
        background: lightgray !important;
      }
    }
  }

  .s_e_ft_tb_tr.normalBold {
    td{
      font-weight: 600;
      font-family: Inter;
      .mx_numinput_input{
        font-weight: 600 !important;
      }
    }
  }


  .s_e_ft_tb_tr.bold {
    td{
      font-weight: 600;
      font-family: Inter;
      border-top: 1px solid black !important;
      background: lightgray !important;
      .mx_numinput_input{
        font-weight: 600 !important;
        background: lightgray !important;
      }
    }
  }

  .s_e_ft_tb_tr.bold {
    td{
      font-weight: 600;
      font-family: Inter;
      border-top: 1px solid black !important;
      background: lightgray !important;
      .mx_numinput_input{
        font-weight: 600 !important;
        background: lightgray !important;
      }
    }
  }


}

.report_sub_title{
  font-weight: 400;
  font-family: Inter;
  display: block;
  margin-top: 10px;
  p{
    font-weight: 400;
    margin: 2px;
    font-size: 12px;
    font-family: Inter;
  }
}

.report_company_name{
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
}
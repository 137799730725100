.sccc_table_menu{
  display: flex;
  column-gap: 30px;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;

  .sccc_table_icon{
    display: inline-flex;
    column-gap: 15px;
    align-items: center;
    justify-content: start;
    margin-left: 10px;
    white-space: nowrap;

  }
  .sccc_table_divider{
    width: 100%;
  }
}

.scc_table_disable_height{
  height: unset !important;
}
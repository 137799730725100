.notes_editor{
  .MuiDialogContent-root{
    display: flex;
    align-items: center;
    flex-direction: column;
    //justify-content: ;
    row-gap: 20px;

    .text_editor_container{
      width: 500px;
      height: 500px;

      .text_editor{
        border: 1px solid grey;
        border-radius: 8px;
        padding:10px;
        box-sizing: border-box;
        width:100%;
        height:100%;
        overflow: auto;
      }
    }
  }
}

.refer_a_friend {
  .MuiPaper-root {
    width: 1118px !important;
    max-width: unset;
    background: #f2f2f2;
  }

  .MuiDialogTitle-root {
    padding: 0 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .send_mail {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    align-items: center;
    padding-bottom: 5px;

    .h1 {
      font-weight: 700;
      font-size: 16px;
      padding-bottom: 14px;
    }

    .p_b {
      padding-bottom: 40px;
    }

    .text {
      font-size: 14px;

      line-height: 17px;
      .acc-name-link {
        color: #01affd;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .toc {
      color: #01affd;
      &:hover {
        cursor: pointer;
      }
    }
    .second-toc {
      line-height: 40px;
    }

    .white_container {
      background: white;
      border-radius: 17px;
      width: 100%;
      padding: 26px 50px;
      box-sizing: border-box;
    }
    .wc_pf {
      padding: 26px 50px 5px 50px;
    }

    .blue_container {
      background-color: #01affd;
      border-radius: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 327px;
      height: 103px;
      color: white;
      //padding: 40px 90px;
    }

    .flex {
      display: flex;
      column-gap: 38px;

      &.a_t {
        align-items: flex-start;
      }

      &.col {
        flex-direction: column;
        justify-content: space-around;

        &.gap {
          row-gap: 20px;
        }
      }

      .referal-text-field {
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: #01affd !important;
            border-width: 1px !important;
          }
        }
        &.Mui-focused {
          &:hover {
            border-color: #01affd !important;
            border-width: 1px !important;
          }
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
          border-color: #01affd !important; /* color for focused state */
        }
      }
    }
  }
}
.dialog-content {
  h2 {
    color: gray;
    font-weight: 400;
  }
}

.accounts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 98%;
  height: max-content;
  max-height: 212px;
  margin: 0 auto;

  .account {
    width: 100%;
    height: max-content;
    margin: 2px 0;

    &:hover {
      cursor: pointer;
    }
  }
}
.explanation_text {
  width: 80%;
  background-color: rgba(192, 192, 192, 0.5);
  border-radius: 5px;
  margin: 20px auto 10px auto;
  padding: 2px;
  text-align: center;
}

.btn_loader {
}

.is_link {
  cursor: pointer;
}

.scdaca_warning_container {
  .MuiDialogContent-root {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.no_account {
  .MuiPaper-root {
    width: unset;
    padding: 20px 40px;

  }

  .MuiDialogTitle-root {
    //text-align: center;
  }

  .btn_container {
    display: flex;
    justify-content: center;
  }
}
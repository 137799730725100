.scd_active_tab_dash{
  position: absolute;
  bottom: 6px !important;
  width: 24px;
  height: 4px;
  border-radius: 5px;

  background: #01AFFD;


}
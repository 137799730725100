.spsfcf_footer_div{
  display: flex;
  align-items: center;
  column-gap: 62px;
}

.spsfcf_footer_action_buttons{
  display: flex;
  align-items: center;
  column-gap: 16px;
  .start{
    justify-content: flex-start;
  }
  .center{
    justify-content: center;
  }

  &.center{
    width:100%;
    justify-content: center;
  }
}

.spsffcf_footer_checkbox{
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: end;
}


.spsfcf_table_resize_icons{
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;
}

.spsfcfi_messages_div{
  min-width: 200px;
  width: auto;
  display: flex;
}

.spsfcf_table_display_size{
  width: 40px;
  font-family: Inter;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

.spsffcf_warning_div{
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 42px;
}

.spsffcf_title{
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  font-family: Inter;
  line-height: 100%;
}

.spsffcf_collapse_div{
  height: 42px;
  //margin-left: 10px;
  display: flex;
  min-width: 165px;
  align-items: center;
  justify-content: start;
  line-height: 100% !important;
  .spsffcf_title{
    white-space: nowrap;
    margin-right: 10px !important;
  }
}

.spsfcfi_burden_checkbox{
  .input[type='checkbox'] {
    accent-color: red;
  }

}

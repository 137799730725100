.drawable_container{
  z-index: 999999;
  transform-origin: inherit;
  .drawable {
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .btn_container{
    position: absolute;
    bottom:-30px;

    button {
      margin-right:20px;

      border-radius: 20px;
      border: none;
      background: #FBFBFB;
      position: relative;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 160%;

      color: #01AFFD;

      .btn_mask {
        position: absolute;
        border-radius: 20px;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &:hover {
          cursor: pointer;
          background-color: rgba(1, 175, 253, 0.05);
          transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 0.5) 0ms;
          -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
      }

    }
  }

}
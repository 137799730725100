.management_reports {
  .c_c_chart_container {
    width: auto !important;
    flex: 1.4;

    .circle {
      position: static;
    }

  }

  .chart_table_container {
    width: auto !important;
    flex: 1;
    padding-right: 0;

    th {
      padding-right: 2px !important;
    }

    .MuiTableCell-root:first-child {
      padding: 0 5px !important;
    }
  }

}

.sp_cp_childpanel {
  .f_c_loader_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content_container{
  padding: 0 0 0 10px;

  .subtitle{
    padding-top:11px;
    font-family: Inter;
    margin-bottom: 10px;
  }
  .input_container{
    max-width: 427px;
    padding: 15px 0 0  0;
    height: 45px;
    display: block;
  }
  .status_title{
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #252525;
  }

  .status_title.employement{
    padding-top:34px;
  }
  .checkbox_container{
    padding-top: 6px;
    display: flex;
    flex-wrap: wrap;
    .MuiFormControlLabel-root{
      width:49%;
    }
  }
  .checkbox_container_2{
    display: flex;
    padding-top:5px;
    flex-direction: column;
    .MuiFormControlLabel-root{
      //padding-top:2px;
      width:40%;
    }
    .checkbox_row{
      width:100%;
      display:flex;
    }
  }
}


.tableInsertSquare{
    width: 18px;
    height: 18px;
    border: 1px solid #D4D5DE;
    border-radius: 2px;
    /*margin-top:10px;*/

}
.tableInsertSquare.szh-menu__item {
    padding:0 !important;
    margin-left: 10px;
}

.tableInsertSquare.szh-menu__item:first-child {
    padding:0 !important;
    margin-left: 0px !important;
}

.tableInsertSquare.szh-menu__item:hover {
    background: white;
}
.tableInsertGridRow{
    width:100%;
    height: 100%;
    display:flex;
    padding: 10px 10px 0px 10px;
}

.tableInsertGridRow:first-child{
    width:100%;
    height: 100%;
    display:flex;
    padding: 0px 10px 0px 10px;
}

.tableInsertRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.tableInput{
    border: solid 1px #F0F1F7;
    color: #000000;
    width: 40px;
    height:34px;
    border-radius: 8px;
    text-align: center;
}

.tableInput:focus{
    outline: none;
    /*border: none;*/
}
.CRInsert{
    display: flex;
    gap:10px;
    align-items: center;
    font-size: 10pt;
    justify-content: center;
}
.bottomRow{
    width: max-content;
}
.szh-menu{
    /*display*/
}
.szh-menu__item{
    min-width: 0;
    /*width:50px !important;*/
    text-align: center;
}

.scim_tooltip_div{
  .MuiButtonBase-root{
    display: block;
    padding: 0;
    font-family: Inter;
    margin-top: -1px;
  }
  .scim_tool_tip_click{
    border-radius: unset !important;

    .scim_tool_tip_warning{

    }
  }
}

.scim_tooltip_div_disable_style{
  .MuiButtonBase-root{
    //display: block;
    padding: 0;
    font-family: Inter;
    margin-top: -1px;
  }
  .scim_tool_tip_click{
    //border-radius: unset !important;

    .scim_tool_tip_warning{

    }
  }
}
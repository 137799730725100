.sc_light {
  min-width: 1280px;
  min-height: 720px;

  transform: scale(0.1935);
  transform-origin: top left;

  .loader_cont {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
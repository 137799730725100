.img_cont{
  width: 100%;
  justify-content: center;
  margin-top: 20px;

  .img_cont_card{
    width: 100%;
    height: 210px;
    background: #7fd1de;
    border-radius: 15px;
    background: unset;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .svg_cont{
      z-index: 0;
      position:absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .img{
      z-index: 1;
      max-width: 100%;
      max-height: 100%;
    }

    .float_btn{
      padding: 0;
      z-index: 2;

      position: absolute;
      top: -10px;
      right: -10px;

    }
  }
}

.p_c_cont {
  padding-top: 10px;
  width: 100%;

  &.flex {
    display: flex;

    &.center {
      justify-content: center;
      column-gap: 10px;
    }
  }
}
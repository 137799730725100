.main_circle_loader_container_mask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.main_circle_loader_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.presentation_container {

  .se_panel_placeholder {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;

    .shape {
      position: absolute;
      height: 100%;
      overflow: visible;
      display: block;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
    }

    &_selected {
      border: 1px solid #00c4cc;
    }
  }
  .se_scale_panel_top {
    height: 100%;
    width: 100%;
    align-self:center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .se_scale_panel {
      display: block;
    }


  }
  .se_panel {
    width: 1280px;
    height: 720px;
    background-color: #fff;
    overflow: hidden;
    transform-origin: top left;
    position: relative;
  }

  .buttons_wrapper{
    position: absolute;
    width: 100%;
    bottom:0;
    left:0;
    z-index: 999999999999;

    .buttons_container{
      background: rgba(256,256,256,0.9);
      width: 100%;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
      padding-top: 20px;
      column-gap: 20px;
      display: flex;
      z-index: 999999999999;
      visibility: hidden;

    }

    .p_c_pagging{
      position: absolute;
      right: 20px;
      bottom: 15px;
      text-shadow: 0 0 3px #FFF, 0 0 5px #FFF;
    }
  }

  .buttons_wrapper:hover{
    .buttons_container {
      visibility: visible;

    }
  }
}

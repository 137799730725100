
.financial_main_brakedown_wrapper {
  display: block;

  .fmbc_charts_container{
    display: flex;

    .financial_main_breakdown_monthly_chart_container {
      box-sizing: border-box;
      //width: 70%;
      width: 76%;

    }
    .financial_main_breakdown_yearly_chart_container {
      box-sizing: border-box;
      //width: 30%;
      width: 24%;
    }
  }

  .financial_main_breakdown_cb_container {
    box-sizing: border-box;
    display: flex;
    padding-left: 30px;
    height: 42px;
    width: 100%;
  }
}
.name_dialog {
  .MuiPaper-root {
    //height: 30%;
    //width:30%;

    height: 180px;
    width: 303px;

    //max-height:360px;
    //max-width:606px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .Paragraph_P4 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      color: #889299;
      text-align: center;
      padding: 0 25px 0 25px;
    }

    .checkbox_container {
      display: flex;
      justify-content: center;
      align-items: center;

      .MuiFormControlLabel-root {
        margin: 0;
      }
    }

    .Label_2 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;

      text-align: center;

      color: #000000;
    }

    .label {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .subtext {
      font-family: 'Inter';
      font-style: normal;
      font-size: 14px;
      line-height: 150%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .input_container {
      box-sizing: border-box;
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;

      input {
        outline: none;
        width: 85%;
        height: 100%;
        border: 1px solid #D4D8DB;
        border-radius: 6px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        color: #889299;
      }

      .input:focus {
        outline: 1px solid #01AFFD;
      }
    }

    .btns_container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      &.no_gap {
        gap: 5px;
      }
    }
  }
}